import { render, staticRenderFns } from "./ResidentEditor.vue?vue&type=template&id=927c2a1a&scoped=true&"
import script from "./ResidentEditor.vue?vue&type=script&lang=js&"
export * from "./ResidentEditor.vue?vue&type=script&lang=js&"
import style0 from "./ResidentEditor.vue?vue&type=style&index=0&id=927c2a1a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "927c2a1a",
  null
  
)

export default component.exports