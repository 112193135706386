<template>
    <div style="margin-left: 2rem">
        <b-container>
            <b-row>
                <b-col>
                    <b-form-group
                            class="situation-radio-group"
                            label-for="situation-radio-group"
                            label-class="label-bold"
                    >
                        <b-form-group label="Situation"
                                      label-for="immediacy"
                                      label-class="label-bold">
                            <b-form-radio v-model="immediacy" name="immediacy" value="true">{{
                                    selectedSignAndSymptomObject ? selectedSignAndSymptomObject.immediate_symptom :  'Not Found'
                                }}
                            </b-form-radio>
                            <b-form-radio v-model="immediacy" name="immediacy" value="false">
                                {{ selectedSignAndSymptomObject ? selectedSignAndSymptomObject.non_immediate_symptom :  'Not Found' }}
                            </b-form-radio>
                        </b-form-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Started On" label-for="startedAt" label-class="label-bold" label-cols="4">
                        <b-form-datepicker
                                v-model="startedAt"
                                id="situation-datepicker"
                                locale="en"
                                nav-button-variant="danger"
                                name="startedAt"
                                :max="dateLimiter"
                                :state="hasValidDate"
                        ></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                            class="situation-radio-group"
                            label="Since this started, has it gotten"
                            label-for="severityDirection"
                            label-class="label-bold"
                            label-cols="4"
                    >
                        <b-form-radio-group
                                id="situation-severity-radio-group"
                                v-model="severityDirection"
                        >
                            <b-form-radio value="worse" name="severityDirection">Worse</b-form-radio>
                            <b-form-radio value="same" name="severityDirection">Stayed the Same</b-form-radio>
                            <b-form-radio value="better" name="severityDirection">Better</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            class="situation-radio-group"
                            label-for="situation-occurrence-radio-group"
                            label-cols="auto"
                    >
                        <template slot="label"><b>Has this occurred before?</b></template>
                        <b-form-radio-group
                                id="situation-occurrence-radio-group"
                                v-model="isReoccurrence"
                        >
                            <b-form-radio value="true">Yes</b-form-radio>
                            <b-form-radio value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                            :description="negativeFactorsTextAreaRemainingLength"
                    >
                        Things that make the condition or symptom <u>worse</u> are:
                        <b-form-textarea
                                id="situation-negative-factors-input"
                                v-model="negativeFactors"
                                :state="negativeFactorsTextAreaIsValid"
                                placeholder="(Optional) List items that make the situation worse..."
                                rows="3"
                        ></b-form-textarea>

                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            :description="positiveFactorsTextAreaRemainingLength"
                    >
                        Things that make the condition or symptom <u>better</u> are:
                        <b-form-textarea
                                id="situation-positive-factors-input"
                                v-model="positiveFactors"
                                :state="positiveFactorsTextAreaIsValid"
                                placeholder="(Optional) List items that make the situation better..."
                                rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        :description="treatmentFactorsTextAreaRemainingLength"
                    >
                        <b>Treatment for last episode (if applicable):</b>
                        <b-form-textarea
                                id="situation-treatment-input"
                                v-model="lastTreatment"
                                :state="treatmentFactorsTextAreaIsValid"
                                placeholder="(Optional) Treatment for the last occurance, if applicable..."
                                rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                            :description="otherFactorsTextAreaRemainingLength"
                    >
                        <b>Other relevant information: </b>
                        <b-form-textarea
                                id="situation-positive-factors-input"
                                v-model="otherFactors"
                                :state="otherFactorsTextAreaIsValid"
                                placeholder="(Optional) Other items of interest..."
                                rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "Situation",
    computed: {
        ...mapGetters({
            getCurrentSignsAndSymptom: "episode/getCurrentSignsAndSymptom",
            getSignsAndSymptoms: "configuration/signsAndSymptoms",
            assessment: "episode/getAssessment",
        }),

        negativeFactorsTextAreaIsValid() {

            return this.negativeFactors.length <= 65500
        },

        negativeFactorsTextAreaRemainingLength() {

            return (65500 - this.negativeFactors.length) + ' characters remaining.'
        },

        positiveFactorsTextAreaIsValid() {

            return this.positiveFactors.length <= 65500
        },

        positiveFactorsTextAreaRemainingLength() {

            return (65500 - this.positiveFactors.length) + ' characters remaining.'
        },

        otherFactorsTextAreaIsValid() {

            return this.otherFactors.length <= 65500
        },

        otherFactorsTextAreaRemainingLength() {

            return (65500 - this.otherFactors.length) + ' characters remaining.'
        },

        treatmentFactorsTextAreaIsValid() {

            return this.lastTreatment.length <= 65500
        },

        treatmentFactorsTextAreaRemainingLength() {

            return (65500 - this.lastTreatment.length) + ' characters remaining.'
        },

        selectedSignAndSymptomObject() {

            return this.getSignsAndSymptoms.find(symptom => symptom.id === this.getCurrentSignsAndSymptom)
        },

        immediacy: {
            get() {

                return this.assessment?.is_immediate === 1
            },
            set(value) {

                this.setAssessmentImmediacy(value)
            }
        },

        immediacyText() {

            return this.immediacy === 'true' ? 'Immediate' : 'Non-immediate'
        },

        hasValidDate() {

            return this.startedAt !== null
        },

        startedAt: {
            get() {

                return this.assessment?.started_at
            },
            set(value) {

                this.setAssessmentStartedAt(value)
            }
        },

        severityDirection: {
            get() {

                return this.assessment?.severity_direction.toLowerCase().trim()
            },
            set(value) {

                this.setAssessmentSeverityDirection(value)
            }
        },

        negativeFactors: {
            get() {

                return this.assessment?.negative_factors
            },
            set(value) {

                this.setAssessmentNegativeFactors(value)
            }
        },

        positiveFactors: {
            get() {

                return this.assessment?.positive_factors
            },
            set(value) {

                this.setAssessmentPositiveFactors(value)
            }
        },

        lastTreatment: {
            get() {

                return this.assessment?.last_treatment
            },
            set(value) {

                this.setAssessmentLastTreatment(value)
            }
        },

        otherFactors: {
            get() {

                return this.assessment?.other_factors
            },
            set(value) {

                this.setAssessmentOtherFactors(value)
            }
        },

        isReoccurrence: {
            get() {

                return this.assessment?.is_reoccurrence ? 'true' : 'false'
            },
            set(value) {

                this.setAssessmentIsReoccurrence(value === 'true')
            }
        },
    },
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(today)
        return {
            dateLimiter: maxDate
        }
    },
    methods: {
        ...mapActions({
            setAssessmentImmediacy: "episode/setAssessmentImmediacy",
            setAssessmentStartedAt: "episode/setAssessmentStartedAt",
            setAssessmentSeverityDirection: "episode/setAssessmentSeverityDirection",
            setAssessmentNegativeFactors: "episode/setAssessmentNegativeFactors",
            setAssessmentPositiveFactors: "episode/setAssessmentPositiveFactors",
            setAssessmentLastTreatment: "episode/setAssessmentLastTreatment",
            setAssessmentOtherFactors: "episode/setAssessmentOtherFactors",
            setAssessmentIsReoccurrence: "episode/setAssessmentIsReoccurrence",

        }),
        reset() {
            this.immediacy = null
            this.startedAt = null
            this.severityDirection = "same"
            this.negativeFactors = ""
            this.positiveFactors = ""
            this.lastTreatment = ""
            this.otherFactors = ""
            this.isReoccurrence = false
            this.is_immediate = null
        },
    },
}
</script>