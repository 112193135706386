<template>
  <div>
    <div class="text-center" v-if="showOverlay">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="container" v-if="!showOverlay">
      <!-- <form @submit="onSubmit"> -->
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="sel1">Immunization Type:</label>
            <b-form-select
              v-model="form.immunization_type"
              :options="immunization_types"
            ></b-form-select>
          </div>
        </div>
        <div class="col">
          <label>Date Administered</label>
          <b-form-datepicker v-model="form.administered_at"></b-form-datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="form.immunization_type !== null">
          <div class="form-group">
            <label for="brand">Brand</label>
            <b-form-select
              v-model="form.immunization_id"
              :options="immunization_brands"
            ></b-form-select>
          </div>
        </div>

        <div class="col" v-if="required_doses > 1">
          <label>Next Dose Date</label>
          <b-form-datepicker
            v-model="form.next_required_dose"
            :disabled="form.immunization_id == null"
          >
          </b-form-datepicker>
        </div>
      </div>
      <div class="row" v-if="required_doses > 1 && !is_covid">
        <div class="col">
          <label for="repeat_interval">Repeat Intervals</label>
          This vaccine must be re-administered every {{ repeat_number }}
          {{ repeat_interval }}
        </div>
      </div>

      <div class="row" v-if="required_doses > 1 && is_covid">
        <div class="col">
          <label for="repeat_interval">Repeat Intervals</label>
          This vaccine must be re-administered in {{ repeat_number }}
          {{ repeat_interval }}
        </div>
      </div>

      <div class="row" v-if="is_covid">
        <div class="col">
          <div class="form-group">
            <label for="sel1">&nbsp;</label>
            <div>
              <span class="text-danger">
                <strong>This is a COVID-19 Vaccine</strong></span
              >
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="sel1">Patient COVID Status</label>
            <b-form-select
              v-model="form.has_covid"
              :options="covid_status"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="row" v-if="form.brand !== null">
        <div class="col">
          <div class="form-group">
            <label for="lot_number">Lot or Batch Number</label>
            <input type="text" v-model="form.lot_number" class="form-control" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col mb-4">
          <div class="form-group">
            <app-button
              @click="submitImmunization"
              :disabled="!canSubmit"
              v-if="editorMode === 'add'"
              ><i class="fas fa-check"></i>&nbsp;Add Immunization
            </app-button>
            <app-button
              @click="editImmunization"
              :disabled="!canSubmit"
              v-if="editorMode === 'edit'"
              ><i class="fas fa-check"></i>&nbsp;Update Immunization
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/AppButton";

export default {
  components: {
    AppButton,
  },
  name: "ImmunizationEditor",
  props: {
    patientId: Number,
    patientImmunizationId: Number,
    editorMode: String,
    immunization: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
          patient_id: 0,
          immunization_id: 0,
          administered_at: null,
          brand: null,
          next_required_dose: null,
          lot_number: null,
          has_covid: null,
          created_by: 0,
          created_at: null,
          updated_at: null,
          immunization: {
            id: 0,
            type: null,
            name: null,
            description: null,
            required_doses: 0,
            repeat_number: 0,
            repeat_interval: null,
            requires_lot_number: 0,
            is_covid: 0,
            created_at: null,
            updated_at: null,
          },
        };
      },
    },
  },
  data() {
    return {
      form: {
        id: 0,
        patient_id: this.patientId,
        immunization_id: null,
        immunization_type: null,
        administered_at: null,
        brand: null,
        next_required_dose: null,
        lot_number: null,
        has_covid: "no",
        immunization: {
          id: null,
          type: null,
          name: null,
          description: null,
          required_doses: 0,
          repeat_number: 0,
          repeat_interval: null,
          requires_lot_number: null,
          is_covid: 0,
          created_at: null,
          updated_at: null,
        },
      },
      immunization_types: [{ value: null, text: "Please select a vaccine." }],
      immunization_brands: [{ value: null, text: "Please select a vaccine." }],
      is_covid: false,
      requires_lot: false,
      repeat_interval: null,
      repeat_number: 0,
      required_doses: 0,
      covid_status: [
        { value: "no", text: "Does Not Have COVID" },
        { value: "yes", text: "Has COVID" },
        { value: "recovered", text: "Recovered From COVID" },
      ],
      showOverlay: true,
    };
  },
  watch: {
    immunizationBrandChanged() {
      this.immunization_brands = [
        { value: null, text: "Please select a brand." },
      ];
      if (this.form.immunization_type !== null) {
        this.getImmunizations.forEach((immunization) => {
          if (immunization.type === this.form.immunization_type) {
            this.immunization_brands.push({
              value: immunization.id,
              text: immunization.name,
            });
          }
        });
      }
    },
    immunizationChanged() {
      let immunization =
        this.getImmunizations.filter(
          (item) => item.id === this.form.immunization_id
        )[0] ?? null;

      if (immunization != null) {
        this.is_covid = immunization.is_covid ?? false;
        this.requires_lot = immunization.requires_lot_number ?? false;
        this.form.brand = immunization.name ?? "None";
        this.repeat_number = immunization.repeat_number ?? 0;
        this.repeat_interval = immunization.repeat_interval ?? "days";
        this.required_doses = immunization.required_doses ?? 0;
        this.form.immunization = immunization;
      }
    },

    administeredAtChanged() {
      let requiredDate = moment(this.form.administered_at);

      this.form.next_required_dose = requiredDate
        .add(this.repeat_number, this.repeat_interval)
        .format("YYYY-MM-DD");
    },
  },
  computed: {
    ...mapGetters({
      getImmunizations: "configuration/immunizations",
    }),

    canSubmit() {
      return (
        this.hasValidPatientId &&
        this.hasValidImmunizationId &&
        this.hasValidAdministeredDate &&
        this.hasValidBrand &&
        this.hasValidNextRequiredDoseDate &&
        this.hasValidLotNumber &&
        this.hasValidCovidStatus
      );
    },

    immunizationBrandChanged() {
      this.resetForm();
      return this.form.immunization_type;
    },

    immunizationChanged() {
      return this.form.immunization_id;
    },

    administeredAtChanged() {
      return this.form.administered_at;
    },

    hasValidPatientId() {
      return this.patientId !== null;
    },

    hasValidImmunizationId() {
      return this.immunization_id !== null;
    },

    hasValidAdministeredDate() {
      return this.form.administered_at !== null;
    },

    hasValidBrand() {
      return this.is_covid ? this.form.brand !== null : true;
    },

    hasValidNextRequiredDoseDate() {
      return this.required_doses > 1
        ? this.form.next_required_dose !== null
        : true;
    },

    hasValidLotNumber() {
      return true;
    },

    hasValidCovidStatus() {
      return this.is_covid ? this.form.has_covid !== null : true;
    },
  },

  mounted: async function () {
    this.showLoadingOverlay();
    await this.loadData();
    if (this.editorMode === "edit") {
      await this.loadCurrentImmunization();
    }
    this.hideLoadingOverlay();
  },
  methods: {
    ...mapActions({
      updateImmunization: "episode/updateImmunization",
      updatePatientImmunization: "episode/updateImmunization",
      addImmunization: "episode/addImmunization",
    }),

    generateId() {
      return new Date().getTime() + Math.random().toString(16).slice(2);
    },

    showLoadingOverlay() {
      this.showOverlay = true;
    },

    hideLoadingOverlay() {
      this.showOverlay = false;
    },

    loadCurrentImmunization() {
      this.is_covid = this.immunization.immunization.is_covid === 1;
      this.requires_lot =
        this.immunization.immunization.requires_lot_number === 1;
      this.repeat_interval = this.immunization.immunization.repeat_interval;
      this.repeat_number = this.immunization.immunization.repeat_number;
      this.required_doses = this.immunization.immunization.required_doses;
      this.form.id = this.immunization.id;
      this.form.immunization_id = this.immunization.immunization_id;
      this.form.patient_id = this.immunization.patient_id;
      this.form.brand = this.immunization.brand;
      this.form.immunization_type = this.immunization.immunization.type;

      this.form.has_covid = this.immunization.has_covid;

      this.form.administered_at = this.immunization.administered_at;
      this.form.next_required_dose = this.immunization.next_required_dose;
      this.form.lot_number = this.immunization.lot_number;
      this.form.immunization = this.immunization.immunization;
    },

    resetForm() {
      if (this.editorMode === "edit") {
        return;
      }

      this.form.patient_id = this.patientId;
      this.form.id = 0;
      this.form.administered_at = null;
      this.form.brand = null;
      this.form.next_required_dose = null;
      this.form.lot_number = null;
      this.form.has_covid = "no";

      this.is_covid = false;
      this.requires_lot = false;
      this.repeat_interval = null;
      this.repeat_number = 0;
      this.required_doses = 0;

      this.form.immunization = {
        id: null,
        type: null,
        name: null,
        description: null,
        required_doses: 0,
        repeat_number: 0,
        repeat_interval: null,
        requires_lot_number: null,
        is_covid: 0,
        created_at: null,
        updated_at: null,
      };
    },

    async loadData() {
      this.immunization_types = [];
      let immunizationTypes = [];
      this.immunization_types.push({
        value: null,
        text: "Please select type.",
      });
      this.getImmunizations.forEach((immunization) => {
        immunizationTypes.push(immunization.type);
      });
      const immunizations = new Set(immunizationTypes);

      immunizations.forEach((immunization) => {
        this.immunization_types.push({
          value: immunization,
          text: immunization.toUpperCase(),
        });
      });
    },
    async editImmunization() {
      if (!this.canSubmit) {
        await this.$bvModal.msgBoxOk(
          `Please review the entries, the data is invalid.`,
          {
            title: "Invalid Data",
            size: "sm",
            buttonSize: "sm",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        return;
      }

      await this.updatePatientImmunization(this.form);
      this.$parent.hideImmunizationModal();
    },
    async submitImmunization() {
      if (!this.canSubmit) {
        await this.$bvModal.msgBoxOk(
          `Please review the entries, the data is invalid.`,
          {
            title: "Invalid Data",
            size: "sm",
            buttonSize: "sm",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        return;
      }

      this.form.id = this.generateId();
      this.form.action = "C";

      this.addImmunization(this.form);
      this.$parent.hideImmunizationModal();
    },
    onClick() {
      this.$emit("toggle-immunization");
    },
    ValidateImmunization() {
      return (
        this.hasValidImmunizationType &&
        this.hasValidAdministeredDate &&
        this.hasValidBrand &&
        this.hasValidDoseDate &&
        this.hasValidCovidStatus &&
        this.hasValidIsCovid &&
        this.hasValidRepeatIntervals &&
        this.hasValidLotNumber &&
        this.hasValidDescription
      );
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}

input[type="text"] {
  flex: 10;
  padding: 10px;
  border: 1px solid #41b883;
  outline: 0;
}

input[type="submit"] {
  flex: 1;
  background: #41b883;
  color: #fff;
  border: 1px #41b883 solid;
  cursor: pointer;
}
</style>
