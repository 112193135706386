<template>
    <b-row>
        <b-col cols="1">
            &nbsp;
        </b-col>
        <b-col cols="11">
                <span>
                <slot name="icon"></slot> {{ text }}
            </span>
        </b-col>
    </b-row>
</template>
<script>
export default {
    name: "ObservationAlertItem",
    props: {
        text: {
            required: true,
            type: String,
        },
    },
}
</script>
<style scoped>

</style>