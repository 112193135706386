var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"escalation escalation-icon",class:{
      'warning low-warning': _vm.hasLowWarning,
      'warning medium-warning': _vm.hasMediumWarning,
      'warning high-warning': _vm.hasHighWarning,
    },attrs:{"id":'intervention-list-' + _vm.episode.id,"title":"Escalation"}},[(_vm.hasInterventions)?_c('img',{staticClass:"icon-size",attrs:{"src":require("../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/Intervention-ICONx64-RED.png"),"alt":"Has Escalations"}}):_vm._e(),(!_vm.hasInterventions)?_c('img',{staticClass:"icon-size",attrs:{"src":require("../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png"),"alt":"No Escalations"}}):_vm._e(),(_vm.showInterventionCountBadge)?_c('span',{staticClass:"escalation-notifications"},[_vm._v(_vm._s(_vm.activeInterventions.length))]):_vm._e()]),_c('b-popover',{attrs:{"target":'intervention-list-' + _vm.episode.id,"triggers":"hover focus","placement":"left","show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.interventions),function(intervention){return _c('b-list-group-item',{key:intervention.id,staticClass:"d-flex justify-content-between align-items-center f-poppins",attrs:{"id":intervention.id,"button":"","variant":_vm.hasActivity(intervention.id, _vm.episode.interventions)},on:{"click":function($event){return _vm.openActivity(intervention.id)}}},[_vm._v(" "+_vm._s(intervention.intervention)+" "),_c('b-icon',{class:[
            'rounded-circle',
            'p-2',
            _vm.hasActivityBackground(intervention.id, _vm.episode.interventions) ],attrs:{"icon":"bell-fill","variant":"light"}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }