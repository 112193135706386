<template>
    <div>
        <b-form-textarea
            id="message"
            v-model="messageText"
            placeholder="Enter your message"
            rows="2"
            max-rows="2"
            :disabled="isSending"
        ></b-form-textarea>
        <app-button @click="sendMessage" class="mt-2" is-narrow :is-disabled="!canSend"
        >{{ sendButtonText }}
        </app-button
        >
    </div>
</template>

<script>
import AppButton from "../AppButton.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MessageInput",
    components: {AppButton},
    data() {
        return {
            messageText: "",
            isSending: false,
        }
    },
    computed: {
        ...mapGetters({
        }),
        sendButtonText() {

            return this.isSending ? "Sending..." : "Send"
        },

        hasMessage() {

            return this.messageText.length > 0
        },

        canSend() {

            // if a message is sending, we should not enable the button.
            if(this.isSending === true) {

                return false;
            }

            //  if there is no text, we should not enable the button
            if(this.hasMessage === false) {

                return false;
            }

            //  otherwise we can send
            return true;
        },
    },
    methods: {
        ...mapActions({
            sendNewMessage: "messages/sendNewMessage",
            loadMessages: "messages/loadMessages",
            loadEpisodeById: "episode/loadEpisodeById",
            episode: "episode/getEpisode",
            getEpisodeId: "episode/getEpisodeId",

        }),
        async sendMessage() {

            this.isSending = true;

            if (await this.sendNewMessage(this.messageText)) {
                this.clearMessage();
                await this.loadMessages();
                this.$emit('messageSent')
            }
            this.isSending = false;
        },

        clearMessage() {
            this.messageText = "";
        },
    },
}
</script>

<style scoped>

</style>