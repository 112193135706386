const EscalationPageState = Object.freeze({
    INVOKE: 1,
    SYMPTOMS: 2,
    VITALS: 3,
    EVALUATE: 4,
    COMMUNICATE: 4,
    TEST: 5,
    MONITOR: 6,


})
export default EscalationPageState