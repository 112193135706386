<template>
  <div>
    <form>
      <div class="row residentForm-headding-button-row">
        <app-button
          :isSelected="activePage === 1"
          v-if="meAuthorised"
          @click="changePage(1)"
          >Key Clinical Info</app-button
        >
        <app-button
          :isSelected="activePage === 2"
          v-if="meAuthorised"
          @click="changePage(2)"
          >High Risk Conditions/Treatment</app-button
        >
        <app-button
          :isSelected="activePage === 3"
          v-if="meAuthorised"
          @click="changePage(3)"
          >Procedures & Key Findings</app-button
        >
        <app-button
          :isSelected="activePage === 4"
          v-if="meAuthorised"
          @click="changePage(4)"
          >Medications & Allergies</app-button
        >
      </div>

      <div v-if="activePage == 1">
        <b-form>
          <b-form-row id="subheading-wrapper">
            <p class="subheading">Vital Signs</p>
          </b-form-row>
          <b-form-row class="text-center episode-clinical-info--form" align-v="baseline">
            <b-col>
              <b-form-group
                label="Blood Glucose"
                label-for="input-blood-glucose"
              >
                <b-form-input
                  id="input-blood-glucose"
                  type="number"
                  v-model="clinical.clinical_vitals.blood_glucose"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Oral Temp" label-for="input-temperature">
                <b-form-input
                  id="input-temperature"
                  type="number"
                  v-model="clinical.clinical_vitals.temperature"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Respiratory Rate"
                label-for="input-respiratory-rate"
              >
                <b-form-input
                  id="input-respiratory-rate"
                  type="number"
                  v-model="clinical.clinical_vitals.respiration_rate"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Resting Pulse"
                label-for="input-resting-pulse"
              >
                <b-form-input
                  id="input-resting-pulse"
                  type="number"
                  v-model="clinical.clinical_vitals.resting_pulse"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Heart Rate(BPM)"
                label-for="input-heart-rate"
              >
                <b-form-input
                  id="input-heart-rate"
                  type="number"
                  v-model="clinical.clinical_vitals.heart_rate"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="O2 Saturation"
                label-for="input-o2-saturation"
              >
                <b-form-input
                  id="input-o2-saturation"
                  type="number"
                  v-model="clinical.clinical_vitals.o2_saturation"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Current Weight" label-for="input-weight">
                <b-form-input
                  id="input-weight"
                  type="number"
                  v-model="clinical.clinical_vitals.weight"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Irregular Pulse"
                label-for="input-irregular-pulse"
              >
                <b-form-checkbox
                  id="input-irregular-pulse"
                  value="yes"
                  unchecked-value="no"
                  v-model="clinical.clinical_vitals.irregular_pulse"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Blood Pressure"
                label-for="input-blood-pressure"
              >
                <b-form-input
                  id="input-blood-pressure"
                  type="text"
                  v-model="clinical.clinical_vitals.blood_pressure"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="4">
              <b-form-group label="Pain Site" label-for="input-pain-site">
                <b-form-input
                  id="input-pain-site"
                  type="text"
                  v-model="clinical.clinical_vitals.pain_site"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Pain Rating:" label-for="select-pain-rating">
                <b-form-select
                  v-model="clinical.clinical_vitals.pain_rating"
                  :options="painRatingOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Mental State:"
                label-for="select-mental-state"
              >
                <b-form-select
                  v-model="clinical.clinical_vitals.mental_state"
                  :options="mentalStateOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
        <hr />
      </div>

      <div v-if="activePage == 2">
        <b-form>
          <b-form-row>
            <b-col>
              <b-form-group>
                <p>Diabetic</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-diabetic"
                  v-model="clinical.high_risk.diabetic_status"
                  name="cbxDiabetic"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <div v-if="clinical.high_risk.diabetic_status == 1">
            <b-form-row>
              <b-col cols="6">
                <b-form-group
                  label="Most Recent Glucose Reading:"
                  label-for="input-glucose-date"
                  label-cols="4"
                >
                  <b-form-input
                    id="input-glucose-reading"
                    v-model="clinical.clinical_vitals.blood_glucose"
                    type="number"
                    placeholder="..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <b-form-datepicker
                    id="input-glucose-date"
                    v-model="clinical.high_risk.glucose_date"
                    placeholder="..."
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <b-form-timepicker
                    id="input-glucose-time"
                    v-model="clinical.high_risk.glucose_time"
                    placeholder="..."
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </div>
          <hr />
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>Fall Risk</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-fall-risk"
                  v-model="clinical.high_risk.fall_risk_status"
                  name="cbxFallRisk"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
                <b-form-input
                  v-if="clinical.high_risk.fall_risk_status == 1"
                  v-model="clinical.high_risk.fall_risk_precautions"
                  placeholder="Please list precautions taken..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row>
            <b-col cols="4">
              <b-form-group>
                <p>Heart Failure</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-heart-failure"
                  v-model="clinical.high_risk.heart_failure_status"
                  name="cbxHeartFailure"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <p>New Diagnosis</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-new-diagnosis"
                  v-model="clinical.high_risk.new_diagnosis_status"
                  name="cbxnewDiagnosis"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <p>Exacerbation in Admission</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-exacerbation"
                  v-model="clinical.high_risk.exacerbation_status"
                  name="cbxExacerbation"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <b-form-group
                label="Date of Last Echo:"
                label-for="input-echo-date"
                label-cols="6"
              >
                <b-form-datepicker
                  id="input-echo-date"
                  v-model="clinical.high_risk.echo_date"
                  placeholder="..."
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Dry Weight:"
                label-for="input-dry-weight"
                label-cols="6"
              >
                <b-form-input
                  id="input-dry-weight"
                  v-model="clinical.high_risk.dry_weight"
                  type="number"
                  placeholder="..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>EF%</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-ef"
                  v-model="clinical.high_risk.ef_status"
                  name="cbxEF"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
                <b-form-input
                  v-if="clinical.high_risk.ef_status == 1"
                  v-model="clinical.high_risk.ef_level"
                  type="number"
                  placeholder="EF Level..."
                  min="1"
                  max="100"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
        </b-form>
      </div>

      <div v-if="activePage == 3">
        <b-form>
          <b-form-row>
            <b-col cols="12">
              <label for="text-primary-diagnosis">Primary Diagnosis:</label>
              <b-form-input
                id="text-primary-diagnosis"
                v-model="clinical.findings.primary_diagnosis"
                placeholder="..."
              ></b-form-input>
            </b-col> </b-form-row
          ><b-form-row>
            <b-col cols="12">
              <label for="text-other-diagnosis">Other Medical Diagnosis:</label>
              <b-form-input
                id="text-other-diagnosis"
                v-model="clinical.findings.other_diagnosis"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <label for="text-mental-diagnosis">Mental Diagnosis:</label>
              <b-form-input
                id="text-mental-diagnosis"
                v-model="clinical.findings.mental_diagnosis"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>
          <br />
          <b-form-row>
            <b-modal id="procedure-confirm-modal" centered hide-footer>
              <template v-slot:default="{ close }">
                <p>
                  Are you sure you want to remove
                  {{ removed_procedure_item.procedure }}?
                </p>
                <br />
                <b-button
                  class="residentTable-model-delete-btn"
                  @click="close()"
                  >Cancel</b-button
                >
                <b-button
                  class="residentTable-model-cancel-btn"
                  @click="handleRemoveProcedure()"
                  >Remove</b-button
                >
              </template>
            </b-modal>

            <b-col cols="6">
              <b-form-group
                label="Procedure:"
                label-cols="3"
                label-for="text-procedure-name"
              >
                <b-form-input
                  id="text-procedure-name"
                  v-model="procedure_name"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group
                label="Key Findings:"
                label-cols="3"
                label-for="text-key-findings"
              >
                <b-form-input
                  id="text-key-findings"
                  v-model="key_findings"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <b-button variant="primary" @click="addProcedureToTable()">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-table
              striped
              v-if="clinical.findings.procedure_list.length > 0"
              :items="clinical.findings.procedure_list"
              :fields="procedures_fields"
              class="residentTable-style"
              thead-class="table-headers"
              tbody-class="table-body"
              ><template v-slot:cell(actions)="{ item }">
                <b-btn
                  class="residentTable-edit-button"
                  v-b-modal.procedure-confirm-modal
                  @click="removeProcedureFromTable(item)"
                >
                  REMOVE
                </b-btn>
              </template></b-table
            >
          </b-form-row>
        </b-form>
      </div>

      <div v-if="activePage == 4">
        <b-form>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>On Pain Medication</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-pain-meds"
                  v-model="clinical.meds_allergies.pain_meds_status"
                  name="cbxPainMeds"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
                <b-form-input
                  v-if="clinical.meds_allergies.pain_meds_status == 1"
                  v-model="clinical.meds_allergies.pain_meds"
                  placeholder="Please list pain medications..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row>
            <b-modal id="discontinue-confirm-modal" centered hide-footer>
              <template v-slot:default="{ close }">
                <p>
                  Are you sure you want to discontinue
                  {{ discontinued_item.medication_name }}?
                </p>
                <br />
                <b-button
                  class="residentTable-model-delete-btn"
                  @click="close()"
                  >Cancel</b-button
                >
                <b-button
                  class="residentTable-model-cancel-btn"
                  @click="handleDiscontinue()"
                  >Discontinue</b-button
                >
              </template>
            </b-modal>

            <b-col cols="12">
              <b-form-group>
                <label for="text-meds-list">Medications List:</label>
                <b-form-textarea
                  id="text-meds-list"
                  v-model="clinical.meds_allergies.medication_attached"
                  placeholder="..."
                  rows="4"
                  max-rows="8"
                  no-resize
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row>
            <b-col cols="5">
              <b-form-group
                label="Med:"
                label-cols="2"
                label-for="clinical-info-medications-list"
              >
                <b-form-input
                  id="clinical-info-medications-list"
                  v-model="med"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Dose:"
                label-cols="2"
                label-for="clinical-info-medications-dose"
              >
                <b-form-input
                  id="clinical-info-medications-dose"
                  v-model="dose"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Route:"
                label-cols="2"
                label-for="clinical-info-medications-route"
              >
                <b-form-input
                  id="clinical-info-medications-route"
                  v-model="route"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="addMedToTable()">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-table
              v-if="clinical.meds_allergies.meds_list.length > 0"
              striped
              :items="clinical.meds_allergies.meds_list"
              :fields="medication_fields"
              class="residentTable-style"
              thead-class="table-headers"
              tbody-class="table-body"
              ><template v-slot:cell(actions)="{ item }">
                <b-button
                  v-b-modal.discontinue-confirm-modal
                  variant="danger"
                  @click="discontinue(item)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button> </template
            ></b-table>
          </b-form-row>

          <hr />

          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>On Antibiotics</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-antibiotics"
                  v-model="clinical.meds_allergies.antibiotics_status"
                  name="cbxAntibiotics"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <b-form-group
                label="Total Treatment Course: "
                label-cols="5"
                label-for="clinical-info-antibiotics-course"
              >
                <b-form-input
                  id="clinical-info-antibiotics-course"
                  v-model="clinical.meds_allergies.antibiotics_course"
                  type="number"
                  placeholder="days..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Date Started: "
                label-cols="4"
                label-for="clinical-info-antibiotics-start-date"
              >
                <b-form-datepicker
                  id="clinical-info-antibiotics-start-date"
                  v-model="clinical.meds_allergies.antibiotics_start_date"
                  placeholder="..."
                >
                </b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="12">
              <b-form-group
                label="Indications: "
                label-cols="2"
                label-for="clinical-info-antibiotics-indications"
              >
                <b-form-input
                  id="clinical-info-antibiotics-indications"
                  v-model="clinical.meds_allergies.antibiotics_indications"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>On PPI (Proton Pump Inhibitors)</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-ppi"
                  v-model="clinical.meds_allergies.on_ppi_status"
                  name="cbxPainMeds"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
                <b-form-select
                  v-model="clinical.meds_allergies.ppi_type"
                  :options="ppiOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />

          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>Anticoagulated (Blood Thinners)</p>
                <b-form-checkbox
                  class="checkbox"
                  id="clinical-info-blood-thinner"
                  v-model="clinical.meds_allergies.blood_thinner_status"
                  name="cbxBloodThinners"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                label="Reasons: "
                label-for="clinical-info-bloodthinner-reason"
              >
                <b-form-select
                  v-model="clinical.meds_allergies.blood_thinner_reasons"
                  :options="bloodThinnerOptions"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <b-form-input
                  id="clinical-info-bloodthinner-reason"
                  v-if="bloodThinnersOtherSelected == 1"
                  v-model="bloodThinnerList"
                  placeholder="Specify other reason..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />

          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p>Allergies</p>
                <b-form-checkbox
                  class="checkbox mycheckbox"
                  id="clinical-info-allergies"
                  v-model="clinical.meds_allergies.allergies_status"
                  name="cbxAllergies"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
                <b-form-input
                  v-if="clinical.meds_allergies.allergies_status == 1"
                  v-model="clinical.meds_allergies.allergies"
                  placeholder="Please list allergies..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
    </form>
  </div>
</template>

<script>
import { constantMixin } from "@/mixins/constants.js";
import AppButton from "@/components/AppButton";
import { mapGetters } from "vuex";

export default {
  name: "ResidentClinicalInfo",
  components: {
    AppButton,
  },
  computed: {
    ...mapGetters({
      getEpisode: "episode/getEpisode",
    }),
  },
  data: function() {
    return {
      clinical: {
        clinical_vitals: {
          pain_site: null,
          pain_rating: null,
          temperature: null,
          blood_glucose: null,
          blood_pressure: null,
          heart_rate: null,
          respiration_rate: null,
          o2_saturation: null,
          weight: null,
          mental_state: null,
          resting_pulse: null,
          irregular_pulse: "no",
          episode_id: null,
          patient_id: null,
        },

        high_risk: {
          diabetic_status: 0,
          fall_risk_status: 0,
          glucose_date: null,
          glucose_time: null,
          fall_risk_precautions: "",
          heart_failure_status: 0,
          new_diagnosis_status: 0,
          exacerbation_status: 0,
          echo_date: null,
          dry_weight: null,
          ef_status: 0,
          ef_level: null,
          episode_id: null,
          patient_id: null,
        },

        findings: {
          primary_diagnosis: null,
          other_diagnosis: null,
          mental_diagnosis: null,
          procedure_list: [],
          removed_procedure_list: [],
          episode_id: null,
          patient_id: null,
        },

        meds_allergies: {
          medication_attached: null,
          meds_list: [],
          discontinued_meds_list: [],

          allergies_status: 0,
          allergies: "",

          antibiotics_status: 0,
          antibiotics_indications: "",
          antibiotics_course: null,
          antibiotics_start_date: null,

          pain_meds_status: 0,
          pain_meds: "",

          on_ppi_status: 0,
          ppi_type: null,

          blood_thinner_status: 0,
          blood_thinner_reasons: [],
          episode_id: null,
          patient_id: null,
        },
      },
      medication_fields: [
        {
          key: "med_description",
          sortable: true,
        },
        {
          key: "dosage_frequency",
          sortable: false,
        },
        {
          key: "route",
          sortable: false,
        },
        {
          key: "actions",
          label: "Discontinue",
          thClass: "residentTable-remove-th",
          tdClass: "residentTable-remove-td",
        },
      ],
      procedures_fields: [
        {
          key: "procedure",
          sortable: true,
        },
        {
          key: "key_findings",
          sortable: false,
        },
        {
          key: "actions",
          label: "Remove",
          thClass: "residentTable-remove-th",
          tdClass: "residentTable-remove-td",
        },
      ],
      meAuthorised: true,
      activePage: 2,

      key_findings: null,
      procedure_name: null,
      removed_procedure_item: null,

      mentalStateOptions: [
        { value: "Alert", text: "Alert" },
        { value: "Not Alert", text: "Not Alert" },
        {
          value: "Disoriented (Follows Commands)",
          text: "Disoriented (Follows Commands)",
        },
        {
          value: "Disoriented (Cannot Follow Commands)",
          text: "Disoriented (Cannot Follow Commands)",
        },
      ],

      dose: null,
      med: null,
      route: null,
      discontinued_item: null,

      ppiOptions: [
        { value: null, text: "..." },
        {
          value: "In-hospital prophylaxis and can be d/c (discontinued)",
          text: "In-hospital prophylaxis and can be d/c (discontinued)",
        },
        { value: "Specific Dx (Diagnosis)", text: "Specific Dx (Diagnosis)" },
      ],
      bloodThinnerOptions: [
        { value: "Afib", text: "Afib" },
        { value: "DVT/PE", text: "DVT/PE" },
        { value: "Mech. Valve", text: "Mech. Valve" },
        { value: "Post-OP", text: "Post-OP" },
        { value: "Low EF", text: "Low EF" },
        { value: "Other", text: "Other" },
      ],
      painRatingOptions: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
      ],
      bloodThinnersOtherSelected: false,
      bloodThinnerList: null,
    };
  },
  methods: {
    changePage(newPage) {
      this.activePage = newPage;
    },
    setStartPage() {
      this.activePage = 1;
    },
    addMedToTable() {
      this.$parent.meds_allergies.meds_list.push({
        med_description: this.med,
        dosage_frequency: this.dose,
        route: this.route,
      });
    },
    addProcedureToTable() {
      this.$parent.findings.procedure_list.push({
        procedure: this.procedure_name,
        key_findings: this.key_findings,
      });
    },
    removeProcedureFromTable(item) {
      this.removed_procedure_item = item;
    },
    handleRemoveProcedure() {
      this.$parent.findings.removed_procedure_list.push(
        this.removed_procedure_item
      );
      var index = this.$parent.findings.procedure_list.indexOf(
        this.removed_procedure_item
      );
      this.$parent.findings.procedure_list.splice(index, 1);
      this.$bvModal.hide("procedure-confirm-modal");
    },
    discontinue(item) {
      this.discontinued_item = item;
    },
    handleDiscontinue() {
      this.$parent.meds_allergies.discontinued_meds_list.push(
        this.discontinued_item
      );
      var index = this.$parent.meds_allergies.meds_list.indexOf(
        this.discontinued_item
      );
      this.$parent.meds_allergies.meds_list.splice(index, 1);
      this.$bvModal.hide("discontinue-confirm-modal");
    },
  },
  watch: {
    bloodThinnerReasons: function(val) {
      function checkSelected(value) {
        return value == 5;
      }

      var otherSelected = val.find(checkSelected);
      if (otherSelected == 5) {
        this.bloodThinnersOtherSelected = 1;
      } else {
        this.bloodThinnersOtherSelected = 0;
      }
    },
    clinical: {
      handler(val) {
        this.$parent.clinical = val;
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.$store.getters["authentication/user"].roles.find(
        (u) => u.role.name === constantMixin.SUPERADMIN
      ) != undefined
    ) {
      this.meAuthorised = true;
      this.setStartPage();
    } else {
      this.meAuthorised = false;
    }
  },
};
</script>

<style scoped>
.subheading {
  color: #0073c5 !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  margin: 17px 0 30px 0;
  text-align: center;
}

.residentForm-heading-button {
  min-width: 23%;
  max-width: 33%;
}

.residentForm-heading-button-unselected {
  min-width: 23%;
  max-width: 35%;
}

p {
  float: left;
  margin-right: 5px;
}

.checkbox {
  float: left;
}

.residentTable-remove-td {
  max-width: 100px;
}

#subheading-wrapper {
  justify-content: center;
}

.episode-clinical-info--form {
  align-items: end;
}
</style>
