<template>
  <div class="header--template">
    <app-button is-danger @click="closeMessages" class="header--button">
      <i class="fas fa-close fa-2x icon" />
    </app-button>
    <app-button @click="expandMessages" class="header--button">
      <i class="fas fa-angle-left fa-2x icon" v-if="widthSelector === '<'" />
      <i class="fas fa-angle-right fa-2x icon" v-if="widthSelector === '>'" />
    </app-button>
    <div class="header--patient-info">
      <span class="header--patient-name">{{ patientName }}</span>
      <span class="header--patient-room">{{ `(Room: ${roomNumber})` }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "../AppButton.vue";

export default {
    props: {
        widthSelector: {
            type: String,
            default: "<"
        }
    },
  components: { AppButton },

  computed: {
    ...mapGetters({
      episode: "episode/getEpisode",
    }),

    patientName() {
      if (this.episode?.patient?.name && this.episode?.patient?.last_name) {
        return `${this.episode.patient.name} ${this.episode.patient.last_name}`;
      }

      return "Loading patient name...";
    },

    roomNumber() {
      return this.episode?.bed_number || "No room";
    },
  },

  methods: {
    ...mapActions({
      closeMessages: "messages/closeMessages",
    }),

    expandMessages() {
      this.$emit("expand-sidebar");
    },
  },
};
</script>
