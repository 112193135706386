<template>
    <div class="container-lg">
        <div class="row residentForm-headding-button-row">
            <app-button isSelected @click="changePage(1)">Escalations</app-button>
            <app-button :disabled="meNotAuthorised" @click="generatePdfReport"
            >Escalation Path Report
                <font-awesome-icon :icon="['fas', 'plus']"/>
            </app-button>
        </div>

        <template v-if="showPage === 1 && !isLoading">
            <b-table
                striped
                id="interventions"
                :items="displayInterventions"
                class="residentTable-style"
                :fields="fields"
                thead-class="table-headers"
                tbody-class="table-body"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                small
                responsive="sm"
            >
                <template v-slot:cell(notification)="data">
                    <b-img
                        height="32px"
                        :src="
              require(`@/assets/img/${bellIconImageSource(
                data.item.notification
              )}`)
            "
                        center
                    ></b-img>
                </template>

                <template v-slot:cell(intervention)="data">
                    {{ showIntervention(data.item.intervention) }}
                </template>

                <template v-slot:cell(next_step)="data">
                    <b-button
                        v-if="
              data.item.next_step == null || data.item.next_step === 'Invoke'
            "
                        variant="secondary"
                        block
                        @click="invoke(data.item, steps.assess)"
                    >Invoke
                    </b-button>

                    <b-button
                        v-if="data.item.next_step === 'Vitals'"
                        variant="success"
                        block
                        @click="viewIntervention(data.item, steps.vital)"
                    >Vitals
                    </b-button>

                    <b-button
                        v-if="data.item.next_step === 'Notifications'"
                        variant="success"
                        block
                        @click="viewIntervention(data.item, steps.notify)"
                    >Evaluate
                    </b-button>

                    <b-button
                        v-if="data.item.next_step === 'Communicate'"
                        variant="danger"
                        block
                        @click="viewIntervention(data.item, steps.notify)"
                    >Communicate
                    </b-button>
                    <b-button
                        v-if="
              data.item.next_step === 'Assess' ||
              data.item.next_step === 'Suggested'
            "
                        variant="success"
                        block
                        @click="viewIntervention(data.item, steps.assess)"
                    >Assess
                    </b-button>

                    <b-button
                        v-if="data.item.next_step === 'Monitor'"
                        variant="warning"
                        block
                        @click="viewIntervention(data.item, steps.monitor)"
                    >Monitor
                    </b-button>
                </template>

                <template v-slot:cell(status)="data">
                    <div
                        v-if="data.item.status === 'Active'"
                        style="color: green; text-align: center"
                    >
                        Active
                    </div>
                    <div
                        v-if="data.item.status === 'Suggested'"
                        style="color: blue; text-align: center"
                    >
                        Suggested
                    </div>
                    <div
                        v-if="data.item.status === 'Priority'"
                        style="color: red; text-align: center"
                    >
                        Communicate
                    </div>
                    <div
                        v-if="data.item.status === 'Monitor'"
                        style="color: orange; text-align: center"
                    >
                        Monitor
                    </div>
                    <div
                        v-if="data.item.status === null || data.item.status === 'Inactive'"
                        style="text-align: center"
                    >
                        Inactive
                    </div>
                </template>

                <template v-slot:cell(reject)="data">
                    <b-link
                        :disabled="!data.item.id"
                        @click="closeIntervention(data.item)"
                    >
                        <font-awesome-icon
                            class="close-intervention-button"
                            :class="{ 'disabled-button': !data.item.id, 'text-success': data.item.active === 1 }"
                            :icon="['fas', 'check']"
                        />
                    </b-link>
                </template>
            </b-table>
        </template>
        <episode-intervention-symptoms
            v-if="showPage === EscalationPageState.SYMPTOMS"
        ></episode-intervention-symptoms>
        <episode-intervention-vitals
            v-if="showPage === EscalationPageState.VITALS"
        ></episode-intervention-vitals>
        <episode-intervention-evaluation
            v-if="showPage === EscalationPageState.EVALUATE"
        ></episode-intervention-evaluation>
        <episode-intervention-tests
            v-if="showPage === EscalationPageState.TEST"
        ></episode-intervention-tests>
        <episode-intervention-monitor
            v-if="showPage === EscalationPageState.MONITOR"
        ></episode-intervention-monitor>

        <b-modal id="confirmRemoveModal" centered hide-footer hide-header>
            <div class="d-block text-center">
                <h4 class="primaryTypeFace">
                    Are you sure you want to resolve this Escalation?
                </h4>
            </div>
            <div class="d-block text-center">
                <b-button
                    class="mt-3 residentTable-model-delete-btn"
                    @click="resolveEscalation()"
                >RESOLVE
                </b-button
                >
                <b-button
                    class="mt-3 residentTable-model-cancel-btn"
                    block
                    @click="hideModal"
                >CANCEL
                </b-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters, mapActions} from "vuex";
import EpisodeInterventionSymptoms from "@/components/Episode/EpisodeInterventionSymptoms";
import EpisodeInterventionVitals from "@/components/Episode/EpisodeInterventionVitals";
import EpisodeInterventionEvaluation from "@/components/Episode/EpisodeInterventionEvaluation";
import EpisodeInterventionTests from "@/components/Episode/EpisodeInterventionTest";
import EpisodeInterventionMonitor from "@/components/Episode/EpisodeInterventionMonitor";
import AppButton from "@/components/AppButton";
import {generateId} from "@/helpers/helpers";
import EscalationPageState from "../../enums/modules/EscalationPageState";

export default {
    name: "EpisodeIntervention",

    components: {
        EpisodeInterventionSymptoms,
        EpisodeInterventionVitals,
        EpisodeInterventionEvaluation,
        EpisodeInterventionTests,
        EpisodeInterventionMonitor,
        AppButton,
    },

    computed: {
        EscalationPageState() {
            return EscalationPageState;
        },
        ...mapGetters({
            getIntervention: "interventions/getIntervention",
            interventions: "configuration/interventions",
            episode: "episode/getEpisode",
            getEpisodeId: "episode/getEpisodeId",
            getInterventionList: "configuration/getInterventionList",
            getInterventionName: "episode/getInterventionName",
            currentActiveIntervention: "episode/getCurrentIntervention",
            user: "user/user",
        }),

        displayInterventions() {
            if (
                this.episode?.interventions &&
                this.episode.interventions.length === 0
            ) {
                return this.interventions;
            }

            //  merge active interventions with the list of interventions.
            const baseInterventions = this.interventions;
            const episodeInterventions = this.episode?.interventions ?? [];

            return baseInterventions.map((baseIntervention) => {
                const foundIntervention = episodeInterventions.find(
                    (episodeIntervention) =>
                        baseIntervention.id === episodeIntervention.intervention_id &&
                        episodeIntervention.active === 1
                );

                if (foundIntervention) {
                    return {
                        action: foundIntervention.action,
                        active: foundIntervention.active,
                        id: foundIntervention.id,
                        episode_id: foundIntervention.episode_id,
                        intervention_id: foundIntervention.intervention_id,
                        next_step: foundIntervention.next_step,
                        notification: foundIntervention.notification,
                        notified: foundIntervention.notified,
                        page: foundIntervention.page,
                        selected_notifications: foundIntervention.selected_notifications,
                        selected_symptoms: foundIntervention.selected_symptoms,
                        selected_monitors: foundIntervention.selected_monitors,
                        selected_tests: foundIntervention.selected_tests,
                        status: foundIntervention.status,
                        intervention: baseIntervention.intervention,
                        notifications: baseIntervention.notifications,
                        symptoms: baseIntervention.symptoms,
                        tests: baseIntervention.tests,
                        monitors: baseIntervention.monitors,
                    };
                }

                return baseIntervention;
            });
        },
    },

    data() {
        return {
            showPage: 1,
            sortBy: "intervention",
            escalation: null,
            sortDesc: true,
            fields: [
                {key: "id", thClass: "d-none", tdClass: "d-none"},
                {
                    key: "notification",
                    label: "",
                    editable: true,
                    sortDirection: "desc",
                    sortable: true,
                },
                {
                    key: "intervention",
                    label: "Escalation",
                    editable: false,
                    sortable: true,
                },
                {
                    key: "next_step",
                    label: "Next Step",
                    editable: false,
                },
                {
                    key: "status",
                    label: "Status",
                    editable: false,
                },
                {key: "alert", label: "Alert", thClass: "residentTable-edit-th"},
                {
                    key: "reject",
                    label: "Resolve",
                    thClass: "residentTable-remove-th",
                    tdClass: "residentTable-remove-td",
                },
                /*
                                                                        {
                                                                          key: "viewReport",
                                                                          label: "View Report",
                                                                          thClass: "residentTable-remove-th",
                                                                          tdClass: "residentTable-remove-td",
                                                                        },
                                                                        */
            ],
            meSearchValue: "",
            meNotAuthorised: false,
            currentIntervention: null,
            selectedSymptoms: [],
            selectedImmediateNotificationChecklist: [],
            communicate: false,
            alertVitals: [],
            testInterventions: [],
            UNSAVED: false,
            SAVED: true,
            listOfInterventions: [],
            episodeInterventions: [],
            displayListOfInterventions: [],
            steps: {
                invoke: 1,
                assess: 2,
                vital: 3,
                notify: 4,
                test: 5,
                monitor: 6,
            },
            isLoading: false,
            interventionTemplate: {
                id: null,
                episode_id: null,
                intervention_id: null,
                selected_symptoms: [],
                selected_notifications: [],
                selected_tests: [],
                next_step: [],
                alert: [],
                status: [],
                page: 1,
                active: 0,
                notified: 0,
                notified_at: null,
                read: 0,
                read_at: null,
                created_by: null,
                created_at: null,
                updated_at: null,
                action: "R",
            },
        };
    },
    mounted() {
        if (
            this.currentActiveIntervention?.action === "C" ||
            this.currentActiveIntervention?.action === "U"
        ) {
            this.changePage(2);
        }
    },

    methods: {
        ...mapActions({
            addOrUpdateIntervention: "episode/addOrUpdateIntervention",
            setInterventionName: "episode/setInterventionName",
            viewCurrentIntervention: "episode/viewCurrentIntervention",
            setEpisodeInterventionId: "episode/setEpisodeInterventionId",
            deleteSignsAndSymptom: "episode/deleteSignsAndSymptom",
            setEscalationForEpisode: "episode/setEscalationForEpisode",
            resetIntervention: "episode/resetIntervention"
        }),

        async resolveEscalation() {
            await this.setInterventionEpisodeToInactive(this.escalation)
            await this.$parent.refresh()
            this.hideModal()
        },

        hideModal() {
            this.selectedRow = null;
            this.$bvModal.hide("confirmRemoveModal");
        },

        isset(value) {
            return !!value;
        },

        bellIconImageSource(notification) {
            switch (notification) {
                case "Active":
                    return `bell-green-64.png`;
                case "Priority":
                    return `bell-red-64.png`;
                case "Suggested":
                    return `bell-blue-64.png`;
                case "Inactive":
                default:
                    return `bell-grey-64.png`;
            }
        },

        showIntervention(intervention) {
            if (intervention?.intervention) {
                return intervention.intervention;
            }

            return intervention;
        },

        async generatePdfReport() {
            axios({
                url: "interventions/pdf/" + this.getEpisodeId,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", "file.pdf");
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },

        async closeIntervention(interventionEpisode) {
            //  modal requesting confirmation

            this.escalation = interventionEpisode
            this.$bvModal.show("confirmRemoveModal");
            // await this.$bvModal
            //     .msgBoxConfirm("Are you sure you want to resolve this Escalaton?", {
            //         title: "Resolve Escalation",
            //         size: "sm",
            //         buttonSize: "sm",
            //         okVariant: "success",
            //         headerClass: "p-2 border-bottom-0",
            //         footerClass: "p-2 border-top-0",
            //         centered: true,
            //     })
            //     .then((confirmation) => {
            //         if (confirmation) {
            //             this.setInterventionEpisodeToInactive(interventionEpisode);
            //             this.$parent.refresh();
            //         }
            //     });
            //  then set status to false
        },

        removeInterventionFromEpisode(interventionId) {
            //  we need to remove the intervention id from list of interventions.
            const updatedEpisodeArray = this.$parent.episode.intervention_ids.filter(
                function (intervention) {
                    //  return intervention.intervention_episode_id != interventionEpisodeId;
                    return intervention.intervention_episode_id !== interventionId;
                }
            );

            this.removeLinkedChangedInCondition(interventionId);
            this.$parent.episode.intervention_ids = updatedEpisodeArray;
            // we refresh the list of interventions
            this.$parent.refresh();
        },

        async deleteIntervention(interventionEpisodeId) {
            await axios
                .delete(`interventions/${interventionEpisodeId}`)
                .catch((error) => {
                    console.error(error);
                });
        },

        async setInterventionEpisodeToInactive(interventionEpisode) {

            //  set the loading status to true
            this.isLoading = true
            //  load the selected escalation.
            await this.viewCurrentIntervention(interventionEpisode)
            //  mark the escalation as inactive.
            await this.removeInterventionFromEpisode(interventionEpisode?.id)
            await this.$store.dispatch('episode/resolveEpisodeIntervention')
            //  check to see if there are any changes in condition associated with this escalation.
            //  mark those changes in condition as complete.
            await this.$store.dispatch('episode/clearChangeInConditionByEscalationId', interventionEpisode?.id)
            //  set the loading status to false
            this.isLoading = false
            this.$parent.showPage = 1
        },

        async viewIntervention(intervention, page) {
            intervention.shouldSetAsCurrent = true;
            this.viewCurrentIntervention(intervention);
            await this.setEpisodeInterventionId(intervention.id);
            this.changePage(page);
        },

        async invoke(item, page) {
            //  invoking an intervention creates a new intervention for the existing episode. An EpisodeIntervention.
            const episodeIntervention = {
                episode_id: this.episode.id,
                intervention_id: item.id,
                selected_symptoms: [],
                selected_notifications: [],
                selected_tests: [],
                notification: "Active",
                next_step: "Assess",
                page: page,
                alert: null,
                status: "Active",
                active: true,
                notified: false,
                notified_at: null,
                created_by: this.user.id,
                intervention: item.intervention,
                name: item.intervention,
                symptoms: item.symptoms,
                notifications: item.notifications,
                monitors: item.monitors,
                tests: item.tests,
                action: "C",
                shouldSetAsCurrent: true,
                invokedFrom: "Interventions",
                episodeInterventionId: generateId(),
            };

            await this.addOrUpdateIntervention(episodeIntervention);
            await this.setInterventionName(episodeIntervention);
            await this.setEpisodeInterventionId(
                episodeIntervention.episodeInterventionId
            );
            this.changePage(page);
        },

        changePage(page) {
            this.showPage = page;
        },

        async resolveInterventions() {
            this.isLoading = true;
            const activeInterventions = await axios
                .get(`interventions-episode/${this.getEpisodeId}`)
                .catch((error) => {
                    console.error(error);
                });
            if (activeInterventions)
                this.setEscalationForEpisode(activeInterventions.data.data); // we need to set the episode state via a mutation, not directly.
            this.isLoading = false;
        },
        removeLinkedChangedInCondition(interventionId) {
            const cocs = this.episode?.signsAndSymptoms?.filter(
                (changeInCondition) =>
                    changeInCondition.episode_intervention_id === interventionId
            );

            if (cocs && cocs.length > 0) {
                cocs.forEach((condition) =>
                    this.deleteSignsAndSymptom(condition.signs_and_symptom_id)
                );
            }
        },
    },
};
</script>

<style scoped>
.close-intervention-button {
    color: black;
    height: 35px;
    width: 35px !important;
}

.disabled-button {
    cursor: default;
    opacity: 0.4;
}
</style>
