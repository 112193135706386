<template>
  <b-container>
    <b-row>
      <b-col>
        <span style="color: black; font-weight: bold; text-align: center">
          FOR
          {{ intervention | capitalize }}</span
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-center">
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                page === 2,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                page > 2,
            }"
            style="cursor: pointer"
            @click="changePage(2)"
          >
            STEP 1: SYMPTOMS
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                page === 3,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                page !== 3,
            }"
            style="cursor: pointer"
            @click="changePage(3)"
          >
            STEP 2: VITALS
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                page === 4,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                page !== 4,
            }"
            style="cursor: pointer"
            @click="changePage(4)"
          >
            STEP 3: EVALUATE
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                page === 5,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                page !== 5,
            }"
          >
            STEP 4: TESTS
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                page === 6,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                page !== 6,
            }"
            style="cursor: pointer"
            @click="changePage(6)"
          >
            STEP 5: MONITOR
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import EpisodeInterventionHeader from '@/components/Episode/EpisodeInterventionHeader'
export default {
  name: "EpisodeInterventionHeader",
  component: {
      EpisodeInterventionHeader
  },
  props: {
      intervention: {
          type: String,
          required: true,
          default: function() {
              return 'Unknown'
          }
      },
      page: {
          type: Number,
          required: true,
          default: function() {
              return 1
          }
      }
  },
    methods: {
        changePage(page) {
            this.$parent.$parent.changePage(page)
        },
    },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },
};
</script>
