<template>
    <div
        class="observation observation-icon"
        @click="residentVitalSigns(episode)"
        :class="{ 'low-warning': episode.needsVitalSigns }"
        v-b-tooltip.hover.left
        title="Vitals"
    >
        <img
            v-if="episode.needsVitalSigns"
            src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/VitalSigns-ICONx64-RED.png"
            alt="Has Escalations"
            class="icon-size"
        />
        <img
            v-if="!episode.needsVitalSigns"
            src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/VitalSigns-ICONx64.png"
            alt="No Escalations"
            class="icon-size"
        />
    </div>
</template>
<script>

import {mapActions, mapMutations} from "vuex";

export default {


    props: {
        episode: Object,
    },

    methods: {
        residentVitalSigns: function (episode) {
            this.setEpisodeId(episode.id);
            this.episode = episode;
            this.$parent.showEditor(this.episode, "edit", "vitalSigns");
        },

        ...mapActions({
            setEpisodeToHighPriority: "episodes/setEpisodePriorityToHigh",
        }),
        ...mapMutations({
            setEpisodeId: "episode/setEpisodeId",
        }),
    },
}
</script>
<style>
.observation-icon {


}
.low-warning {
    animation-duration: 12s;
    animation-name: warning-cycle;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    border-radius: 50%;
}
@keyframes warning-cycle {
    0% {
        background: #ffffba;
    }
    50% {
        background: #ffdfba;
    }
    100% {
        background: #ffb3ba;
    }
}

.observation {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
}

.observation-notifications {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.icon-size {

    height: 40px;
    width: 40px;
}
.observation-icon {
    cursor: pointer;
}

</style>