<template>
        <b-modal
            id="sessionExpiredModal"
            title="Automatic Logout"
            size="x"
            centered
            show-backdrop
            content-class="shadow"
            no-close-on-backdrop
            no-close-on-esc
            show-header
            hide-footer
            header-bg-variant="dark"
            header-text-variant="light"
            hide-header-close
        >
            <b-container>
                <b-row>
                    <b-col>
                        <h3>Hello!</h3>
                        <p>We haven't noticed any activity recently, and as a security and privacy precaution, we will
                            log
                            you out if we do not detect any activity soon.</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
</template>
<script>
import {mapActions} from "vuex";


export default {
    name: "UserActivityMonitor",
    components: {},
    data: function () {
        return {
            timeoutEvents: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            logoutTimer: null,
            warningTimer: null,
            isWarningShown: false,
        }
    },

    mounted() {
        this.setUpUserInactivityHandler()
    },

    destroyed() {
        this.destroyUserInactivityHandler()
    },

    methods: {
        ...mapActions({
            logoutAction: "authentication/logout",
        }),

        setTimers() {

            this.logoutTimer = setTimeout(this.logout, this.$defaultTimeOut)
            this.warningTimer = setTimeout(this.showWarning, Math.floor(this.$defaultTWarningTimeOut))
        },

        clearTimers() {

            clearTimeout(this.logoutTimer)
            clearTimeout(this.warningTimer)
        },

        resetTimer() {

            this.clearTimers()
            this.setTimers()
            this.hideWarning()
        },

        showWarning() {

            this.isWarningShown = true
            this.$bvModal.show('sessionExpiredModal')
            // this.toggleWarningModal()
        },

        hideWarning() {

            this.isWarningShown = false
            this.$bvModal.hide('sessionExpiredModal')
        },

        async logout() {

            await this.unsubscribe();
            await this.logoutAction()
            await this.$router.push({path: "/login", query: {reason: 'userInactive'}});
        },

        setUpUserInactivityHandler() {
            this.timeoutEvents.forEach((event) => {
                window.addEventListener(event, this.resetTimer)
            })

            this.setTimers()
            this.hideWarning()
        },

        destroyUserInactivityHandler() {
            this.timeoutEvents.forEach((event) => {
                window.removeEventListener(event, this.resetTimer)
            })

            this.clearTimers()
        },

        async unsubscribe() {

            await this.$emit('unsubscribeChannels')
        },
    },
}
</script>

<style scoped>

</style>