export class careDirective {
  constructor() {
    this.id = undefined;
    this.episode_id = undefined;
    this.patient_id = undefined;
    this.created_by = undefined;
    this.created_at = undefined;
    this.updated_at = undefined;
    this.physical = {
      ambulatory: 0,
      weight_bearing: [],
      transfer: [],
    };
    this.sensorial = {
      sight: undefined, 
      hearing: undefined,
    };
    this.devices = [];
    this.continence = [];
    this.catheter = { 
      date_inserted: null, 
      have_retention: null, 
      have_skin_protection: null, 
      other: {
        description: null,
      },
    };
    this.bowel_movement_datetime;
    this.bowel_incontinent = undefined;
    this.nutrition = {
      diet: null, 
      consistency: null, 
      free_water_restriction: null,
      eating_instructions: [],
      speech_therapy_recommendation_description: null,
    };
    this.tube_feeding = {
      type: null,
      date_inserted: null, 
      free_water_bolus_cc: null, 
      free_water_bolus_hours: null, 
      product: {
        product_name: null, 
        rate: null, 
        duration: null,
      },
    };
    this.cardiac_treatments = {
      picc: {
        isPicc: false,
        date_inserted: new Date(),
      },
      portacath: {
        isPortacatch: false, 
        date_inserted: new Date(), 
      },
      cardiac: {
        types: [], 
        selected: null,
        other_description: null,
      },
    };
    this.respiratory = {
      types: [],
      selected: null, 
      o2_liters: null, 
      trach_size_mm: null,
    };
    this.therapies = {
      types: [],
      selected: null,
      recommendation: null,
    };
    this.skin_care = {
      has_skin_breakdown: false,
      first_pressure_ulcer: {
        stage: null, 
        location: null,
      }, 
      second_pressure_ulcer: {
        stage: null, 
        location: null,
      }, 
      other_wounds: {
        description: null,
      },
    };
    this.risks = {
      types: [],
      selected: null,
      other_description: null,
      precaution_description: null,
    };
    this.infection_control = {
      types: [],
      selected: null,
      infection_colonization: [],
      isolation_precautions: undefined,
      immunization: {
        influenza: {
          date: null,
        }, 
        pneumococcal: {
          date: null,
        },
      },
    };
  }
}

export class episode {
  constructor(facility_id) {
    this.id = null;
    this.action_priority = null;
    this.admission_date = null;
    this.care_type_id = null;
    (this.facility_id = facility_id), (this.intervention_ids = []);
    this.care_teams = []; //this is used for the care team
    this.primary_care_providers = []; //this is used at basic info side
    this.intervention_needed = false;
    this.medical_insurance_id = null;
    this.messages = [];
    this.name = null;
    this.episode_hospital_meds_before = [];
    this.episode_hospital_meds_after = [];
    this.family = [];
    this.care_directives = [new careDirective()];
    this.clinical = new clinicalInfo();

    this.patient = {
      id: null,
      name: "",
      last_name: "",
      mrn_number: "",
      social_security_number: "",
      blood_group: null,
      date_of_birth: null,
      ethnicity: null,
      ethnicity_id: null,
      ethnicity_other: null,
      gender: null,
      gender_id: null,
      gender_other: null,
      language: null,
      language_id: null,
      language_other: null,
    };
    this.priority_high = false;
    this.room = "";
    this.signs_and_symptoms = "";
    this.status = "";
    (this.transfer_date = null), (this.vitals = []);
  }
}

export class clinicalInfo {
  constructor() {
    this.episode_id = null;
    this.patient_id = null;

    this.clinical_vitals = {
      pain_site: '',
      pain_rating: [],
      temperature: 0,
      blood_glucose: 0,
      blood_pressure: 0,
      heart_rate: 0,
      respiration_rate: 0,
      o2_saturation: 0,
      weight: 0,
      mental_state: [],
      resting_pulse: null,
      irregular_pulse: "no",
    };

    this.high_risk = {
      diabetic_status: 0,
      fall_risk_status: 0,
      glucose_date: null,
      glucose_time: null,
      fall_risk_precautions: "",
      heart_failure_status: 0,
      new_diagnosis_status: 0,
      exacerbation_status: 0,
      echo_date: null,
      dry_weight: null,
      ef_status: 0,
      ef_level: null,
    };

    this.findings = {
      primary_diagnosis: null,
      other_diagnosis: null,
      mental_diagnosis: null,
      procedure_list: [],
      removed_procedure_list: [],
    };

    this.meds_allergies = {
      medication_attached: null,
      meds_list: [],
      discontinued_meds_list: [],

      allergies_status: 0,
      allergies: "",

      antibiotics_status: 0,
      antibiotics_indications: "",
      antibiotics_course: null,
      antibiotics_start_date: null,

      pain_meds_status: 0,
      pain_meds: "",

      on_ppi_status: 0,
      ppi_type: null,

      blood_thinner_status: 0,
      blood_thinner_reasons: [],
    };
  }
}