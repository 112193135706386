<template>
    <div>
        <div
            class="quickmenu-blue-icon icon-margin"
            title="Quick Menu"
            :id="'quick-menu-' + episode.id"
        ></div>
        <b-popover :target="'quick-menu-' + episode.id"
                   triggers="hover focus"
                   placement="left"
                   :show.sync="show"
        >
            <b-list-group flush>
                <b-list-group-item
                    button
                    class="d-flex justify-content-between align-items-center f-poppins"
                    @click="quickMenu('basicData')"
                >
                    Resident Info
                </b-list-group-item>
                <b-list-group-item
                    button
                    class="d-flex justify-content-between align-items-center f-poppins"
                    @click="quickMenu('medicationRecon')"
                >
                    Medication Recon.
                </b-list-group-item>
                <b-list-group-item
                    button
                    class="d-flex justify-content-between align-items-center f-poppins"
                    @click="quickMenu('interventions')"
                >
                    Escalations
                </b-list-group-item>
                <b-list-group-item
                    button
                    class="d-flex justify-content-between align-items-center f-poppins"
                    @click="quickMenu('careTeams')"
                >
                    Care Team
                </b-list-group-item>
                <b-list-group-item
                    button
                    class="d-flex justify-content-between align-items-center f-poppins"
                    @click="quickMenu('signsAndSymptoms')"
                >
                    Change in Condition
                </b-list-group-item>
            </b-list-group>
        </b-popover>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "EpisodeListItemQuickMenu",
    title: "Episode List Item Quick Menu",
    props: {
        episode: Object,
        episodeId: Number,
    },
    data: function() {
        return {
            show: false,
        }
    },
    computed: {
        ...mapGetters({
            getEpisodeId: "episode/getEpisodeId"
        }),
    },
    methods: {
        ...mapActions({
            loadEpisode: "episode/loadEpisode"
        }),
        ...mapMutations({
            setEpisodeId: "episode/setEpisodeId",
        }),

        quickMenu(page) {
            this.hideQuickMenu();
            this.$emit("openEditor", page)
        },

        hideQuickMenu() {
            this.show = false
        },
    },
}
</script>

<style scoped>
@font-face {
    font-family: Poppins;
    src: url("~@/assets/fonts/poppins/Poppins-Light.otf");
}
.f-poppins {
    font-family: Poppins, Arial;
    font-size: 1.5em;
}
</style>