<template>
    <b-container fluid>
        <episode-intervention-header :page="6" :intervention="intervention.intervention"></episode-intervention-header>
        <b-row>
            <b-col sm="9">
                <h4 style="color: red">Monitor the Following:</h4>
                <h5>
                    Monitoring Items for
                    {{ intervention.intervention }}
                </h5>
                <ul>
                    <li
                        id="evaluation-checkbox-group"
                        v-for="(item, index) in intervention.monitors"
                        :key="index"
                        name="monitorList"
                    >
                        {{ item }}
                    </li>
                </ul>
            </b-col>
            <b-col sm="3">
                <episode-intervention-flow-chart :intervention="intervention"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="goBack">&lt; BACK</app-button>

            </b-col>
            <b-col class="float-right">
                <app-button @click="resolveEscalation" is-danger>RESOLVE</app-button>

            </b-col>
        </b-row>
    </b-container>
</template>

<script>
//  import axios from "axios";
import EpisodeInterventionHeader from "@/components/Episode/EpisodeInterventionHeader";
import EpisodeInterventionFlowChart from "@/components/Episode/EpisodeInterventionFlowChart";
import {mapGetters} from 'vuex'
import AppButton from "@/components/AppButton";

export default {
    name: "EpisodeInterventionsEvaluation",
    components: {
        EpisodeInterventionHeader,
        EpisodeInterventionFlowChart,
        AppButton,
    },
    computed: {
        ...mapGetters({
            getIntervention: "episode/getIntervention",
            episode: "episode/getEpisode",
            currentIntervention: "episode/getCurrentIntervention",
        }),

        intervention: {
            get() {
                return this.currentIntervention;
            },
            set(data) {
                this.setIntervention(data);
            },
        },

        selectedMonitors: {
            get() {
                return this.$store.state.episode.intervention?.selected_monitors;
            },
            set(value) {
                // this.$store.commit("episode/updateSelectedMonitors", value);
                this.$store.dispatch('episode/updateSelectedMonitorsAndIntervention', value)
            },
        },


        disallowNext() {
            return true
        },

        disallowBack() {
            return false
        },

        nextText() {
            return this.$parent.communicate ? "COMMUNICATE" : "NEXT >";
        },
        nextVariant() {
            return this.$parent.communicate ? "outline-danger" : "outline-secondary";
        },
    },

    filters: {
        capitalize: function (value) {
            if (!value) return "";
            value = value.toString();
            return value.toUpperCase();
        },
    },

    methods: {
        goBack() {
            this.$parent.showPage = this.$parent.showPage - 2;
        },
        goForward() {

            //  where you goin' Willis?
        },
        resolveEscalation() {
            this.$bvModal.msgBoxConfirm('Are you sure that you want to resolve this escalation.', {
                title: 'Resolve Escalation',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Resolve',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if(value === true) {
                        //  mark the escalation as resolved.
                        this.$store.dispatch('episode/resolveEpisodeIntervention')
                        //  take the user to the escalations overview.
                        this.$parent.showPage = 1;
                    }
                })
                .catch(err => {
                    console.error(`An error occurred: ${err}`)
                })
        },
    },
};
</script>