<template>
    <div>
        <b-form-row class="mt-2 rowSize">
            <b-col class="sm-2">
                <b-form-group
                    id="basic-info-group-family-name"
                    label="Care Giver: "
                    label-for="basic-info-family-name"
                >
                    <b-input
                        id="basic-info-family-name"
                        v-model="family.name"
                        type="text"
                    ></b-input>
                </b-form-group>
            </b-col>
            <b-col class="sm-2">
                <b-form-group
                    id="basic-info-group-family-telephone"
                    label="Telephone:"
                    label-for="basic-info-family-telephone"
                >
                    <b-input
                        id="basic-info-family-telephone"
                        v-model="family.telephone"
                        placeholder="(000) 000-0000"
                        type="text"
                    ></b-input>
                </b-form-group>
            </b-col>
            <b-col class="sm-2">
                <b-form-group
                    id="basic-info-group-family-proxy"
                    label="Proxy: "
                    label-for="basic-info-family-proxy"
                >
                    <b-form-checkbox v-model="family.proxy" name="basic-info-group-family-prox" size="lg" switch></b-form-checkbox>
                </b-form-group>
            </b-col>

            <b-col class="sm-2">
                <b-form-group
                    id="basic-info-group-family-proxy-telephone"
                    label="Proxy Telephone: "
                    label-for="basic-info-family-proxy-telephone"
                >
                    <b-input
                        id="basic-info-family-proxy-telephone"
                        v-model="family.proxy_telephone"
                        placeholder="(000) 000-0000"
                        type="text"
                    ></b-input>
                </b-form-group>
            </b-col>

            <b-col class="sm-2">
                <b-form-group
                    id="basic-info-group-family-email"
                    label="Email: "
                    label-for="basic-info-family-email"
                >
                    <b-input
                        id="basic-info-family-email"
                        v-model="family.email"
                        type="text"
                    ></b-input>
                </b-form-group>
            </b-col>

            <b-col class="sm-2">
                <b-form-group id="basic-info-group-family-add" label="Action">
                    <app-button @click="validateFamilyMember" isNarrow>+</app-button>
                </b-form-group>
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import {mapGetters, mapMutations} from "vuex";
import * as helper from "@/helpers/helpers";
import {generateId} from "../../helpers/helpers";

export default {
    components: {
        "app-button": AppButton,
    },
    data: function () {
        return {
            family: {
                name: "",
                telephone: "",
                proxy: "",
                proxy_telephone: "",
                email: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            getEpisode: "episode/getEpisode",
        }),

        contactDetailsAreValid() {
            return (
                helper.isTelephoneNumber(this.family.telephone) ||
                helper.isEmailAddress(this.family.email)
            );
        },

        contactNameIsValid() {
            return this.family.name.length > 2;
        },

        contactIsValid() {
            return this.contactNameIsValid && this.contactDetailsAreValid;
        },
    },
    methods: {
        ...mapMutations({
            addFamilyMember: "episode/addFamilyMember",
        }),

        validateFamilyMember() {
            if (this.contactIsValid) {
                this.family.patient_id = this.getEpisode.patient.id;
                this.family.action = 'C'
                this.family.id = generateId()
                this.addFamilyMember(this.family);
                this.resetFamily();
            }
        },

        resetFamily() {
            this.family = {
                name: "",
                telephone: "",
                proxy: "",
                proxy_telephone: "",
                email: "",
            };
        },
    },
};
</script>
<style scoped>
.rowSize {
    font-size: 18px;
    margin: 4px 2px;
    padding: 12px 24px;
}
</style>
