<template>
  <div>
    <div v-if="isLoading">
      Loading...
      <b-spinner variant="info"></b-spinner>
    </div>
    <form v-if="!isLoading">
      <div>
        <div class="row residentForm-heading-button-row">
          <b-button
            variant="info"
            :class="{
              'residentForm-heading-button residentForm-heading-margin':
                activePage === meMedBeforeTabButtonNumber,
              'residentForm-heading-button-unselected residentForm-heading-margin':
                activePage !== meMedBeforeTabButtonNumber,
            }"
            @click="changePage(1)"
            >Medication Before Hospitalization</b-button
          >
          <b-button
            variant="info"
            :class="{
              'residentForm-heading-button residentForm-heading-margin':
                activePage === meMedAfterTabButtonNumber,
              'residentForm-heading-button-unselected residentForm-heading-margin':
                activePage !== meMedAfterTabButtonNumber,
            }"
            @click="changePage(2)"
            >Medication After Hospitalization</b-button
          >
          <b-button
            variant="info"
            :class="{
              'residentForm-heading-button residentForm-heading-margin':
                activePage === meMedCurrentTabButtonNumber,
              'residentForm-heading-button-unselected residentForm-heading-margin':
                activePage !== meMedCurrentTabButtonNumber,
            }"
            @click="changePage(3)"
            >Current Medication</b-button
          >
        </div>
      </div>
      <!--Modals-->
      <b-modal
        ref="modal-for-fields"
        title="Error"
        centered
        ok-only
        header-text-variant="danger"
      >
        <p class="my-4">Please ensure that the fields are not all empty.</p>
      </b-modal>
      <!--Modal for removal of row-->
      <b-modal id="confirmRemoveModal" centered hide-footer hide-header>
        <div class="d-block text-center">
          <h4 class="primaryTypeFace">
            Are you sure you want to delete this row?
          </h4>
        </div>
        <div class="d-block text-center">
          <b-button
            class="mt-3 residentTable-model-delete-btn"
            @click="removeListItem()"
            >DELETE</b-button
          >
          <b-button
            class="mt-3 residentTable-model-cancel-btn"
            block
            @click="hideModal()"
            >CANCEL</b-button
          >
        </div>
      </b-modal>
      <!------------------------------Part 1----------------------------->
      <div v-if="activePage === meMedBeforeTabButtonNumber">
        <b-form>
          <b-form-row>
            <b-col cols="10" class="medication-table-heading">
              <b-form-group>
                <tr>
                  <th width="40%">
                    Medication before patient was hospitalized
                  </th>
                </tr>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="medication-recon-add-col">
              <!-- <b-button @click="addShowFields" variant="primary" >Add Medication</b-button>  -->
              <app-button @click="addShowFields">Add Medication</app-button>
            </b-col>
          </b-form-row>
        </b-form>
        <!----------------Table for MEDICATION BEFORE HOSPITALIZATION-------------->
        <b-table
          striped
          :items="medicationsBeforeHospitalization"
          class="residentTable-style"
          :fields="recommended_medication_fields_before"
          thead-class="table-headers"
          tbody-class="table-body"
          :sort-desc.sync="sortDesc"
        >
          <template
            v-if="isInMedBeforeAddMode"
            slot="top-row"
            slot-scope="data"
          >
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.med_description"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.dosage_frequency"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.route"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-textarea
                v-model="medicationForm.clarification"
                class="residentTable-add-input"
                rows="1"
                max-rows="6"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-checkbox
                v-model="medicationForm.is_recommended"
              ></b-form-checkbox>
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-checkbox
                v-model="medicationForm.is_currently_taking"
              ></b-form-checkbox>
            </td>
            <td :span="data.columns">
              <b-btn
                class="residentTable-save-button"
                @click="addMedicationRecord()"
              >
                SAVE
              </b-btn>
            </td>
            <td :span="data.columns">
              <b-link @click="setFromAddToView()">
                <font-awesome-icon
                  class="residentTable-remove-link"
                  :icon="['fas', 'times']"
                />
              </b-link>
            </td>
          </template>
          <template
            v-for="field in editableMedAfterFields"
            v-slot:[`cell(${field.key})`]="{ value, item }"
          >
            <b-form-checkbox
              v-if="
                isEditMedicationBeforeShow &&
                  selectedMedBeforeRow &&
                  selectedMedBeforeRow.id === item.id &&
                  (field.key === 'is_recommended' ||
                    field.key === 'is_currently_taking')
              "
              :key="field.id"
              v-model="selectedMedBeforeRow[field.key]"
            ></b-form-checkbox>
            <b-form-textarea
              v-else-if="
                selectedMedBeforeRow &&
                  selectedMedBeforeRow.id === item.id &&
                  field.key === 'clarification' &&
                  isEditMedicationBeforeShow
              "
              :key="field.id"
              v-model="selectedMedBeforeRow[field.key]"
              class="residentTable-add-input"
              rows="1"
              max-rows="6"
            />
            <b-input
              v-else-if="
                selectedMedBeforeRow &&
                  selectedMedBeforeRow.id === item.id &&
                  isEditMedicationBeforeShow
              "
              :key="field.id"
              v-model="selectedMedBeforeRow[field.key]"
              :type="'text'"
            >
            </b-input>
            <template v-else>{{
              value.toString() === "true"
                ? "Yes"
                : value.toString() === "false"
                ? "No"
                : value.toString()
            }}</template>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <b-button-group
              v-if="
                selectedMedBeforeRow &&
                  selectedMedBeforeRow.id === item.id &&
                  isEditMedicationBeforeShow
              "
            >
              <b-btn
                class="residentTable-save-button"
                v-if="isEditMedicationBeforeShow"
                @click="saveEdit()"
              >
                SAVE
              </b-btn>
            </b-button-group>
            <b-btn
              class="residentTable-edit-button"
              v-if="!isEditMedicationBeforeShow"
              @click="showEditRecord(item)"
            >
              EDIT
            </b-btn>
          </template>
          <template v-slot:cell(remove)="{ item }">
            <b-button-group
              v-if="
                selectedMedBeforeRow &&
                  selectedMedBeforeRow.id === item.id &&
                  isEditMedicationBeforeShow
              "
            >
              <b-btn class="residentTable-save-button" @click="resetEdit">
                Cancel
              </b-btn>
            </b-button-group>
            <!--
                <b-link v-if="!isEditMedicationBeforeShow" :disabled="meNotAuthorised" @click="showRemoveModal(item)">
                  <font-awesome-icon class="residentTable-remove-link" :icon="['fas', 'times']"/>
                </b-link>
                -->
            <b-link
              v-if="!isEditMedicationBeforeShow"
              :disabled="!canEdit"
              @click="showRemoveModal(item)"
            >
              <font-awesome-icon
                class="residentTable-remove-link"
                :icon="['fas', 'times']"
              />
            </b-link>
          </template>
        </b-table>
      </div>

      <!--------------------------------Part 2----------------------->
      <div v-if="activePage === meMedAfterTabButtonNumber">
        <b-form>
          <b-form-row>
            <b-col cols="10" class="medication-table-heading">
              <b-form-group>
                <tr>
                  <th width="40%">Medication after patient was hospitalized</th>
                </tr>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="medication-recon-add-col">
              <!-- <b-button @click="addShowFields" variant="primary" >Add Medication</b-button>  -->
              <app-button @click="addShowFields">Add Medication</app-button>
            </b-col>
          </b-form-row>
        </b-form>
        <!----------------Table for MEDICATION AFTER HOSPITALIZATION-------------->
        <b-table
          striped
          :items="medicationsAfterHospitalization"
          class="residentTable-style"
          :fields="recommended_medication_fields_after"
          thead-class="table-headers"
          tbody-class="table-body"
          :sort-desc.sync="sortDesc"
        >
          <template v-if="isInMedAfterAddMode" slot="top-row" slot-scope="data">
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.med_description"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.dosage_frequency"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-input
                v-model="medicationForm.route"
                class="residentTable-add-input"
                type="text"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-textarea
                v-model="medicationForm.clarification"
                class="residentTable-add-input"
                rows="1"
                max-rows="6"
              />
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-checkbox
                v-model="medicationForm.is_recommended"
              ></b-form-checkbox>
            </td>
            <td class="residentTable-add-td" :span="data.columns">
              <b-form-checkbox
                v-model="medicationForm.is_currently_taking"
              ></b-form-checkbox>
            </td>
            <td :span="data.columns">
              <b-btn
                class="residentTable-save-button"
                @click="addMedicationRecord()"
              >
                SAVE
              </b-btn>
            </td>
            <td :span="data.columns">
              <b-link @click="setFromAddToView()">
                <font-awesome-icon
                  class="residentTable-remove-link"
                  :icon="['fas', 'times']"
                />
              </b-link>
            </td>
          </template>
          <template
            v-for="field in editableMedAfterFields"
            v-slot:[`cell(${field.key})`]="{ value, item }"
          >
            <b-form-checkbox
              v-if="
                isEditMedicationAfterShow &&
                  selectedMedAfterRow &&
                  selectedMedAfterRow.id === item.id &&
                  (field.key === 'is_recommended' ||
                    field.key === 'is_currently_taking')
              "
              :key="field.id"
              v-model="selectedMedAfterRow[field.key]"
            ></b-form-checkbox>
            <b-form-textarea
              v-else-if="
                selectedMedAfterRow &&
                  selectedMedAfterRow.id === item.id &&
                  field.key === 'clarification' &&
                  isEditMedicationAfterShow
              "
              :key="field.id"
              v-model="selectedMedAfterRow[field.key]"
              class="residentTable-add-input"
              rows="1"
              max-rows="6"
            />
            <b-input
              v-else-if="
                selectedMedAfterRow &&
                  selectedMedAfterRow.id === item.id &&
                  isEditMedicationAfterShow
              "
              :key="field.id"
              v-model="selectedMedAfterRow[field.key]"
              :type="'text'"
            >
            </b-input>
            <template v-else>{{
              value.toString() === "true"
                ? "Yes"
                : value.toString() === "false"
                ? "No"
                : value.toString()
            }}</template>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <b-button-group
              v-if="
                selectedMedAfterRow &&
                  selectedMedAfterRow.id === item.id &&
                  isEditMedicationAfterShow
              "
            >
              <b-btn
                class="residentTable-save-button"
                v-if="isEditMedicationAfterShow"
                @click="saveEdit()"
              >
                SAVE
              </b-btn>
            </b-button-group>
            <b-btn
              class="residentTable-edit-button"
              v-if="!isEditMedicationAfterShow"
              @click="showEditRecord(item)"
            >
              EDIT
            </b-btn>
          </template>
          <template v-slot:cell(remove)="{ item }">
            <b-button-group
              v-if="
                selectedMedAfterRow &&
                  selectedMedAfterRow.id === item.id &&
                  isEditMedicationAfterShow
              "
            >
              <b-btn class="residentTable-save-button" @click="resetEdit">
                Cancel
              </b-btn>
            </b-button-group>
            <b-link
              v-if="!isEditMedicationAfterShow"
              :disabled="!canEdit"
              @click="showRemoveModal(item)"
            >
              <font-awesome-icon
                class="residentTable-remove-link"
                :icon="['fas', 'times']"
              />
            </b-link>
          </template>
        </b-table>
      </div>
      <!---------------Part 3-------------------->
      <div v-if="activePage === meMedCurrentTabButtonNumber">
        <b-form>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <tr>
                  <th width="40%">Current medication being used</th>
                </tr>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
        <!---------------------------------Table for CURRENT MEDICATION USAGE---------------------------------->
        <b-table
          striped
          :items="currentMedications"
          class="residentTable-style"
          :fields="current_medication_fields"
          thead-class="table-headers"
          tbody-class="table-body"
          :sort-desc.sync="sortDesc"
        >
        </b-table>
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";
import {constantMixin, Roles} from '@/mixins/constants.js'
// import {globalMixin} from '@/mixins/globalMethods.js'
import  AppButton  from '@/components/AppButton.vue'
import { mapGetters, mapActions} from 'vuex'

export default {
  name: "MedicationRecon",
  components: {
    'app-button': AppButton,
  },
  computed: {

    ...mapGetters({
      getEpisode: 'episode/getEpisode',
      currentMedications: 'episode/medicationsCurrent',
      medicationsBeforeHospitalization: 'episode/medicationsBeforeHospitalization',
      medicationsAfterHospitalization: 'episode/medicationsAfterHospitalization',
    }),

    editableMedBeforeFields() {
        return this.recommended_medication_fields_before.filter((field) => field.editable);
      },
    editableMedAfterFields() {
         return this.recommended_medication_fields_after.filter((field) => field.editable);
      },
      role() {
        const roles = this.$store.getters["authentication/user"].roles
          .find(u => u.facility.id === this.getEpisode.facility_id);

        return roles.role.type;
      },
      canEdit() {

          return this.role === Roles.Administrator || Roles.SuperAdministrator || Roles.RegisteredNurseAdministrator || Roles.Doctor || Roles.Nurse;
      },
  },
  data: function() {
    return {
        medicationForm: {
          med_description: "",
          dosage_frequency: "",
          route: "",
          clarification: "",
          status: "none",
          is_recommended: false,
          is_currently_taking: false,
          is_disabled: 0,
          action: "C"
        },

        activePage: 1,
        meMedBeforeTabButtonNumber: 1,
        meMedAfterTabButtonNumber: 2,
        meMedCurrentTabButtonNumber: 3,
        meAuthorised: true,

        isEditMedicationBeforeShow: false,
        isEditMedicationAfterShow: false,
        isInMedBeforeAddMode: false,
        isInMedAfterAddMode: false,
        selectedMedBeforeRow: null,
        selectedMedAfterRow: null,
        sortDesc: true,


        med_description_before: '',
        dosage_frequency_before: '',
        route_before: '',
        clarification_before: '',
        is_currently_taking_before: false,
        is_recommended_before: false,

        //Table for BEFORE (part 1)
         recommended_medication_fields_before: [
          { key: "id", thClass: 'd-none', tdClass: 'd-none' },
          { key: "med_description", label:"Description", editable: true, thClass:'name-td'},
          { key: "dosage_frequency", label:"Dosage Frequency", editable: true, thClass:'name-td'},
          { key: "route", label:"Route", editable: true, thClass:'name-td'},
          { key: "clarification", label:"Clarification", editable: true, thClass:'specialty-td'},
          { key: "is_recommended", label:"Recommended", editable: true, thClass:'name-td'},
          { key: "is_currently_taking", label:"Currently Taking", editable: true, thClass:'name-td'},
          { key: "actions", label: "Edit", thClass:"residentTable-edit-th"},
          { key: "remove", label: "Remove", thClass:"residentTable-remove-th", tdClass:"residentTable-remove-td"}
        ],


        //part 2
        med_description_after: '',
        dosage_frequency_after: '',
        route_after: '',
        clarification_after: '',
        is_currently_taking_after: false,
        is_recommended_after: false,

        //Table for AFTER (part 2)
          recommended_medication_fields_after: [
          { key: "id", thClass: 'd-none', tdClass: 'd-none' },
          { key: "med_description", label:"Description", editable: true, thClass:'name-td'},
          { key: "dosage_frequency", label:"Dosage Frequency", editable: true, thClass:'name-td'},
          { key: "route", label:"Route", editable: true, thClass:'name-td'},
          { key: "clarification", label:"Clarification", editable: true, thClass:'specialty-td'},
          { key: "is_recommended", label:"Recommended", editable: true, thClass:'name-td'},
          { key: "is_currently_taking", label:"Currently Taking", editable: true, thClass:'name-td'},
          { key: "actions", label: "Edit", thClass:"residentTable-edit-th"},
          { key: "remove", label: "Remove", thClass:"residentTable-remove-th", tdClass:"residentTable-remove-td"}
         ],

         //part 3
         current_medication: [],

         //Table for CURRENT (part 3)
         current_medication_fields: [
          { key: "id", thClass: 'd-none', tdClass: 'd-none' },
          { key: "med_description", label:"Description", thClass:'name-td'},
          { key: "dosage_frequency", label:"Dose", thClass:'specialty-td'},
          { key: "route", label:"Route", thClass:'specialty-td'},
         ],
         isLoading: false,

    };
  },
  methods: {
    ...mapActions({
      addMedication: 'episode/addMedication',
      removeMedication: 'episode/removeMedication',
      updateMedication: 'episode/updateMedication',
    }),
    generateId() {

      return (new Date).getTime() + Math.random().toString(16).slice(2)
    },
    resetForm() {

      this.medicationForm = {
          med_description: "",
          dosage_frequency: "",
          route: "",
          clarification: "",
          status: "none",
          is_recommended: false,
          is_currently_taking: false,
          is_disabled: 0,
          action: "C"
        }
    },
    updateCurrentMedications() {
      this.current_medication = [];
      let medications = [];
        this.getEpisode.episode_hospital_meds_before.forEach((medication) => {
        if(medication.is_currently_taking) {

          medications.push({
            id: medication.id,
            med_description: medication.med_description,
            dosage_frequency: medication.dosage_frequency,
            route: medication.route,
          });
        }
      });

        this.getEpisode.episode_hospital_meds_after.forEach((medication) => {
        if(medication.is_currently_taking) {

          medications.push({
            id: medication.id,
            med_description: medication.med_description,
            dosage_frequency: medication.dosage_frequency,
            route: medication.route,
          });
        }
      });

      this.current_medication = medications;
    },
    async loadData(){
      try{
          this.isLoading = true;

          if(this.$parent.mode === constantMixin.residentEditMode){
            this.determineIfAuthorizedToEditAdd();
            this.getCurrentMedication();
          }
          await this.getAllInfoRequried();
          this.isLoading = false;
      }
        catch(error){
        this.isLoading = false; //prevents infinite loading
        }
    },
    determineIfAuthorizedToEditAdd(){
        if(this.$store.getters["authentication/user"].roles.find(u => u.role.name === constantMixin.SUPERADMIN && u.facility_id === this.getEpisode.facility_id) != undefined){
          this.meNotAuthorised = false;
        }
        else{
           this.meNotAuthorised = true;
        }
      },
    changePage(newPage) {
      if(newPage === 3) {
        // this.updateCurrentMedications();
      }
      this.activePage = newPage;
    },
    setStartPage() {
      this.activePage = 1;
    },
    addMedBeforeShowFields(){
      if(this.isInMedBeforeAddMode === false){
        this.isInMedBeforeAddMode = true;
      }

    },
    addShowFields(){
      if(this.isInMedBeforeAddMode === false && this.activePage === this.meMedBeforeTabButtonNumber){
        this.isInMedBeforeAddMode = true;
      }
      else if(this.isInMedAfterAddMode === false && this.activePage === this.meMedAfterTabButtonNumber){
        this.isInMedAfterAddMode = true;
      }

    },
    validateAddMedBeforeFields(){
      if(this.med_description_before != '' &&
      this.dosage_frequency_before != '' &&
      this.route_before != ''){
        return true;
      }
      else{
        return false;
      }
    },
    validateAddMedAfterFields(){
      if(this.med_description_after != '' &&
        this.dosage_frequency_after != '' &&
        this.route_after != ''){
        return true;
      }
      else{
        return false;
      }
    },
    validateEditMedBeforeFields(){
      if(this.selectedMedBeforeRow.med_description != '' &&
      this.selectedMedBeforeRow.dosage_frequency != '' &&
      this.selectedMedBeforeRow.route_ != ''){
        return true;
      }
      else{
        return false;
      }
    },
    validateAddMedication() {
      return this.med_description !== '' && this.dosage_frequency !== '' && this.route !== ''
    },
    validateEditMedAfterFields(){
       if(this.selectedMedAfterRow.med_description != '' &&
      this.selectedMedAfterRow.dosage_frequency != '' &&
      this.selectedMedAfterRow.route_ != ''){
        return true;
      }
      else{
        return false;
      }
    },
    async addMedicationRecord(){

      let medicationStatus = this.activePage === this.meMedBeforeTabButtonNumber ? 'before' : 'after'

      if(this.validateAddMedication()) {

        this.addMedication({

          id: this.generateId(),
          patient_id: this.getEpisode.patient_id,
          episode_id: this.getEpisode.id,
          med_description: this.medicationForm.med_description,
          dosage_frequency: this.medicationForm.dosage_frequency,
          route: this.medicationForm.route,
          clarification: this.medicationForm.clarification,
          status: medicationStatus,
          is_recommended: this.medicationForm.is_recommended,
          is_currently_taking: this.medicationForm.is_currently_taking,
          is_disabled: 0,
          action: 'C'
        })
        this.resetForm()
        this.setFromAddToView()
      } else {

        this.$refs['modal-for-fields'].show() 
      }
    },
    clearAddedValues(){
       if(this.activePage == this.meMedBeforeTabButtonNumber){
        this.med_description_before = '';
        this.dosage_frequency_before = '';
        this.route_before = '';
        this.clarification_before = '';
        this.is_recommended_before = false;
        this.is_currently_taking_before = false;
      }else if(this.activePage == this.meMedAfterTabButtonNumber){
        this.med_description_after = '';
        this.dosage_frequency_after = '';
        this.route_after = '';
        this.clarification_after = '';
        this.is_recommended_after = false;
        this.is_currently_taking_after = false;
      }


    },
    viewTableState(){
       if(this.activePage == this.meMedBeforeTabButtonNumber){
          this.isInMedBeforeAddMode = false;
        }
        else if(this.activePage == this.meMedAfterTabButtonNumber){
          this.isInMedAfterAddMode = false;
        }
      },

    setFromAddToView(){  //Clears the values of the top row

      this.viewTableState();
      this.clearAddedValues();
    },

    showEditRecord(paRecord) {
        let doEdit = true;
        if (
          this.selectedMedBeforeRow &&
          !confirm(
            "You have unsaved changes, are you sure you want to continue?"
          )
        ) {
          doEdit = false;
        }

        if (doEdit) {
          if(this.activePage == this.meMedBeforeTabButtonNumber){
            this.isEditMedicationBeforeShow = true;
            this.selectedMedBeforeRow = { ...paRecord };
          }else if(this.activePage == this.meMedAfterTabButtonNumber){
            this.isEditMedicationAfterShow = true;
            this.selectedMedAfterRow = { ...paRecord };
          }
        }
      },

    async saveEdit() {

      if(this.validateAddMedication()) {

        const medication = this.activePage === this.meMedBeforeTabButtonNumber ? this.selectedMedBeforeRow : this.selectedMedAfterRow
        
        this.updateMedication(medication)
        this.resetEdit()
      } else {

        this.$refs['modal-for-fields'].show() 
      }
    },

    showRemoveModal(paSelectedselectedRow){
      if(this.activePage == this.meMedBeforeTabButtonNumber){
        this.isEditMedicationBeforeShow = false;
        this.selectedMedBeforeRow = paSelectedselectedRow;
        this.$bvModal.show('confirmRemoveModal');
      }
      else if(this.activePage == this.meMedAfterTabButtonNumber){
        this.isEditMedicationAfterShow = false;
        this.selectedMedAfterRow = paSelectedselectedRow;
        this.$bvModal.show('confirmRemoveModal');
      }

    },

    async removeListItem() {

      const medication = this.activePage === this.meMedBeforeTabButtonNumber ? this.selectedMedBeforeRow : this.selectedMedAfterRow
      
      this.removeMedication(medication)

      this.hideModal()
    },

    hideModal() {
      if(this.activePage == this.meMedBeforeTabButtonNumber){
        this.selectedMedBeforeRow = null;
      }else if(this.activePage == this.meMedAfterTabButtonNumber){
        this.selectedMedAfterRow = null;
      }
      this.$bvModal.hide('confirmRemoveModal');
    },

    resetEdit() {
        if(this.activePage == this.meMedBeforeTabButtonNumber){
         this.selectedMedBeforeRow = null;
         this.isEditMedicationBeforeShow = false;
        }else if(this.activePage == this.meMedAfterTabButtonNumber){
         this.selectedMedAfterRow = null;
         this.isEditMedicationAfterShow = false;
        }

      },


    // getCurrentMedication(){
    //    axios
    //    .get("/clincal-info/show-episode-medications/" + this.getEpisode.id)
    //    .then(response => {
    //      this.current_medication = response.data.data;
    //      })
    // },


  },
  watch: {

  },
   mounted(){
    this.loadData();
    // this.getCurrentMedication();
   },

};
</script>

<style scoped>
.name-td {
  width: 20% !important;
}

.specialty-td {
  width: 20% !important;
}

.email-td {
  width: 20% !important;
}
.number-td {
  width: 20% !important;
}

.bp-td {
  width: 6%;
}

/* .residentTable-remove-link {
       color: black;
       height: 40px;
       width: 60px !important;
    } */

.residentTable-remove-link {
  height: 40px;
  width: 65px !important;
}

.medication-recon-save-button {
  border-radius: 4px;
  background-color: #ffffff;
  color: #3cb4e5;
  text-align: center;
  font-size: 16px;
  transition-duration: 0.4s;
  border: 2px solid #c5c3d1;
}

.medication-recon-add-col {
  text-align: end;
  margin-bottom: 10px;
  padding: 2px;
}

.medication-table-heading {
  margin-top: 10px;
}
</style>
