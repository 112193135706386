<template>
    <b-container>
        <b-form-row>
            <b-col>
                <b-form-group
                    id="users-email-group"
                    label="User's Login Email"
                    label-for="user-email"
                    label-cols-sm="4"
                >
                    <b-form-input
                        id="user-email"
                        type="email"
                        readonly
                        v-model="userEdit.login_email"
                        placeholder="User's Login Email"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group
                    id="user-name-group"
                    label="User's Name"
                    label-for="user-name"
                    label-cols-sm="4"
                >
                    <b-form-input
                        id="user-name"
                        type="text"
                        readonly
                        v-model="userEdit.name"
                        placeholder="User's Name"
                        :state="hasValidUserName"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>            
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    id="employee-number-group"
                    label="Employee Number"
                    label-for="employee-number"
                    label-cols-sm="4"
                >
                    <b-form-input
                        id="employee-number"
                        type="text"
                        v-model="userEdit.employee_number"
                        placeholder="Employee's Number"
                        :state="hasValidEmployeeNumber"
                    >

                    </b-form-input>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group
                    id="start-date-group"
                    label="Start Date"
                    label-for="start-date"
                    label-cols-sm="4"
                >

                    <b-form-datepicker
                        id="start-date"
                        v-model="userEdit.started_at" 
                        :state="hasValidStartDate"                   
                    >

                    </b-form-datepicker>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    id="staff-role-group"
                    label="Staff Role"
                    label-for="staff-role"
                    label-cols-sm="4"
                >
                    <b-form-select
                        v-model="userEdit.responsibility.id"
                        :options="responsibilities"
                        :state="hasValidStaffRole"
                    >

                    </b-form-select>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group
                    id="ended-at-group"
                    label="Ended At"
                    label-for="ended-at"
                    label-cols-sm="4"
                >

                    <b-form-datepicker
                        id="ended-at"
                        v-model="userEdit.ended_at"
                    >

                    </b-form-datepicker>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>

                <b-form-group
                    id="speciality-group"
                    label="Speciality"
                    label-for="speciality"
                    label-cols-sm="4"
                >
                    <b-form-input
                        id="speciality"
                        type="text"
                        v-model="userEdit.speciality"
                        placeholder="Speciality (Optional)"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col></b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    id="facility-email-group"
                    label="Facility Email"
                    label-for="facility-email"
                    label-cols-sm="4"
                >

                    <b-form-input
                        id="facility-email"
                        v-model="userEdit.work_email"
                        type="email"
                        placeholder="Facility Email"
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group
                    id="facility-number-group"
                    label="Facility Telephone"
                    label-for="facility-number"
                    label-cols-sm="4"
                >
                    <b-form-input
                        id="facility-number"
                        v-model="userEdit.work_number"
                        placeholder="Telephone Number"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>&nbsp;</b-col>
            <b-col>
                
                <b-button variant="primary" class="float-right" @click="cancelEdit">Cancel</b-button>
                <b-button variant="warning" class="mr-2 float-right" @click="updateUser">Update</b-button>
            </b-col>
        </b-form-row>
    </b-container>
</template>

<script>
import axios from 'axios';
export default {
    name: 'UserEditor',
    data() {
        return {
            responsibilities: [],
            userEdit: {},
        }
    },
    props: {
        user: {
            type: Object,
            required: true,
        },

        fetchUsersByFacility: {
            type: Function,
            required: true,
        },
    },
    created: function () {
        this.loadUser();
    },
    mounted: function () {
        this.loadRoles();
    },
    methods: {
        async loadRoles() {

            const responsibilities = await axios.get('responsibilities').catch((error) => console.log(error.message));

            responsibilities.data.data.forEach((responsibility) => {
                this.responsibilities.push({ value: responsibility.id, text: responsibility.designation })
            })
        },
        loadUser() {
            this.userEdit = Object.assign({}, this.user);
        },
        cancelEdit() {
            this.$bvModal.hide('userEditor');
        },
        async updateUser() {
            //  validate
            if(this.validateUser()) {
                // save the user
                //  we must construct a data object as the structures of the resource model are slightly different:
                const data = {
                    id: this.userEdit.id,
                    facility_id: this.userEdit.facility.id,
                    staff_id: this.userEdit.staff_id,
                    speciality: this.userEdit.speciality,
                    role_id: this.userEdit.responsibility.id,
                    employee_number: this.userEdit.employee_number,
                    started_at: this.userEdit.started_at,
                    ended_at: this.userEdit.ended_at,
                    work_email: this.userEdit.work_email,
                    work_number: this.userEdit.work_number,
                };

                //  write to the api, on confirmation, update the table.
                console.log("Fetching facility staff / " + this.userEdit.id);
                const response = await axios.put(`facility-staff/${this.userEdit.id}`, data)
                    .then((response) => {

                        return response.data;
                    })
                    .catch((error) => {
                        //  an error occured.
                        return {
                            message: error.message
                        }
                    });

                if(response.message === 'Facility Staff Member Updated.') {

                    await this.$bvModal.msgBoxOk("Staff member updated.", {
                        
                        centered:true,
                    }).then(() => {
                        
                        //  we update the table
                        this.fetchUsersByFacility();
                    });

                        this.$bvModal.hide('userEditor', { centered: true });
                } else {

                    await this.$bvModal.msgBoxOk(`An error occured: ${response.message}`, { centered: true });
                }


            } else {
                
                this.$bvModal.msgBoxOk("Please complete all required fields.", {
                    centered: true,
                });
            }
        },
        validateUser() {
            return this.hasValidUserName && this.hasValidEmployeeNumber && this.hasValidStartDate && this.hasValidStaffRole;
        },
    },
    computed: {
        hasValidUserName() {

            return this.userEdit.name.length >= 5;
        },
        hasValidEmployeeNumber() {
            return this.userEdit.employee_number.length >= 5
        },
        hasValidStartDate() {
            return this.userEdit.started_at != null;
        },
        hasValidStaffRole() {
            return this.userEdit.responsibility.id != null;
        },
    },
}
</script>