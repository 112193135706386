<template>
    <div>
        <ChangeInConditionSummary :assessment="assessment" @toggleShowDetail="showDetail" v-if="!showChangeInConditionDetail" />
        <ChangeInConditionDetail :assessment="assessment" @toggleShowDetail="showDetail" v-if="showChangeInConditionDetail" />
    </div>
</template>
<script>
import ChangeInConditionSummary from "@/components/Messaging/ChangeInConditionSummary.vue";
import ChangeInConditionDetail from "@/components/Messaging/ChangeInConditionDetail.vue";

export default {
    components: {ChangeInConditionDetail, ChangeInConditionSummary},
    props: {
        assessment: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showChangeInConditionDetail: false,
        }
    },
    methods: {
        showDetail() {
            this.showChangeInConditionDetail = !this.showChangeInConditionDetail;
        },
    },
}
</script>