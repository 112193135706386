<template>
    <div>
        <b-form-row>
            <!-- table of existing contacts -->
            <b-table
                :items="filteredFamily"
                :fields="familyFields"
                class="residentTable-style"
                thead-class="table-headers"
                tbody-class="table-body"
                v-if="episodeId != 0 && family.length > 0"
            >
                <template
                    v-for="field in editableFamilyFields"
                    v-slot:[`cell(${field.key})`]="{ value, item }"
                >
                    <b-input
                        v-if="
              selectedFamilyRow &&
                selectedFamilyRow.id === item.id &&
                isEditFamilyShow
            "
                        :key="field.id"
                        v-model="selectedFamilyRow[field.key]"
                        :type="field.type || 'text'"
                        :number="field.isNumber"
                    >
                    </b-input>
                    <template v-else>{{ value === "" ? '-' : value }}</template>
                </template>
                <template #cell(proxy)="data">
                    {{ (data.value === "1" || data.value === 1 || data.value === true) ? "YES" : "NO" }}
                </template>
                <template v-slot:cell(actions)="{ item }">
                    <b-button-group>
                        <app-button isNarrow @click="editFamilyInfo(item)">EDIT</app-button>
                    </b-button-group>
                </template>
                <template v-slot:cell(remove)="{ item }">
                    <b-button-group v-if="!isEditFamilyShow">
                        <app-button :disabled="isReadOnlyField"
                                    isNarrow
                                    isDanger @click="showFamilyContactRemoveModal(item)">
                            <font-awesome-icon :icon="['fas', 'times']"
                            />
                        </app-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-form-row>
        <b-modal
            ref="modal-for-name-number"
            centered
            title="Error"
            ok-only
            header-text-variant="danger"
        >
            <p class="my-4">
                Please ensure that a name and telephone number is provided.
            </p>
        </b-modal>

        <b-modal
            id="modal-edit-contact"
            centered
            title="Edit Contact"
            hide-footer
            header-text-variant="light"
            header-bg-variant="dark"
        >
            <b-form-row>
                <b-form-group
                    id="input-group-name"
                    label="Contact Name"
                    label-for="contact-name"
                    description="The name of the contact"
                >
                    <b-form-input
                        id="contact-name"
                        v-model="selectedFamilyItem.name"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <b-form-group
                    id="input-group-telephone"
                    label="Telephone Number"
                    label-for="telephone"
                    description="The telephone number of the contact"
                >
                    <b-form-input
                        id="telephone"
                        v-model="selectedFamilyItem.telephone"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <b-form-group
                    id="input-group-proxy"
                    label="Proxy"
                    label-for="proxy"
                    description="Is this contact a proxy for the patient?"
                >
                    <b-form-select v-model="selectedFamilyItem.proxy" :options="proxySelectOptions"></b-form-select>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <b-form-group
                    id="input-group-proxy-telephone"
                    label="Proxy Telephone"
                    label-for="proxy-telephone"
                    description="The telephone number of the proxy for the contact"
                >
                    <b-form-input
                        id="proxy-telephone"
                        v-model="selectedFamilyItem.proxy_telephone"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <b-form-group
                    id="input-group-email"
                    label="Email"
                    label-for="email"
                    description="The email address of the contact"
                >
                    <b-form-input
                        id="email"
                        v-model="selectedFamilyItem.email"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <app-button isNarrow @click="closeModalById('modal-edit-contact')">CLOSE</app-button>
            </b-form-row>
        </b-modal>

        <b-modal
            ref="modal-for-email"
            title="Error"
            centered
            ok-only
            header-text-variant="danger"
        >
            <p class="my-4">
                The email you have entered is invalid. Please ensure that you enter a
                correct email address.
            </p>
        </b-modal>
        <b-modal
            id="confirmFamilyContactRemoveModal"
            centered
            hide-footer
            hide-header
        >
            <div class="d-block text-center">
                <h4 class="primaryTypeFace">
                    Are you sure you want to remove this contact?
                </h4>
            </div>
            <div class="d-block text-center">
                <app-button isDanger @click="removeFamilyItem()">DELETE</app-button>
                <app-button @click="hideFamilyModal()">CANCEL</app-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppButton from "@/components/AppButton.vue";
import {constantMixin} from "@/mixins/constants.js";
import {mapActions} from 'vuex';

export default {
    components: {
        "app-button": AppButton,
    },
    props: {
        family: {
            type: Array,
            required: true,
        },
        episodeId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        editableFamilyFields() {
            return this.familyFields.filter((field) => field.editable);
        },
        isReadOnlyField() {
            if (
                this.$parent.mode != constantMixin.residentEditMode ||
                (this.$store.getters["authentication/user"].roles.find(
                        (u) =>
                            u.role.name === constantMixin.SUPERADMIN &&
                            u.facility_id === this.$parent.episode.facility_id
                    ) != undefined &&
                    this.$parent.mode === constantMixin.residentEditMode)
            ) {
                return false;
            } else {
                return true;
            }
        },
        filteredFamily() {
            return this.family?.filter(member => member.action !== 'D')
        },
    },
    data: function () {
        return {
            proxySelectOptions: [
                {value: false, text: "No"},
                {value: true, text: "Yes"},
            ],
            selectedFamilyItem: {
                name: null,
                telephone: null,
                proxy: null,
                proxyTelephone: null,
                email: null,
            },
            familyFields: [
                {key: "id", thClass: "d-none", tdClass: "d-none"},
                {key: "name", label: "Contact", editable: true, sortable: true},
                {
                    key: "telephone",
                    label: "Telephone",
                    editable: true,
                    type: "number",
                },
                {
                    key: "proxy",
                    label: "Proxy",
                    editable: true,
                    sortable: true,
                },
                {
                    key: "proxy_telephone",
                    editable: true,
                    label: "Proxy Number",
                    type: "number",
                },
                {key: "email", label: "Email", editable: true},
                {key: "actions", label: "Edit", thClass: "residentTable-edit-th"},
                {
                    key: "remove",
                    label: "Remove",
                    thClass: "residentTable-remove-th",
                    tdClass: "residentTable-remove-td",
                },
            ],
            isEditFamilyShow: false,
            selectedFamilyRow: null,
        };
    },
    methods: {

        ...mapActions({
            deleteFamilyItem: 'episode/deleteFamilyItem',
            addFamilyMember: "episode/addFamilyMember",
            updateFamilyMember: "episode/updateFamilyMember",
        }),

        closeModalById(id) {

            this.updateFamilyMember(this.selectedFamilyItem)

            this.$bvModal.hide(id)
        },

        editFamilyInfo(item) {

            this.selectedFamilyItem = {...item}

            this.$bvModal.show("modal-edit-contact")
        },

        showEmailRequiredModal() {
            this.$refs["modal-for-email"].show();
        },

        hideFamilyModal() {
            this.selectedFamilyRow = null;
            this.$bvModal.hide("confirmFamilyContactRemoveModal");
        },

        showFamilyContactNameRequiredModal() {
            this.$refs["modal-for-name-number"].show();
        },

        showFamilyContactRemoveModal(paSelectedselectedRow) {
            this.isEditShow = false;
            this.selectedFamilyRow = paSelectedselectedRow;
            this.$bvModal.show("confirmFamilyContactRemoveModal");
        },

        removeFamilyItem() {

            this.deleteFamilyItem(this.selectedFamilyRow.id)
            this.$bvModal.hide("confirmFamilyContactRemoveModal");

        },
    },
};
</script>
