<template>
    <div>
        <b-form-row v-if="isLoading">
            <b-col class="text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-col>
        </b-form-row>
        <b-form v-if="!isLoading">
            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-organization-name-group"
                        label="Organization Name"
                        label-for="facility-organization-name"
                        description="The name of the organization that runs the facility."
                        label-cols
                        content-cols
                    >
                        <b-form-input
                            id="facility-organization-name"
                            type="text"
                            v-model="form.organization_name"
                            required
                            placeholder="Organization Name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-name-group"
                        label="Facility"
                        label-for="facility-name"
                        description="The name of the facility."
                        label-cols
                        content-cols
                    >
                        <b-form-input
                            id="facility-name"
                            type="text"
                            v-model="form.name"
                            required
                            placeholder="Facility Name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-beds-group"
                        label="Facility Beds"
                        label-for="facility-bed"
                        label-cols
                        content-cols
                        description="The number of beds at the facility."
                    >
                        <b-form-input
                            id="facility-beds"
                            type="number"
                            v-model="form.beds"
                            required
                            placeholder="Facility Beds"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-timezone-group"
                        label="Facility Timzone"
                        label-for="facility-timezone"
                        label-cols
                        content-cols
                        description="The timezone of the facility."
                    >
                        <b-form-input
                            id="facility-timezone"
                            type="text"
                            v-model="form.timezone"
                            required
                            placeholder="Timezone"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-standing-orders-group"
                        label-cols
                        content-cols
                        description="Does this facility have standing orders?"
                    >
                        <b-form-checkbox
                            id="facility-standard-orders"
                            v-model="form.standing_orders"
                            name="standing-orders"
                            value="1"
                            unchecked-value="0"
                        >Facility has standing orders.
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-pcc-group"
                        label-cols
                        content-cols
                        description="Is this a PCC facility?."
                    >

                        <b-form-checkbox
                            id="facility-pcc"
                            v-model="form.is_pcc_facility"
                            name="is_pcc_facility"
                            value="1"
                            unchecked-value="0"
                        >Integrate with PCC
                        </b-form-checkbox>

                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row v-if="form.is_pcc_facility == 1">
                <b-col>
                    <b-form-group
                        id="facility-organization-uuid-group"
                        label="Organization UUID"
                        label-for="facility-organization-uuid"
                        label-cols
                        content-cols
                        description="The PCC Facility Organization UUID."
                    >
                        <b-form-input
                            id="facility-organization-uuid"
                            type="text"
                            v-model="form.organization_uuid"
                            required
                            placeholder="Organization UUID"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row v-if="form.is_pcc_facility == 1">
                <b-col>
                    <b-form-group
                        id="facility-pcc-facility-id-group"
                        label="PCC Facility ID"
                        label-for="facility-pc-facility-id"
                        label-cols
                        content-cols
                        description="The PCC Facility ID."
                    >
                        <b-form-input
                            id="facility-pcc-facility-id"
                            type="text"
                            v-model="form.pcc_facility_id"
                            required
                            placeholder="PCC Facility ID"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col>
                    <b-form-group
                        id="facility-status-group"
                        label-cols
                        content-cols
                        description="Is this Facility Active?"
                    >
                        <b-form-checkbox
                            id="facility-status"
                            v-model="form.status"
                            name="status"
                            value="1"
                            unchecked-value="0"
                        >Facility is enabled?
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <app-button class="ml-2 float-right" is-selected @click="saveFacility">Save</app-button>
                    <app-button variant="warning" class="float-right" @click="closeModal">Cancel</app-button>
                    <app-button variant="danger" class="float-left" is-danger @click="destroyFacility">Delete
                    </app-button>
                </b-col>
            </b-form-row>
            <b-form-row class="mt-2">
                <b-col>
                    <span style="color: red">{{ validationMessage }}</span>
                </b-col>
            </b-form-row>
        </b-form>
    </div>
</template>
<script>
import axios from 'axios';
import AppButton from "./AppButton.vue";

export default {
    components: {AppButton},
    props: {
        facility: Object,
    },
    data: function () {
        return {
            form: {
                name: null,
                standing_orders: 1,
                status: 1,
                beds: null,
                is_pcc_facility: 0,
                organization_uuid: null,
                pcc_facility_id: null,
                timezone: null,
                organization_name: null,
            },
            validationMessage: "",
            facilityPath: "facilities",
            isLoading: false,
        }
    },
    mounted: function () {
        this.loadFacility();
    },
    methods: {
        async destroyFacility() {
            this.$bvModal.msgBoxConfirm("Are you sure you want to delete this facility. Once you delete the facility, it cannot be recovered.",
                {
                    title: 'Warning: Deleting a Facility',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Confirm',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            ).then(async value => {
                if (value) {

                    this.isLoading = true;

                    let success = await this.apiDestroyFacility();

                    if(success) {
                        this.$bvModal.msgBoxOk("Facility Deleted.", {
                            title: 'Facility Deleted',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerBgVariant: 'danger',
                            headerClass: 'p-2 border-bottom-0 text-white',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        }).then(value => {

                                if (value) {
                                    this.isLoading = false;
                                    this.closeModal()
                                }
                            }
                        )
                    } else {

                        this.$bvModal.msgBoxOk("Could Not Delete Facility. An error occured.", {
                            title: 'Facility Not Deleted',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerBgVariant: 'danger',
                            headerClass: 'p-2 border-bottom-0 text-white',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        }).then(value => {
                            console.error("Error: ", value);
                            this.isLoading = false;
                        });
                    }
                }
            });
        },
        loadFacility() {
            this.form.name = this.facility.facility;
            this.form.status = this.facility.active === 'Yes' ? 1 : 0;
            this.form.standing_orders = this.facility.standing_orders === 'Yes' ? 1 : 0;
            this.form.beds = this.facility.beds;
            this.form.is_pcc_facility = this.facility.is_pcc_facility === 'Yes' ? 1 : 0;
            this.form.organization_uuid = this.facility.organization_uuid;
            this.form.pcc_facility_id = this.facility.pcc_facility_id;
            this.form.timezone = this.facility.timezone;
            this.form.organization_name = this.facility.organization_name;
        },
        closeModal() {

            this.$bvModal.hide('facility-editor');
        },
        async apiDestroyFacility() {

            return await axios.delete(`${this.facilityPath}/${this.facility.id}`)
                .then((response) => {
                    if (response.status === 204) {
                        return true;
                    }

                    console.error("An error occurred,", response.data);
                })
                .catch(error => {
                    console.error(error)
                    return false;
                });
        },
        async saveFacility() {
            if (this.isValid()) {

                let facility = {}

                facility = {
                    organization_name: this.form.organization_name,
                    name: this.form.name,
                    beds: this.form.beds,
                    timezone: this.form.timezone,
                    status: this.form.status,
                    standing_orders: this.form.standing_orders,
                }

                //  clean up the org-uuid and pcc_id
                if (this.form.is_pcc_facility == 1) {
                    //  the facility is a PCC facility.
                    facility.is_pcc_facility = this.form.is_pcc_facility
                    facility.organization_uuid = this.form.organization_uuid
                    facility.pcc_facility_id = this.form.pcc_facility_id

                }

                if (this.facility.mode === 'add') {

                    await axios.post(`${this.facilityPath}`, facility)
                        .then((response) => {

                            //  show a dialog stating that the facility has been created.
                            this.$bvModal.msgBoxOk(`Facility ${response.data.facility.name} has been created.`, {
                                title: 'Facility Created',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                            })
                                .then(value => {
                                    if (value) {
                                        this.$root.$emit('refreshFacilityBroadcast')
                                    }

                                })
                        }).then(this.$bvModal.hide('facility-editor'))
                        .catch((error) => {

                            alert(error.response.data.message + ' ' + error.response.data.errors);
                        });
                } else {
                    await axios.put(`${this.facilityPath}/${this.facility.id}`, facility).then(this.$bvModal.hide('facility-editor')).catch((error) => {
                        alert(error.response.data.message + ' ' + error.response.data.errors);
                    });
                }
            }
        },
        isValid() {

            if (this.hasFacilityName() && this.hasBedValue() && this.hasTimeZone() && this.hasOrganizationName()) {
                return true;
            }
            return false;
        },
        hasOrganizationName() {
            if (this.form.organization_name !== null) {

                return true;
            }

            this.validationMessage = "Please enter an organization name.";
            return false;
        },
        hasTimeZone() {
            if (this.form.timezone !== null) {

                return true;
            }

            this.validationMessage = "Please enter a timezone.";
            return false;
        },
        hasFacilityName() {
            if (this.form.name !== null) {
                return true;
            }

            this.validationMessage = "Please enter a facility name.";
            return false;
        },
        hasBedValue() {

            let bedValue = +this.form.beds;
            if (Number.isInteger(bedValue) && bedValue > 0) {

                return true;
            }

            this.validationMessage = "The number of beds entered is not a whole number or is 0.";
            return false;
        },
    },
}
</script>