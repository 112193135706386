<template>
  <div class="text-center">
    <b-spinner
      :label="label"
      :variant="variant"
      :small="isSmall"
    ></b-spinner>
  </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: "Loading..."
        },
        variant: {
            type: String,
            default: "info"
        },
        isSmall: {
            type: Boolean,
            default: false
        },
    },
}
</script>