<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <span style="color: black; font-weight: bold; text-align: center">
          FOR
          {{ $parent.currentIntervention.intervention | capitalize }}</span
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-center">
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                $parent.showPage === 2,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                $parent.showPage > 2,
            }"
          >
            STEP 1: SYMPTOMS
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                $parent.showPage === 3,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                $parent.showPage !== 3,
            }"
          >
            STEP 2: VITALS
          </p>
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                $parent.showPage === 4,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                $parent.showPage !== 4,
            }"
          >
            STEP 3: EVALUATE
          </p>
          <!--
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                $parent.showPage === 5,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                $parent.showPage !== 5,
            }"
          >
            STEP 4: TESTS
          </p>
          -->
          <p
            :class="{
              'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
                $parent.showPage === 6,
              'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
                $parent.showPage !== 6,
            }"
          >
            STEP 5: MONITOR
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="9">
        <h4 style="color: red">Please select all that are applicable.</h4>
        <h5>
          Select Tests for
          {{ $parent.currentIntervention.intervention }}
        </h5>
        <b-form>
          <b-form-group
            ><b-form-checkbox-group
              id="tests-checkbox-group"
              v-model="$parent.currentIntervention.selected_tests"
              :options="$parent.currentIntervention.tests"
              name="interventionTests"
            ></b-form-checkbox-group
          ></b-form-group>
        </b-form>
      </b-col>
      <b-col sm="3">
        <intervention-flow-chart
          :intervention="$parent.currentIntervention"
        ></intervention-flow-chart>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        ><b-button
          :disabled="disallowBack"
          variant="outline-secondary"
          @click="goBack"
          >&lt; BACK</b-button
        ></b-col
      >
      <b-col
        ><b-button
          class="float-right"
          :disabled="disallowNext"
          :variant="nextVariant"
          @click="goForward"
          >{{ nextText }}</b-button
        ></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
//  import axios from "axios";
export default {
  name: "InterventionsTests",
  computed: {
    disallowNext() {
      return this.$parent.showPage == 6;
    },
    disallowBack() {
      return this.$parent.showPage == 2;
    },
    nextText() {
      return this.$parent.communicate ? "COMMUNICATE" : "NEXT >";
    },
    nextVariant() {
      return this.$parent.communicate ? "outline-danger" : "outline-secondary";
    },
  },
  data() {
    return {};
  },
  mounted() {},
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },
  methods: {
    goBack() {
      this.$parent.showPage = this.$parent.showPage - 1;
    },
    goForward() {
      this.$parent.showPage = this.$parent.showPage + 1;
    },
  },
};
</script>