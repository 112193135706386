<template>
  <div>
    <div>
      <form>
        <b-form>
          <p class="subheading">Physical and Sensory Functions</p>
          <b-form-row>
            <b-col cols="4">
              <b-form-group>
                <p style="">Ambulatory:</p>
                <b-form-select
                  v-model="$parent.episode.care_directives[0].physical.ambulatory"
                  :options="ambulatory_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <p style="">Weight Bearing:</p>
                <b-form-select
                  v-model="$parent.episode.care_directives[0].physical.weight_bearing"
                  :options="weight_bearing_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <p style="">Transfer:</p>
                <b-form-select
                  v-model="$parent.episode.care_directives[0].physical.transfer"
                  :options="transfer_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <p class="subheading">Sensory Functions</p>
          <b-form-row>
            <b-col cols="5">
              <b-form-group
                class=""
                label="Sight: "
                label-cols="2"
                label-for="care-directives-sight-status"
              >
                <b-form-select
                  id="care-directives-sight-status"
                  v-model="$parent.episode.care_directives[0].sensorial.sight"
                  :options="sight_options"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="5" offset="1">
              <b-form-group
                class=""
                label="Hearing: "
                label-cols="2"
                label-for="care-directives-sight-status"
              >
                <b-form-select
                  id="care-directives-hearing-status"
                  v-model="$parent.episode.care_directives[0].sensorial.hearing"
                  :options="hearing_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />

        <div class="flex-container">        
          <div style="min-width: 250px">  
          <p class="subheading">Devices</p>
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Devices:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.episode.care_directives[0].devices"
                  :options="devices_options"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>
          <div style="min-width: 250px">
          <p class="subheading">Continence</p>
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Continence:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.episode.care_directives[0].continence"
                  :options="continence_options"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>

          <div>  
          <p class="subheading">Catheter</p>
          <b-form-row>
            <b-col>
              <b-form-group
                class=""
                label="Date Inserted: "
                label-cols="4"
                label-for="care-directives-date-inserted"
              >
                <b-form-input
                  id="care-directives-date-inserted"
                  v-model="$parent.episode.care_directives[0].catheter.catheter_inserted_date"
                  type="date"
                  placeholder="..."
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-form-group>
              <p class="">Retention:</p>
            </b-form-group>
            <b-col cols="1">
              <b-form-group>
                <b-form-checkbox
                  class="checkbox"
                  id="care-directives-catheter-retention"
                  v-model="$parent.episode.care_directives[0].catheter.have_retention"
                  name="cbxRetention"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-form-group>
              <p class="">Skin Protection:</p>
            </b-form-group>
            <b-col cols="1">
              <b-form-group>
                <b-form-checkbox
                  class="checkbox"
                  id="care-directives-catheter-skinProtection"
                  v-model="$parent.episode.care_directives[0].catheter.have_skin_protection"
                  name="cbxSkinProtection"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-form-group>
              <p class="">Other:</p>
            </b-form-group>
            <b-col cols="1">
              <b-form-group>
                <b-form-checkbox
                  class="checkbox"
                  id="care-directives-catheter-other"
                  v-model="$parent.episode.care_directives[0].catheter.have_other"
                  name="cbxOther"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-input
                v-if="$parent.episode.care_directives[0].catheter.have_other == 1"
                v-model="$parent.episode.care_directives[0].catheter.have_other_description"
                placeholder="Please specify..."
              ></b-form-input>
            </b-col>
          </b-form-row>
          </div>

        </div>
        <hr />
          
          <div style="max-width: 40%">
          <p class="subheading">Bowel Incontinent</p>
          <b-form-row>
            <b-col>
              <b-form-group
                class=""
                label="Date of last Bowel Movement: "
                label-cols="6"
                label-for="care-directives-last-bowel-movement"
              >
                <b-form-input
                  id="care-directives-last-bowel-movement"
                  v-model="$parent.episode.care_directives[0].catheter.bowel_movement_datetime"
                  type="date"
                  placeholder="..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>
          <hr />
          
          <div>
          <p class="subheading">Nutrition and Hydration</p>
          <b-form-row>
            <b-col>
              <b-form-group
                class=""
                label="Diet:"
                label-cols="2"
                label-for="care-directives-diet"
              >
                <b-form-input
                  id="care-directives-diet"
                  v-model="$parent.episode.care_directives[0].nutrition.diet"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                class=""
                label="Consistency:"
                label-cols="2"
                label-for="care-directives-consistency"
              >
                <b-form-input
                  id="care-directives-consistency"
                  v-model="$parent.episode.care_directives[0].nutrition.consistency"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                class=""
                label="Free Water Restriction:"
                label-cols="2"
                label-for="care-directives-FWR"
              >
                <b-form-input
                  id="care-directives-FWR"
                  v-model="$parent.episode.care_directives[0].nutrition.free_water_restriction"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>


        <div class="flex-container">
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Eating Instructions:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.episode.care_directives[0].nutrition.eating_instructions"
                  :options="eatingInstructionsOptions"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Speech Therapy Recommendations:</p>
                <b-form-textarea
                  id="textarea"
                  v-model="$parent.episode.care_directives[0].nutrition.speech_therapy_recommendation_description"
                  placeholder="Enter speech therapy recommendations..."
                  rows="3"
                  max-rows="8"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>

          <b-form-row>
            <p class="bold-label">Tube Feeding</p>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <b-form-group
                class=""
                label="Type: "
                label-cols="3"
                label-for="care-directives-tube-type"
              >
                <b-form-select
                  id="care-directives-tube-type"
                  v-model="$parent.episode.care_directives[0].tube_feeding.tube_type"
                  :options="tubeOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-input
                v-if="$parent.episode.care_directives[0].tube_feeding.tube_type == 1 || $parent.episode.care_directives[0].tube_feeding.tube_type == 2"
                id="care-directives-tube-type"
                v-model="$parent.episode.care_directives[0].tube_feeding.tube_insertion_date"
                type="date"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="3">
              <b-form-group
                class=""
                label="Free Water Bolus:"
                label-cols="6"
                label-for="care-directives-FWB"
              >
                <b-form-input
                  id="care-directives-FWB"
                  v-model="$parent.episode.care_directives[0].tube_feeding.free_water_bolus_cc"
                  type="number"
                  min=0
                  placeholder="cc"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                class=""
                label="cc"
                label-cols="1"
                label-for="care-directives-cc"
              >
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                class=""
                label="Every:"
                label-cols="2"
                label-for="care-directives-every"
              >
                <b-form-input
                  id="care-directives-every"
                  v-model="$parent.episode.care_directives[0].tube_feeding.free_water_bolus_every_many_hours"
                  type="number"
                  min=0
                  placeholder="hours"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                class=""
                label="hours"
                label-cols="1"
                label-for="care-directives-hr"
              >
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
          
            <b-form-group>
              <p class="">Tube Feed Product:</p>
            </b-form-group>
            <b-col cols="1">
              <b-form-group>
                <b-form-checkbox
                  class="checkbox"
                  id="care-directives-tube-product"
                  v-model="$parent.tubeFeedProductStatus"
                  name="cbxTubeFeedProduct"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          
          </b-form-row>

        <div class="flex-container">
          <b-form-row>
            <b-col>
              <b-form-group
                v-if="$parent.tubeFeedProductStatus == 1"
                class=""
                label="Product Name:"
                label-cols="4"
                label-for="care-directives-product-name"
              >
                <b-form-input
                  v-if="$parent.tubeFeedProductStatus == 1"
                  id="care-directives-product-name"
                  v-model="$parent.episode.care_directives[0].tube_feeding.product.feed_product_name"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                v-if="$parent.tubeFeedProductStatus == 1"
                class=""
                label="Rate:"
                label-cols="3"
                label-for="care-directives-rate"
              >
                <b-form-input
                  v-if="$parent.tubeFeedProductStatus == 1"
                  id="care-directives-rate"
                  v-model="$parent.episode.care_directives[0].tube_feeding.product.feed_product_rate"
                  type="number"
                  min=0
                  placeholder="cc"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-if="$parent.tubeFeedProductStatus == 1"
                class=""
                label="rate per hour"
                label-cols="8"
                label-for="care-directives-rate-per-hour"
              >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                v-if="$parent.tubeFeedProductStatus == 1"
                class=""
                label="Duration:"
                label-cols="5"
                label-for="care-directives-duration"
              >
                <b-form-input
                  id="care-directives-duration"
                  v-model="$parent.episode.care_directives[0].tube_feeding.product.feed_product_duration"
                  type="number"
                  min=0
                  placeholder="hours"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-if="$parent.tubeFeedProductStatus == 1"
                class=""
                label="hours per day"
                label-cols="8"
                label-for="care-directives-hour-per-day"
              >
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
        <hr />
        
        <p class="subheading">Treatments and Therapeutic Devices</p>
        <div class="flex-container">
          <div>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p class="bold-label">Treatments and Devices:</p>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <p class="">PICC:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-picc-cbx"
                    v-model="$parent.episode.care_directives[0].cardiac_treatments.picc"
                    name="cbxPICCDate"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <b-col cols="4">
              <b-form-input
                id="care-directives-picc-date"
                v-if="$parent.episode.care_directives[0].cardiac_treatments.picc == 1"
                v-model="$parent.episode.care_directives[0].cardiac_treatments.picc_date_inserted"
                type="date"
                placeholder="..."
              ></b-form-input>
            </b-col>

            <p class="">Portacath:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-portacath-cbx"
                    v-model="$parent.episode.care_directives[0].cardiac_treatments.portacath"
                    name="cbxPortacathDate"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <b-col cols="4">
              <b-form-input
                id="care-directives-portacath-date"
                v-if="$parent.episode.care_directives[0].cardiac_treatments.portacath == 1"
                v-model="$parent.episode.care_directives[0].cardiac_treatments.portacath_date_inserted"
                type="date"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>

              <b-form-row>
              <b-col cols="11">
            <b-form-group
                class=""
                v-if="$parent.cardiac_types_selected == 3"
                label="Cardiac device description:"
                label-cols="4"
                label-for="care-directives-other-selected"
              >
               <b-form-input
                v-if="$parent.cardiac_types_selected == 3"
                id="care-directives-other-selected"
                v-model="$parent.episode.care_directives[0].cardiac_treatments.cardiac_other_description"
                type="text"
                placeholder="Please specify..."
              ></b-form-input>
                </b-form-group>
              </b-col>
              </b-form-row>
          </div>
          
          <div>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p class="">Cardiac:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.cardiac_types_selected"
                  :options="cardiacDevicesOptions"
                  multiple
                ></b-form-select>
              </b-form-group>
            
              
            </b-col>
          </b-form-row>
          </div>
        </div>
        <hr />

        <p class="subheading">Respiratory</p>
        <div class="flex-container">
          <div style="max-width: 40%">
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Respiratory Items:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.respiratory_items_selected"
                  :options="respiratoryItemsOptions"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="3">
              <b-form-group
                class=""
                label="O2:"
                label-cols="3"
                label-for="care-directives-O2"
              >
                <b-form-input
                  id="care-directives-O2"
                  v-model="$parent.episode.care_directives[0].respiratory.o2_liters"
                  type="number"
                  min=0
                  placeholder="litres"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxLength="2"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="1">
              <b-form-group
                class=""
                label="litres"
                label-cols="2"
                label-for="care-directives-litres"
              >
              </b-form-group>
            </b-col>

            <b-col cols="4" offset="1">
              <b-form-group
                class=""
                label="Trach Size:"
                label-cols="6"
                label-for="care-directives-trach-size"
              >
                <b-form-input
                  id="care-directives-trach-size"
                  v-model="$parent.episode.care_directives[0].respiratory.trach_size_mm"
                  type="number"
                  min=0
                  placeholder="mm"
                  maxLength="2"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <b-form-group
                class=""
                label="mm"
                label-cols="2"
                label-for="care-directives-trach-size"
              >
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>
          
          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Therapies:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.therapy_selections"
                  :options="therapiesListOptions"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group>
                <p class="">Therapy Assessment/Recommendations:</p>
                <b-form-textarea
                  id="textarea"
                  v-model="$parent.episode.care_directives[0].therapies.therapy_recommendation"
                  placeholder="Enter therapy recommendations..."
                  rows="3"
                  max-rows="8"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
        <hr />

          <p class="subheading">Skin Care</p>
          <b-form-row style="padding-left: 10%">
            <p>No Skin Breakdown:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-skin-breakdown-cbx"
                    v-model="$parent.episode.care_directives[0].skin_care.has_skin_breakdown"
                    name="cbx-skin-breakdown"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <p>Pressure Ulcer:</p>
            <b-form-group>
              <b-col cols="2">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-first-ulcer"
                    v-model="$parent.episode.care_directives[0].skin_care.has_first_pressure_ulcer"
                    name="cbx-first-ulcer"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <p>2nd Pressure Ulcer:</p>
            <b-form-group>
              <b-col cols="2">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-second-ulcer"
                    v-model="$parent.episode.care_directives[0].skin_care.has_second_pressure_ulcer"
                    name="cbx-second-ulcer"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <p>Other Wounds:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-other-wounds-cbx"
                    v-model="$parent.episode.care_directives[0].skin_care.has_other_wounds"
                    name="cbx-other-wounds"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
          </b-form-row>

        <div class="flex-container">
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                v-if="$parent.episode.care_directives[0].skin_care.has_first_pressure_ulcer == 1"
                class=""
                label="1st Ulcer Stage:"
                label-cols="4"
                label-for="care-directives-first-ulcer-stage"
              >
                <b-form-input
                  v-if="$parent.episode.care_directives[0].skin_care.has_first_pressure_ulcer == 1"
                  id="care-directives-first-ulcer-stage"
                  v-model="$parent.episode.care_directives[0].skin_care.first_pressure_ulcer_stage"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                v-if="$parent.episode.care_directives[0].skin_care.has_first_pressure_ulcer == 1"
                class=""
                label="1st Ulcer Location:"
                label-cols="4"
                label-for="care-directives-first-ulcer-location"
              >
                <b-form-input
                  v-if="$parent.episode.care_directives[0].skin_care.has_first_pressure_ulcer == 1"
                  id="care-directives-first-ulcer-location"
                  v-model="$parent.episode.care_directives[0].skin_care.first_pressure_ulcer_location"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>

        <div class="flex-container">
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                v-if="$parent.episode.care_directives[0].skin_care.has_second_pressure_ulcer == 1"
                class=""
                label="2nd Ulcer Stage:"
                label-cols="4"
                label-for="care-directives-second-ulcer-stage"
              >
                <b-form-input
                  v-if="$parent.episode.care_directives[0].skin_care.has_second_pressure_ulcer == 1"
                  id="care-directives-second-ulcer-stage"
                  v-model="$parent.episode.care_directives[0].skin_care.second_pressure_ulcer_stage"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                v-if="$parent.episode.care_directives[0].skin_care.has_second_pressure_ulcer == 1"
                class=""
                label="2nd Ulcer Location:"
                label-cols="4"
                label-for="care-directives-second-ulcer-location"
              >
                <b-form-input
                  v-if="$parent.episode.care_directives[0].skin_care.has_second_pressure_ulcer == 1"
                  id="care-directives-second-ulcer-location"
                  v-model="$parent.episode.care_directives[0].skin_care.second_pressure_ulcer_location"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
          
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                v-if="$parent.episode.care_directives[0].skin_care.has_other_wounds == 1"
                class=""
                label="Other Wounds:"
                label-cols="2"
                label-for="care-directives-other-wounds"
              >
                <b-form-input
                  v-if="$parent.episode.care_directives[0].skin_care.has_other_wounds == 1"
                  id="care-directives-first-other-wounds"
                  v-model="$parent.episode.care_directives[0].skin_care.other_wound_descrption"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />

          <p class="subheading">Risk and Precautions</p>
          <b-form-row>
            <b-col cols="6">
              <b-form-group>
                <p class="">Risks:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.risks_selected"
                  :options="risks_and_precautions_options"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-input
                v-if="$parent.risks_selected == 7"
                id="care-directives-risks-other-selected"
                v-model="$parent.episode.care_directives[0].risks.risk_other_description"
                type="text"
                placeholder="Please specify..."
              ></b-form-input>
            </b-col>
          </b-form-row>
          <br />
          <b-form-row>
            <b-col cols="6">
              <b-form-group
                class=""
                label="Precautions:"
                label-cols="3"
                label-for="care-directives-precautions"
              >
                <b-form-input
                  id="care-directives-precautions"
                  v-model="$parent.episode.care_directives[0].risks.precaution_description"
                  type="text"
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          
        <div class="flex-container">
          <div>
          <p class="subheading">Infection Control Issues</p>
          <b-form-row>
            <b-col cols="10">
              <b-form-group>
                <p class="">Infection / Colonization:</p>
                <b-form-select
                  v-b-tooltip.hover
                  title="Ctrl + LeftClick to select multiple"
                  v-model="$parent.infections_selected"
                  :options="infection_colonization_options"
                  multiple
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <br />
          <b-form-row>
            <b-col cols="10">
              <b-form-group
                class=""
                label="Isolation Precautions: "
                label-cols="5"
                label-for="care-directives-isolation-precautions"
              >
                <b-form-select
                  id="care-directives-isolation-precautions"
                  v-model="$parent.episode.care_directives[0].infection_control.isolation_precautions_selected"
                  :options="isolation_precautions_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          </div>

          <div>
            <br />
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <p class="bold-label">Immunizations (in hospital):</p>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <p>Influenza:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-influenza-cbx"
                    v-model="$parent.episode.care_directives[0].infection_control.hospital_immunization_influenza"
                    name="cbx-influenza"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
            <p>Pneumococcal:</p>
            <b-form-group>
              <b-col cols="3">
                <b-form-group>
                  <b-form-checkbox
                    class="checkbox"
                    id="care-directives-pneumococcal"
                    v-model="$parent.episode.care_directives[0].infection_control.hospital_immunization_pneumococcal"
                    name="cbx-pneumococcal"
                    value="1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-col cols="5">
              <b-form-group
                class=""
                v-if="$parent.episode.care_directives[0].infection_control.hospital_immunization_influenza == 1"
                label="Influenza:"
                label-cols="7"
                label-for="care-directives-influenza-dropdown"
              >
                <b-form-select
                  v-if="$parent.episode.care_directives[0].infection_control.hospital_immunization_influenza == 1"
                  id="care-directives-influenza-dropdown"
                  v-model="$parent.episode.care_directives[0].infection_control.have_influenza"
                  :options="influenza_dropdown_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-input
                id="care-directives-influenza-date"
                v-if="$parent.episode.care_directives[0].infection_control.have_influenza == 2"
                v-show="$parent.episode.care_directives[0].infection_control.hospital_immunization_influenza == 1"
                v-model="$parent.episode.care_directives[0].infection_control.have_influenza_date"
                type="date"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="5">
              <b-form-group
                class=""
                v-if="$parent.episode.care_directives[0].infection_control.hospital_immunization_pneumococcal == 1"
                label="pneumococcal:"
                label-cols="7"
                label-for="care-directives-pneumococcal-dropdown"
              >
                <b-form-select
                  v-if="$parent.episode.care_directives[0].infection_control.hospital_immunization_pneumococcal == 1"
                  id="care-directives-pneumococcal-dropdown"
                  v-model="$parent.episode.care_directives[0].infection_control.have_pneumococcal"
                  :options="pneumococcal_dropdown_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-input
                id="care-directives-pneumococcal-date"
                v-if="$parent.episode.care_directives[0].infection_control.have_pneumococcal == 2"
                v-show="$parent.episode.care_directives[0].infection_control.hospital_immunization_pneumococcal == 1"
                v-model="$parent.episode.care_directives[0].infection_control.have_pneumococcal_date"
                type="date"
                placeholder="..."
              ></b-form-input>
            </b-col>
          </b-form-row>
          </div>
        </div>

        </b-form>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CareDirectives",
  components: {},
  computed: {},

  data: function () {
    return {
      ambulatory_status: null,
      weight_bearing_status: null,
      transfer_status: null,
      sight_status: null,
      hearing_status: null,
      devices_used: [],
      continence_status: [],
      catheter_date_inserted: null,
      retention_status: 0,
      skin_protection_status: 0,
      other_status: 0,
      other_specification: "",
      last_bowel_movement_date: null,
      diet_text: "",
      consistency_text: "",
      freeWaterRestrictionText: "",
      eatingInstructions: [],
      speechTherapyTextArea: "",
      tubeUsed: null,
      tubeDateInserted: null,
      fwbNumericInput: null,
      everyNumericInput: null,
      productName: "",
      rate: null,
      duration: null,
      PICCDateStatus: 0,
      portacathDateStatus: 0,
      PortacathDate: null,
      PICCDate: null,

      ambulatory_options: [
        { value: null, text: "..." },
        { value: 1, text: "Independant" },
        { value: 2, text: "With Assistance" },
        { value: 3, text: "With Assistive Device" },
        { value: 4, text: "Not Ambulatory" },
      ],
      weight_bearing_options: [
        { value: null, text: "..." },
        { value: 1, text: "Full" },
        { value: 2, text: "Partial L / R" },
        { value: 3, text: "None L / R" },
      ],
      transfer_options: [
        { value: null, text: "..." },
        { value: 1, text: "Self" },
        { value: 2, text: "1 Person Assist" },
        { value: 3, text: "2 Person Assist" },
      ],

      sight_options: [
        { value: null, text: "..." },
        { value: 1, text: "Normal" },
        { value: 2, text: "Impaired" },
        { value: 3, text: "Blind" },
      ],
      hearing_options: [
        { value: null, text: "..." },
        { value: 1, text: "Normal" },
        { value: 2, text: "Impaired" },
        { value: 3, text: "Deaf" },
      ],

      devices_options: [
        { value: 1, text: "Wheelchair" },
        { value: 2, text: "Walker" },
        { value: 3, text: "Cane" },
        { value: 4, text: "Crutches" },
        { value: 5, text: "Prosthesis" },
        { value: 6, text: "Glasses" },
        { value: 7, text: "Contacts" },
        { value: 8, text: "Dentures" },
        { value: 9, text: "Hearing Aid L / R" },
      ],

      continence_options: [
        { value: 1, text: "Continent" },
        { value: 2, text: "Bladder Incontinent" },
        { value: 3, text: "Ostomy" },
      ],

      eatingInstructionsOptions: [
        { value: 1, text: "Self" },
        { value: 2, text: "With Assistance" },
        { value: 3, text: "Difficulty Swallowing" },
      ],

      tubeOptions: [
        { value: null, text: "..." },
        { value: 1, text: "G Tube" },
        { value: 2, text: "J Tube" },
      ],

      cardiacDevices: [],
      cardiacDevicesOptions: [
        { value: 1, text: "Pacemaker" },
        { value: 2, text: "ICD" },
        { value: 3, text: "Other" },
      ],
      cardiacDevicesOtherSelected: 0,
      cardiacDevicesOtherSelectedText: "",

      respiratoryItems: [],
      respiratoryItemsOptions: [
        { value: 1, text: "CPAP" },
        { value: 2, text: "BiPAP" },
        { value: 3, text: "PRN" },
        { value: 4, text: "Continuous" },
        { value: 5, text: "Suction" },
      ],
      o2: null,
      trachSize: null,

      therapiesList: [],
      therapiesListOptions: [
        { value: 1, text: "PT" },
        { value: 2, text: "OT" },
        { value: 3, text: "Speech" },
        { value: 4, text: "Respiratory" },
        { value: 5, text: "Dialysis" },
      ],
      therapy_assessment: null,

      skin_breakdown: 0,
      first_ulcer: 0,
      second_ulcer: 0,
      other_wounds: 0,
      first_ulcer_stage: null,
      first_ulcer_location: null,
      second_ulcer_stage: null,
      second_ulcer_location: null,
      other_wounds_description: null,

      risks_and_precautions: [],
      risks_and_precautions_options: [
        { value: 1, text: "Fall" },
        { value: 2, text: "Delerium" },
        { value: 3, text: "Agitation" },
        { value: 4, text: "Aggression" },
        { value: 5, text: "Unescorted Exiting" },
        { value: 6, text: "Aspiration" },
        { value: 7, text: "Other" },
      ],

      risks_other_selected_text: "",
      precautions: "",

      infection_colonization: [],
      infection_colonization_options: [
        { value: 1, text: "MRSA" },
        { value: 2, text: "VRW" },
        { value: 3, text: "C.difficle" },
        { value: 4, text: "ESBL" },
        { value: 5, text: "Norovirus" },
        { value: 6, text: "Flu/Respiratory" },
      ],

      isolation_precautions: null,
      isolation_precautions_options: [
        { value: null, text: "..." },
        { value: 1, text: "None" },
        { value: 2, text: "Contact" },
        { value: 3, text: "Contact Plus" },
        { value: 4, text: "Droplet" },
        { value: 5, text: "Airborne" },
      ],

      influenza_status: 0,
      influenza_dropdown: null,
      influenza_dropdown_options: [
        { value: null, text: "..." },
        { value: 1, text: "No" },
        { value: 2, text: "Yes" },
      ],
      influenza_date: null,

      pneumococcal_status: 0,
      pneumococcal_dropdown: null,
      pneumococcal_dropdown_options: [
        { value: null, text: "..." },
        { value: 1, text: "No" },
        { value: 2, text: "Yes" },
      ],
      pneumococcal_date: null,
    };
  },
  methods: {
    // loadData(){
    //   this.loadCareDirective();
    // },
    // async loadCareDirective() {
    //   let loCareDirective = await axios.get(
    //     "/facility-staff/" + this.$parent.facility_id
    //   );
    //   if (loCareDirective.data) {
    //     this.$parent.epis = care_providers.data.data;
    //     this.$parent.assignEpisodeToPCPMultiSelect();
    //   }
    // },
  },
  watch: {

    treatmentsAndDevices: function (val) {
      function checkSelected(value) {
        return value == 1;
      }

      var PDISelected = val.find(checkSelected);
      if (PDISelected == 1) {
        this.portacathDateStatus = 1;
      } else {
        this.portacathDateStatus = 0;
      }
    },

    cardiacDevices: function (val) {
      function checkSelected(value) {
        return value == 3;
      }

      var CDOtherSelected = val.find(checkSelected);
      if (CDOtherSelected == 3) {
        this.cardiacDevicesOtherSelected = 3;
      } else {
        this.cardiacDevicesOtherSelected = 0;
      }
    },

    risks: function (val) {
      function checkSelected(value) {
        return value == 7;
      }

      var risks_other = val.find(checkSelected);
      if (risks_other == 7) {
        this.risks_and_precautions = 7;
      } else {
        this.risks_and_precautions = 0;
      }
    },

    influenza: function (val) {
      function checkSelected(value) {
        return value == 1;
      }

      var influenza_yes_selected = val.find(checkSelected);
      if (influenza_yes_selected == 1) {
        this.influenza_dropdown = 1;
      } else {
        this.influenza_dropdown = 0;
      }
    },
    pneumococcal: function (val) {
      function checkSelected(value) {
        return value == 1;
      }

      var pneumococcal_yes_selected = val.find(checkSelected);
      if (pneumococcal_yes_selected == 1) {
        this.pneumococcal_dropdown = 1;
      } else {
        this.pneumococcal_dropdown = 0;
      }
    },

  },

  mounted() {
    // this.loadData();
  },


};
</script>

<style scoped>
.subheading {
  color: #0073c5 !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  margin: 17px 0 30px 0;
  text-align: left;
}

.bold-label {
  font-weight: bold;
}

.flex-container {
  display: flex;
}

.flex-container > div {
  margin: 5px;
  flex: 1 1 auto;
}

hr {
  border-top: 3px solid rgba(0,0,0,0.3);
}
</style>
