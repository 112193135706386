<template>
    <div>
        <b-form-row>
            <b-col sm="12">
                <b-form-group label="Directives">
                    <b-form-checkbox
                        id="fullCodeSwitch"
                        v-model="fullCode"
                        name="fullCodeSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Full Code
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="dnrSwitch"
                        v-model="dnr"
                        name="dnrSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        DNR
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="dmiSwitch"
                        v-model="dni"
                        name="dniSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        DNI
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="dnhSwitch"
                        v-model="dnh"
                        name="dnhSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        DNH
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="noArtificialFeedingSwitch"
                        v-model="noArtificialFeeding"
                        name="noArtificialFeedingSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        No Artificial Feeding
                    </b-form-checkbox>
                </b-form-group>
            </b-col>

            <b-col sm="12">
                <b-form-group label="Care Directives">
                    <b-form-checkbox
                        id="comfortCareSwitch"
                        v-model="comfortCare"
                        name="comfortCareSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Comfort Care
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="hospiceCareSwitch"
                        v-model="hospiceCare"
                        name="hospiceCareSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Hospice Care
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="otherSwitch"
                        v-model="other"
                        name="otherSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Other Care
                    </b-form-checkbox>
                </b-form-group>

                <b-form-row v-if="other === '1' || other === 1" class="mt-3">
                    <b-form-input
                        v-model="otherCare"
                        placeholder="Please describe the other care."
                    ></b-form-input>
                </b-form-row>
            </b-col>

            <b-col class="sm12">
                <b-form-group label="Advisory">
                    <b-form-checkbox
                        id="decisionMakingCapacitySwitch"
                        v-model="decisionMakingCapacity"
                        name="decisionMakingCapacitySwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Has Decision Making Capacity
                    </b-form-checkbox>

                    <b-form-checkbox
                        id="goalOfCareDiscussedSwitch"
                        v-model="goalOfCareDiscussed"
                        name="goalOfCareDiscussedSwitch"
                        stacked
                        size="lg"
                        switch
                        value=1
                        unchecked-value=0
                    >
                        Goal Of Care Discussed
                    </b-form-checkbox>
                </b-form-group>

                <b-form-row v-if="goalOfCareDiscussed === '1' || goalOfCareDiscussed === 1">
                    <b-form-input
                        v-model="goalOfCare"
                        placeholder="Please describe the goal of care."
                    ></b-form-input>
                </b-form-row>

            </b-col>
        </b-form-row>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {

    computed: {
        ...mapGetters({

            getEpisode: 'episode/getEpisode'
        }),

        fullCode: {
            get() {
                return this.getEpisode?.directives?.full_code;
            },
            set(value) {
                this.updateDirectiveFullCode(value);
            },
        },
        dnr: {
            get() {
                return this.getEpisode?.directives?.dnr;
            },
            set(value) {
                this.updateDirectiveDNR(value);
            },
        },
        dni: {
            get() {
                return this.getEpisode?.directives?.dni;
            },
            set(value) {
                this.updateDirectiveDNI(value);
            },
        },
        dnh: {
            get() {
                return this.getEpisode?.directives?.dnh;
            },
            set(value) {
                this.updateDirectiveDNH(value);
            },
        },
        noArtificialFeeding: {
            get() {
                return this.getEpisode?.directives?.no_artificial_feeding;
            },
            set(value) {
                this.updateDirectiveNoArtificialFeeding(value);
            },
        },
        comfortCare: {
            get() {
                return this.getEpisode?.directives?.comfort_care;
            },
            set(value) {
                this.updateDirectiveComfortCare(value);
            },
        },
        hospiceCare: {
            get() {
                return this.getEpisode?.directives?.hospice_care;
            },
            set(value) {
                this.updateDirectiveHospiceCare(value);
            },
        },
        other: {
            get() {
                return this.getEpisode?.directives?.other;
            },
            set(value) {
                this.updateDirectiveOther(value);
            },
        },
        otherCare: {
            get() {
                return this.getEpisode?.directives?.other_care;
            },
            set(value) {
                this.updateDirectiveOtherCare(value);
            },
        },
        decisionMakingCapacity: {
            get() {
                return this.getEpisode?.directives?.decision_making_capacity;
            },
            set(value) {
                this.updateDirectiveDecisionMakingCapacity(value);
            },
        },
        goalOfCareDiscussed: {
            get() {
                return this.getEpisode?.directives?.goal_of_care_discussed;
            },
            set(value) {
                this.updateDirectiveGoalOfCareDiscussed(value);
            },
        },
        goalOfCare: {
            get() {
                return this.getEpisode?.directives?.goal_of_care;
            },
            set(value) {
                this.updateDirectiveGoalOfCare(value);
            },
        },
    },
    methods: {
        ...mapMutations({
            updateDirectiveFullCode:'episode/updateDirectiveFullCode',
            updateDirectiveDNR:'episode/updateDirectiveDNR',
            updateDirectiveDNI:'episode/updateDirectiveDNI',
            updateDirectiveDNH:'episode/updateDirectiveDNH',
            updateDirectiveNoArtificialFeeding:'episode/updateDirectiveNoArtificialFeeding',
            updateDirectiveComfortCare:'episode/updateDirectiveComfortCare',
            updateDirectiveHospiceCare:'episode/updateDirectiveHospiceCare',
            updateDirectiveOther:'episode/updateDirectiveOther',
            updateDirectiveOtherCare:'episode/updateDirectiveOtherCare',
            updateDirectiveDecisionMakingCapacity:'episode/updateDirectiveDecisionMakingCapacity',
            updateDirectiveGoalOfCareDiscussed:'episode/updateDirectiveGoalOfCareDiscussed',
            updateDirectiveGoalOfCare:'episode/updateDirectiveGoalOfCare'
            }
        ),
    },

    data: function () {
        return {};
    },
};
</script>
