<template>
  <b-card class="text-center" no-body>
    <template v-slot:header>
      <span style="font-size: 10pt; font-weight: bold">
        New {{ intervention.intervention }}</span
      >
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(symptom, symptomIndex) in intervention.selected_symptoms"
        :key="symptomIndex"
        style="font-size: 8pt"
        ><b-icon-check></b-icon-check> {{ symptom }}</b-list-group-item
      >
      <b-list-group-item
        v-for="(vital, vitalsIndex) in intervention.vitals"
        :key="'A' + vitalsIndex"
        style="font-size: 8pt; color: red"
      >
        {{ vital }}
      </b-list-group-item>
      <b-list-group-item
        v-for="(checklist, notificationsIndex) in intervention.selected_notifications"
        :key="'B' + notificationsIndex"
        style="font-size: 8pt; color: red"
        >Notify: {{ checklist }}</b-list-group-item
      >

      <b-list-group-item
        v-for="(tests, testsIndex) in intervention.selected_tests"
        :key="'C' + testsIndex"
        style="font-size: 8pt"
        >Test: {{ tests }}</b-list-group-item
      >
    </b-list-group>
  </b-card>
</template>
<script>

import {mapGetters} from 'vuex'
export default {

  computed: {
    ...mapGetters({
      intervention: 'episode/getCurrentIntervention',
    })
  },
}
</script>