<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="2">
          <p><strong>Summary:</strong></p>
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col cols="2"></b-col>
        <b-col>
          <br />
          <p><strong>Condition:</strong></p>
          <div class="sign-symptom-assess-item">
            <i
              :class="{
                'fa fa-check-square':
                  assessment.is_immediate === 1 ||
                  assessment.is_immediate === true,
                'fa fa-times':
                  assessment.is_immediate === 0 ||
                  assessment.is_immediate === false,
              }"
            ></i>
            <p>{{ selectedSignAndSymptomObject.immediate_symptom }}</p>
          </div>
          <div class="sign-symptom-assess-item">
            <i
              :class="{
                'fa fa-check-square':
                  assessment.is_immediate === 0 ||
                  assessment.is_immediate === false,
                'fa fa-times':
                  assessment.is_immediate === 1 ||
                  assessment.is_immediate === true,
              }"
            ></i>
            <p>{{ selectedSignAndSymptomObject.non_immediate_symptom }}</p>
          </div>
          <div class="sign-symptom-assess-item" v-if="assessment.started_at">
            <i class="fa fa-calendar-alt"></i>
            <p>
              This condition, symptom, or sign started on
              {{ assessment.started_at }}.
            </p>
          </div>
          <div class="sign-symptom-assess-item">
            <i class="fa"></i>
            <p>This condition, symptom, or sign has : {{ directionText }}</p>
          </div>
          <div
            class="sign-symptom-assess-item"
            v-if="assessment.negative_factors.length > 0"
          >
            <b-button
              variant="link"
              @click="showNegativeFactors = !showNegativeFactors"
              ><i class="fa fa-plus-circle"></i
            ></b-button>

            <div>
              <i class="fa fa-exclamation-circle"></i>
              <strong> Items that made the condition or symptom worse:</strong>
              <div v-show="!showNegativeFactors">
                {{ abbreviatedNegativeFactors }}
              </div>
              <div v-show="showNegativeFactors">
                {{ assessment.negative_factors }}
              </div>
            </div>
          </div>
          <div
            class="sign-symptom-assess-item"
            v-if="assessment.positive_factors.length > 0"
          >
            <b-button
              variant="link"
              @click="showPositiveFactors = !showPositiveFactors"
              ><i class="fa fa-plus-circle"></i
            ></b-button>

            <div>
              <i class="fa fa-check-circle"></i>
              <strong> Items that made the condition or symptom better:</strong>
              <div v-show="!showPositiveFactors">
                {{ abbreviatedPositiveFactors }}
              </div>
              <div v-show="showPositiveFactors">
                {{ assessment.positive_factors }}
              </div>
            </div>
          </div>
          <div
            class="sign-symptom-assess-item"
            v-if="
              assessment.is_reoccurrence === 'true' ||
              assessment.is_reoccurrence === true
            "
          >
            <span style="color: red">
              <i class="fa fa-flag"></i>
            </span>
            <p>
              This condition, symptom, or sign
              <strong>{{
                assessment.is_reoccurrence === "true" ||
                assessment.is_reoccurrence === true
                  ? "has"
                  : "has not"
              }}</strong>
              occurred before.
            </p>
          </div>
          <div
            class="sign-symptom-assess-item"
            v-if="
              assessment.last_treatment && assessment.last_treatment.length > 0
            "
          >
            <b-button
              variant="link"
              @click="showLastTreatmentFactors = !showLastTreatmentFactors"
              ><i class="fa fa-plus-circle"></i
            ></b-button>

            <div>
              <i class="fa fa-heartbeat"></i>
              <strong> The last treatment for this sign or condition:</strong>
              <div v-show="!showLastTreatmentFactors">
                {{ abbreviatedLastTreatmentFactors }}
              </div>
              <div v-show="showLastTreatmentFactors">
                {{ assessment.last_treatment }}
              </div>
            </div>
          </div>
          <div
            class="sign-symptom-assess-item"
            v-if="this.assessment.other_factors.length > 0"
          >
            <b-button
              variant="link"
              @click="showOtherFactors = !showOtherFactors"
              ><i class="fa fa-plus-circle"></i
            ></b-button>

            <div>
              <i class="fa info-circle"></i>
              <strong> Other relevant information:</strong>
              <div v-show="!showOtherFactors">
                {{ abbreviatedOtherFactors }}
              </div>
              <div v-show="showOtherFactors">
                {{ assessment.other_factors }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row style="width: 98%">
        <b-col cols="2">
          <p><strong>Next:</strong></p>
        </b-col>
        <b-col>
          <div v-if="this.assessment.immediacy === 'true'">
            <img
              src="@/assets/Icons/top-ribbon/SquareAlert ICONx64 RED.png"
              height="32px"
            />
            <div style="display: inline-block; margin-left: 0.5rem">
              <p><strong>Notify Doctor Immediately</strong></p>
            </div>
          </div>
          <div v-if="assessment.immediacy === 'false'">
            <p>Notify Doctor next day.</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Assess",
  computed: {
    ...mapGetters({
      assessment: "episode/getAssessment",
      getCurrentSignsAndSymptom: "episode/getCurrentSignsAndSymptom",
      getSignsAndSymptoms: "configuration/signsAndSymptoms",
      selectedSignAndSymptom: "configuration/getSelectedSignsAndSymptoms",
    }),

    abbreviatedNegativeFactors() {
      return this.assessment.negative_factors.substring(0, 30) + "...";
    },

    abbreviatedPositiveFactors() {
      return this.assessment.positive_factors.substring(0, 30) + "...";
    },

    abbreviatedOtherFactors() {
      return this.assessment.other_factors.substring(0, 30) + "...";
    },

    abbreviatedLastTreatmentFactors() {
      return this.assessment.last_treatment.substring(0, 30) + "...";
    },

    selectedSignAndSymptomObject() {
      return this.getSignsAndSymptoms.find(
        (symptom) => symptom.id === this.getCurrentSignsAndSymptom
      );
    },

    immediacyText() {
      return this.assessment.immediacy === "true"
        ? this.selectedSignAndSymptom.immediate_symptom
        : this.selectedSignAndSymptom.non_immediate_symptom;
    },

    directionText() {
      if (this.assessment.severity_direction === "worse") {
        return "gotten worse.";
      }

      if (this.assessment.severity_direction === "better") {
        return "gotten better.";
      }

      return "remained the same.";
    },
  },

  data() {
    return {
      showNegativeFactors: false,
      showPositiveFactors: false,
      showOtherFactors: false,
      showLastTreatmentFactors: false,
    };
  },
};
</script>

<style scoped></style>
