<template>

    <b-alert :variant="variant" show>
        <i class="fas fa-notes-medical mr-2"></i>
        <slot>
            No Alert Data was passed.
        </slot>
    </b-alert>
</template>

<script>
export default {
    name: "AppNote",
    props: {
        variant: {
            required: false,
            default: 'info',
            type: String,
        },
    },
}
</script>

<style scoped>

</style>