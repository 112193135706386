<template>
    <div>
        <!-- arbitary comment -->
        <div v-if="!showFacilities">
            <b-form>
                Please enter the Organizations UUID to get a list of facilities.
                <b-input v-model="organizationUuid"></b-input>
                <b-button @click="getFacilityList" class="mt-2">Fetch</b-button>
            </b-form>
        </div>

        <div class="d-flex justify-content-center mb-3" v-if="isBusy && !showFacilities">
            <b-spinner
                label="Loading..."
                variant="info"
                style="width: 5rem; height: 5rem"
            ></b-spinner>
        </div>

        <div v-if="showFacilities && !isBusy">
            <b-table striped hover :items="responseObject" :fields="facilityFields" :busy="isBusy">
                <template #cell(add_facility)="row">
                    <div v-if="row.item.is_onboarded">
                        <app-button size="sm" @click="onboardFacility(row.item)" class="mr-2" is-small
                                    v-if="! row.item.is_disabled"
                                    :is-disabled="row.item.is_onboarded">
                            <span>Onboarded</span>
                        </app-button>
                        <app-button size="sm" @click="reactivateFacility(row.item)" class="mr-2" is-small
                                    v-if="row.item.is_disabled">
                            <span>Reactivate</span>
                        </app-button>
                    </div>
                    <div v-if="! row.item.is_onboarded">
                        <app-button size="sm" @click="onboardFacility(row.item)" class="mr-2" is-small>
                            <span>Onboard</span>
                        </app-button>
                    </div>
                </template
                >
            </b-table>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import AppButton from "../AppButton.vue";

export default {
    components: {AppButton},
    data: function () {
        return {
            organizationUuid: null,
            listOfFacilities: [],
            responseObject: {},
            showFacilities: false,
            facilityFields: [
                {key: "facId", sortable: true, label: "Facility Id"},
                {key: "facilityName", sortable: true, label: "Name"},
                {key: "bedCount", sortable: true, label: "Bed Count"},
                {key: "facilityCode", sortable: true, label: "Code"},
                {key: "addressLine1", sortable: true, label: "Address"},
                {key: "city", sortable: true, label: "City"},
                {key: "state", sortable: true, label: "State"},
                {key: "facilityCode", sortable: true, label: "Code"},
                {key: "add_facility", sortable: false, label: "Onboard"},
            ],
            isBusy: false,
        };
    },
    methods: {

        shouldOnboardText(onboardedState) {

            return onboardedState ? "Onboarded" : "Onboard"
        },

        async getFacilityList() {
            this.showLoader()
            const success = await this.canGetListOfFacilities();

            if (success) {
                this.showFacilityList();
            }
        },

        createFacilityObject(item) {

            const data = {
                name: item.facilityName,
                standing_orders: false,
                status: true,
                beds: item.bedCount,
                timezone: item.timeZone,
                is_pcc_facility: true,
                organization_uuid: item.orgUuid,
                organization_name: item.orgName,
                pcc_facility_id: item.facId,
                facility_id: item.facId,
            }

            return data

        },

        async reactivateFacility(item) {

            this.isBusy = true
            const data = this.createFacilityObject(item)
            await axios.put(`facilities/${item.aptuscare_id}`, data)
                .then(response => {

                    if (response.status >= 200 && response.status < 300) {

                        this.$bvModal.msgBoxOk(response.data.message, {
                            title: 'Created',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'primary',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        })

                        return {success: true, message: response.data.message}
                    }

                    if (response.status >= 300 && response.status < 400) {

                        this.$bvModal.msgBoxOk(response.data.message)

                        return {success: false, error: "System does not support redirects."}
                    }

                    if (response.status >= 400 && response.status < 500) {

                        return {success: false, error: "The data was passed in an invalid format."}
                    }

                    if (response.status >= 500) {

                        this.$bvModal.msgBoxOk(response.data.message)

                        return {
                            success: false,
                            error: "The server encountered an error, please review the system logs."
                        }
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                        this.isBusy = false
                    }
                )
        },

        async onboardFacility(item) {

            const data = this.createFacilityObject(item)

            this.isBusy = true;

            await axios.post(`/facilities`, data)
                .then(response => {

                    if (response.status >= 200 && response.status < 300) {

                        this.$bvModal.msgBoxOk(response.data.message, {
                            title: 'Created',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'primary',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        })

                        return {success: true, message: response.data.message}
                    }
                })
                .catch(error => {

                    const response = error.response
                    if (response.status >= 300 && response.status < 400) {

                        this.$bvModal.msgBoxOk(response.data.message)

                        return {success: false, error: "System does not support redirects."}
                    }

                    if (response.status >= 400 && response.status < 500) {

                        return {success: false, error: "The data was passed in an invalid format."}
                    }

                    if (response.status >= 500) {

                        this.$bvModal.msgBoxOk(response.data.message)

                        return {
                            success: false,
                            error: "The server encountered an error, please review the system logs."
                        }
                    }
                    error => console.error("An error occurred: ", error)
                })
                .finally(() => {
                    this.isBusy = false
                })
        },

        async canGetListOfFacilities() {

            const response = await axios.get(`pointclickcare/facilities/query/${this.organizationUuid}`)
                .then((response) => {
                    this.responseObject = response.data
                    return response.data
                })
                .catch((error) => {

                        console.error("An error occurred: ", error)
                        return error.message
                    }
                )

            this.responseObject = response;

            return response;
        }
        ,
        showFacilityList() {
            this.showFacilities = true;
            this.isBusy = false;
        }
        ,
        showLoader() {

            this.showFacilities = false
            this.isBusy = true
        }
    },
};
</script>