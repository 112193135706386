<template>
    <b-container>
        <b-row>
            <b-col>
                <b-tabs content-class="mt-3">
                    <b-tab title="Add AptusCare Provider" active>
                        <b-form>
                            <b-form-row>
                                <b-col>
                                    <b-form-group
                                        id="care-provider-group"
                                        label="Select a Care Provider."
                                        label-for="care-providers"
                                    >
                                        <b-form-select
                                            :options="facilityCareProviders"
                                            v-model="selectedFacilityCareProviderId"
                                            id="care-providers"
                                        >
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>Please select a provider.
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col>
                                    <app-button @click="addProvider" class="mt-3">Add Provider</app-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-tab>
                    <b-tab title="Add Outside Provider">
                        <b-form>
                            <b-form-row>
                                <b-col>
                                    <b-form-group
                                        id="name-group"
                                        label="Provider Name"
                                        label-for="name"
                                        label-cols-md="3"
                                    >
                                        <b-form-input
                                            id="name"
                                            type="text"
                                            v-model="outsideProvider.name"
                                            placeholder="Name"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col>
                                    <b-form-group
                                        id="speciality-group"
                                        label="Speciality"
                                        label-for="speciality"
                                        label-cols-md="3"
                                    >
                                        <b-form-input
                                            id="speciality"
                                            type="text"
                                            v-model="outsideProvider.speciality"
                                            placeholder="Speciality"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <b-form-group
                                        id="telephone-group"
                                        label="Contact Number"
                                        label-for="telephone"
                                        label-cols-md="3"
                                    >
                                        <b-form-input
                                            id="telephone"
                                            type="text"
                                            v-model="outsideProvider.contact_number"
                                            placeholder="+1 (0) 000-0000"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col>
                                    <b-form-group
                                        id="email-group"
                                        label="Contact Email"
                                        label-for="email"
                                        label-cols-md="3"
                                    >
                                        <b-form-input
                                            id="email"
                                            type="email"
                                            v-model="outsideProvider.email"
                                            placeholder="email@email.com"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col>
                                    <b-button @click="addOutsideProvider"
                                    >Add Outside Provider
                                    </b-button
                                    >
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                &nbsp;
                <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant="addProviderStatusResult"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                    class="mt-3"
                >
                    <p>{{ addProviderResult }}</p>
                    <b-progress
                        variant="success"
                        :max="dismissSecs"
                        :value="dismissCountDown"
                        height="4px"
                    ></b-progress>
                </b-alert>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import AppButton from "@/components/AppButton";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {
        AppButton,
    },
    computed: {
        ...mapGetters({
            getEpisode: "episode/getEpisode",
            getCareProviderOption: "configuration/getCareProviderOption",
            getCareProviders: "configuration/careProviders",
            getCareTeam: 'episode/getCareTeam',
        }),

        facilityId() {
            return this.getEpisode.facility_id;
        },

        patientId() {
            return this.getEpisode.patient.id;
        },

        episodeId() {
            return this.getEpisode.id;
        },

        careTeam() {
            return this.getEpisode.care_teams;
        },

        facilityCareProviders() {

            const providers = this.getCareProviders
                .filter(provider => provider.facility.id === this.facilityId)

            const allocatedCareProviders = this.careTeam.map((careTeam) => {
                return careTeam.action !== 'D' ? careTeam.facility_staff_id : null
            }).filter(team => team !== null)
            const allocatedProviders = providers.filter(provider => ! allocatedCareProviders.includes(provider.staff_id))
            return allocatedProviders.map(provider => provider.designation)
        },

        providers() {
            return this.careProviders;
        },
    },
    data: function () {
        return {
            selectedFacilityCareProviderId: null,
            dismissSecs: 1,
            dismissCountDown: 0,
            addProviderResult: "",
            addProviderStatusResult: "danger",
            outsideProvider: {
                name: "",
                speciality: "",
                contact_number: "",
                is_primary: false,
                patient_id: this.patientId,
                episode_id: this.episodeId,
                facility_staff_id: 0,
                email: "",
                action: "R",
            },
        };
    },
    methods: {
        ...mapActions({
            addCareTeamMember: "episode/addCareTeamMember",
        }),
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        async addOutsideProvider() {
            this.outsideProvider.episode_id = this.getEpisode.id;
            this.outsideProvider.patient_id = this.getEpisode.patient_id;
            this.outsideProvider.action = "C"
            await this.addCareTeamMember(this.outsideProvider);
            this.resetOutsideProvider();
        },
        resetOutsideProvider() {
            this.outsideProvider = {
                name: "",
                speciality: "",
                contact_number: "",
                is_primary: false,
                patient_id: this.patientId,
                episode_id: this.episodeId,
                facility_staff_id: 0,
                email: "",
                action: "R",
            };
        },
        async addProvider() {
            const provider = this.getCareProviders.find(
                (provider) => provider.designation === this.selectedFacilityCareProviderId
            );
            const data = {
                name: provider.designation,
                speciality: provider.speciality != null ? provider.speciality : "Not Specified",
                contact_number: provider.work_number != null ? provider.work_number : "Not Specified",
                is_primary: false,
                patient_id: this.patientId,
                episode_id: this.episodeId,
                facility_staff_id: provider.id,
                email: provider.work_email,
                action: "C",
            };
            await this.addCareTeamMember(data);
            this.selectedFacilityCareProviderId = null;
        },
    },
};
</script>