<template>
  <div>
    <div
      class="escalation escalation-icon"
      :id="'intervention-list-' + episode.id"
      :class="{
        'warning low-warning': hasLowWarning,
        'warning medium-warning': hasMediumWarning,
        'warning high-warning': hasHighWarning,
      }"
      title="Escalation"
    >
      <img
        v-if="hasInterventions"
        src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/Intervention-ICONx64-RED.png"
        alt="Has Escalations"
        class="icon-size"
      />
      <img
        v-if="!hasInterventions"
        src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png"
        alt="No Escalations"
        class="icon-size"
      />
      <span
        v-if="showInterventionCountBadge"
        class="escalation-notifications"
        >{{ activeInterventions.length }}</span
      >
    </div>
    <b-popover
      :target="'intervention-list-' + episode.id"
      triggers="hover focus"
      placement="left"
      :show.sync="show"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="intervention in interventions"
          :id="intervention.id"
          :key="intervention.id"
          button
          class="d-flex justify-content-between align-items-center f-poppins"
          :variant="hasActivity(intervention.id, episode.interventions)"
          @click="openActivity(intervention.id)"
        >
          {{ intervention.intervention }}
          <b-icon
            icon="bell-fill"
            :class="[
              'rounded-circle',
              'p-2',
              hasActivityBackground(intervention.id, episode.interventions),
            ]"
            variant="light"
          ></b-icon>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  props: {
    episode: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      interventions: "configuration/interventions",
    }),

    hasInterventions() {
      return this.episode.interventions.length > 0;
    },

    activeInterventions() {
      return this.episode.interventions.map((intervention) => {
        intervention.active = 1;
      });
    },

    showInterventionCountBadge() {
      return this.activeInterventions.length > 0;
    },

    unnotifiedEscalations() {
      return this.episode.interventions?.filter(this.requiresNotification);
    },

    oldestEscalation() {
      return this.unnotifiedEscalations?.reduce(this.oldest);
    },

    age() {
      return this.numberOfHours(moment(this.oldestEscalation.created_at));
    },

    escalationNotificationIsRecent() {
      return (
        !this.escalationNotificationIsModerate &&
        !this.escalationNotificationIsOld &&
        this.age <= 1
      );
    },
    escalationNotificationIsModerate() {
      return !this.escalationNotificationIsOld && this.age > 1;
    },
    escalationNotificationIsOld() {
      return this.age >= 3;
    },

    hasLowWarning() {
      return (
        this.unnotifiedEscalations.length && this.escalationNotificationIsRecent
      );
    },

    hasMediumWarning() {
      return (
        this.unnotifiedEscalations.length &&
        this.escalationNotificationIsModerate
      );
    },

    hasHighWarning() {
      return (
        this.unnotifiedEscalations.length && this.escalationNotificationIsOld
      );
    },
  },
  methods: {
    getActivityClass(intervention, type) {
      switch (intervention.status) {
        //  There are 3 statuses: Active, Priority, Suggested
        case "Active":
          return type === "background" ? "bg-success" : "success";
        case "Priority":
          return type === "background" ? "bg-danger" : "danger";
        case "Suggested":
          return type === "background" ? "bg-primary" : "primary";
        default:
          return "";
      }
    },

    escalationSeverity() {
      if (this.hasInterventions) {
        return "../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/Intervention-ICONx64-RED.png";
      }

      return "../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png";

      // return "../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png"
    },
    hasActivityBackground(interventionId, episodeInterventions) {
      const intervention = episodeInterventions.filter((intervention) => {
        return intervention.intervention_id === interventionId;
      });

      if (intervention.length > 0) {
        return this.getActivityClass(intervention[0], "background");
      }
    },
    hasActivity(interventionId, episodeInterventions) {
      const intervention = episodeInterventions.filter((intervention) => {
        return intervention.intervention_id === interventionId;
      });

      if (intervention.length > 0) {
        return this.getActivityClass(intervention[0], "foreground");
      }
    },
    openActivity(interventionId) {
      this.show = false; //  closed the pop-over
      this.$emit("openInterventions", interventionId);
    },
    numberOfHours(assessment) {
      let assessmentTime = moment(assessment);
      return Math.abs(assessmentTime.diff(moment.now(), "hours"));
    },
    requiresNotification(item) {
      return item.notified === 0;
    },

    isNotNull(item) {
      return item !== null;
    },

    oldest(item, accumulator) {
      if (accumulator === undefined) {
        return item;
      }

      if (accumulator.created_at < item.created_at) {
        return accumulator;
      }

      return item;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: Poppins;
  src: url("~@/assets/fonts/poppins/Poppins-Light.otf");
}

.intervention {
  height: 58px;
  margin-left: 30%;
  margin-right: 30%;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.f-poppins {
  font-family: Poppins;
  font-size: 1.5em;
}

.intervention-icon-blue {
  background-image: url("~@/assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png");
}

.intervention-icon-red {
  background-image: url("~@/assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/Intervention-ICONx64-RED.png");
}

.escalation-badge {
  position: absolute;
  margin-top: -7px;
  margin-right: -4px;
  color: #fff;
}

.warning {
  animation-name: warning-cycle;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  border-radius: 50%;
}

.low-warning {
  animation-duration: 12s;
}

.medium-warning {
  animation-duration: 6s;
}

.high-warning {
  animation-duration: 125ms;
}

@keyframes warning-cycle {
  0% {
    background: #ffffba;
  }
  50% {
    background: #ffdfba;
  }
  100% {
    background: #ffb3ba;
  }
}

.escalation-icon {
  cursor: pointer;
}

.escalation-red-icon {
  content: url("../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/Intervention-ICONx64-RED.png");
}

.escalation-blue-icon {
  content: url("../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/Intervention-ICONx64.png");
}

.escalation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  outline: none;
  margin-left: auto;
  margin-right: auto;
}

.escalation-notifications {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icon-size {

    height: 40px;
    width: 40px;
}

</style>
