<template>
  <b-container>
    <div v-if="isLoading">
      <b-row>
        <b-col>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              label="Loading..."
              variant="info"
              style="width: 5rem; height: 5rem"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isLoading">
      <b-row>
        <b-col>
          <app-button
            @click="addFacility"
            variant="success"
            size="md"
            class="mb-2"
            is-narrow
            >Add Facility
          </app-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
        <b-col>
          <b-form-group
            label="Filter By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-2"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="facilityTable"
            :fields="facilitiesFields"
            :items="facilitiesItems"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            @filtered="onFiltered"
          >
            <template #cell(actions)="row">
              <app-button
                size="md"
                @click="editFacility(row.item)"
                variant="warning"
                id="edit-facility"
                is-small
                ><i class="fa fa-edit"></i
              ></app-button>
              &nbsp;
              <app-button
                size="md"
                @click="suspendFacility(row.item)"
                v-if="row.item.active === 'Yes'"
                id="diasble-facility"
                is-danger
                is-small
                ><i class="fa fa-thumbs-o-down"></i>
              </app-button>
              <app-button
                size="md"
                @click="suspendFacility(row.item)"
                v-if="row.item.active === 'No'"
                id="enable-facility"
                is-small
                is-success
                ><i class="fa fa-thumbs-o-up"></i>
              </app-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="facility-editor"
      title="Facility Editor"
      size="md"
      centered
      scrollable
      content-class="shadow"
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
      hide-footer
    >
      <facility-editor :facility="facility"></facility-editor>
    </b-modal>
  </b-container>
</template>
<script>
import axios from "axios";
import AppButton from "./AppButton.vue";
import FacilityEditor from "./FacilityEditor.vue";

export default {
  components: { AppButton, FacilityEditor },
  data: function () {
    return {
      facilitiesFields: [
        {
          key: "organization_name",
          label: "Organization Name",
          sortable: true,
        },
        { key: "facility", label: "Facility", sortable: true },
        { key: "beds", label: "Beds" },
        { key: "standing_orders", label: "Standing Orders" },
        { key: "is_pcc_facility", label: "PCC Facility" },
        { key: "timezone", label: "Time Zone" },
        { key: "created_by_user", label: "Created By" },
        { key: "active", label: "Status" },
        { key: "created_at", label: "Created" },
        { key: "updated_at", label: "Last Update" },
        { key: "actions", label: "Action" },
      ],
      facilitiesItems: [],
      facility: null,
      facilityPath: "facilities",
      isLoading: false,
      filter: null,
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
    };
  },
  methods: {
    async fetchFacilities() {
      this.isLoading = true;

      await axios
        .get(`facilities`)
        .then((response) => {
          this.facilitiesItems = response.data.data
          this.totalRows = this.facilitiesItems.length
        })
        .catch((error) => alert(error))
        .finally(() => (this.isLoading = false));
    },
    addFacility() {
      this.facility = {
        name: "",
        beds: null,
        standing_orders: "No",
        active: "Yes",
        mode: "add",
      };
      this.$bvModal.show("facility-editor");
    },
    editFacility(facility) {
      this.facility = facility;
      this.facility.mode = "edit";
      this.$bvModal.show("facility-editor");
    },
    async suspendFacility(facility) {
      this.isLoading = true;
      let status = facility.active === "Yes" ? 0 : 1;
      let standing_orders = facility.standing_orders === "Yes" ? 1 : 0;
      const facilityUpdate = {
        id: facility.id,
        name: facility.facility,
        standing_orders: standing_orders,
        status: status,
      };

      await axios
        .put(`${this.facilityPath}/${facility.id}`, facilityUpdate)
        .catch((error) => {
          alert(error);
        });
      await this.fetchFacilities().finally(() => (this.isLoading = false));
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  },
  mounted: function () {
    this.fetchFacilities();
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === "facility-editor") {
        this.fetchFacilities();
      }
    });
  },
};
</script>
