<template>
    <button :class="[{selected: isSelected}, {narrow: isNarrow}, {danger: isDanger}, {success: isSuccess}, {disabled: isDisabled}, {small: isSmall}, {warning: isWarning} ]" :disabled="isDisabled" @click="fireEvent" type="button">
        <slot />
    </button>
</template>
<script>
export default {
    props: {
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        isSuccess: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNarrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDanger: {
            type: Boolean,
            required: false,
            default: false,
        },

        isWarning: {
            type: Boolean,
            required: false,
            default: false,
        },

        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isSmall: {

            type: Boolean,
            requires: false,
            default: false,
        },
    },
    methods: {

        fireEvent() {
            this.$emit('click')
        }
    },
}
</script>


<style scoped>
    button {

        border-radius: 5px;
        font-family: Poppins,sans-serif;
        text-align: center;
        font-size: 18px;
        margin: 4px 2px;
        padding: 12px 24px;
        transition-duration: 0.4s;
        background-color: #FFFFFF;
        color: #3CB4E5;
        border: 2px solid #3CB4E5;
    }
    button:hover {
        background-color: #3CB4E5;
        color: #FFFFFF;
        border: 2px solid #3CB4E5;
    }
    .selected {

        background-color: #3CB4E5;
        color: #FFFFFF;
        border: 2px solid #3CB4E5;
    }
    .selected:hover {

        background-color: #FFFFFF;
        color: #3CB4E5;
        text-align: center;
        border: 2px solid #3CB4E5;
    }
    .narrow {
        border-radius: 3px;
        margin: 2px 1px;
        padding: 6px 12px;
    }

    .danger {
        background-color: #FFFFFF;
        color: #ff0000;
        border: 2px solid #ff0000;
    }

    .danger:hover {
        background-color: #ff0000;
        color: #FFFFFF;
        border: 2px solid #ff0000;
    }

    .warning {
        background-color: #FFFFFF;
        color: #ffc107;
        border: 2px solid #ffc107;
    }

    .warning:hover {
        background-color: #ffc107;
        color: #FFFFFF;
        border: 2px solid #ffc107;
    }

    .success {
        background-color: #FFFFFF;
        color: #41b883;
        border: 2px solid #41b883;
    }
    .success:hover {
        background-color: #41b883;
        color: #FFFFFF;
        border: 2px solid #41b883;
    }

    .disabled {
        background-color: #FFFFFF;
        color: #C7C9C9;
        border: 2px solid #C7C9C9;
    }

    .disabled:hover {
        background-color: #C7C9C9;
        color: #FFFFFF;
        border: 2px solid #C7C9C9;
    }

    .small {
        border-radius: 3px;
        margin: 2px 1px;
        padding: 6px 6px;
        font-size: 12px;
    }
</style>