<template>
    <b-container fluid>
        <episode-intervention-header
            :page="4"
            :intervention="intervention.intervention"
        ></episode-intervention-header>
        <b-row>
            <b-col sm="9">
                <h4 style="color: red">Please select all that are applicable.</h4>
                <h5>
                    Evaluate Immediate Notification Symptoms for
                    {{ intervention.intervention }}
                </h5>
                <b-form>
                    <b-form-group
                    >
                        <b-form-checkbox-group
                            id="evaluation-checkbox-group"
                            v-model="selectedNotifications"
                            :options="intervention.notifications"
                            name="interventionCheckList"
                            stacked
                            size="lg"
                            switches
                        ></b-form-checkbox-group
                        >
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col sm="3">
                <episode-intervention-flow-chart
                    :intervention="intervention"
                ></episode-intervention-flow-chart>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="goBack">&lt; BACK</app-button>
            </b-col>
            <b-col>
                <app-button
                    class="float-right"
                    :isDisabled="disallowNext"
                    :variant="nextVariant"
                    v-if="!requiresCommunication"
                    @click="goForward"
                >{{ nextText }}
                </app-button
                >
                <app-button
                    class="float-right"
                    isDanger
                    v-if="requiresCommunication"
                    @click="openMsgWindow"
                >COMMUNICATE
                </app-button
                >
            </b-col>
        </b-row>

        <b-modal
            ref="modal-unsaved-changes"
            title="Unsaved Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                You have unsaved changes. <br/>
                You must save your changes before you can communicate them to the
                primary care provider <br/>
                Would you like to save your changes?
            </p>
            <div class="text-center">
                <app-button @click="saveResidentData()" :is-disabled="isSaving">{{ saveButtonText}}</app-button>
                <app-button
                    @click="cancelMessageBoxShow('modal-unsaved-changes')"
                    isDanger
                >CLOSE
                </app-button
                >
            </div>
        </b-modal>

        <b-modal
            ref="modal-error-saving"
            title="Error Saving Changes"
            centered
            header-bg-variant="dark"
            header-text-variant="danger"
            hide-footer
            hide-header-close
        >
            <p class="my-4">
                <strong>We were unable to save the changes.</strong>
            </p>

            <h3>
                {{ episodeErrorsText }}
            </h3>
            <div v-for="error in episodeErrors.errors" :key="(index, error)">
                {{ error }}
            </div>

            <div class="text-center">
                <app-button @click="cancelMessageBoxShow('modal-error-saving')" isDanger
                >CLOSE
                </app-button
                >
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import EpisodeInterventionHeader from "@/components/Episode/EpisodeInterventionHeader";
import EpisodeInterventionFlowChart from "@/components/Episode/EpisodeInterventionFlowChart";
import AppButton from "@/components/AppButton";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "EpisodeInterventionsEvaluation",
    components: {
        EpisodeInterventionHeader,
        EpisodeInterventionFlowChart,
        AppButton,
    },
    data() {
        return {
            isSaving: false,
        }
    },
    computed: {
        ...mapGetters({
            interventions: 'configuration/interventions',
            getIntervention: "episode/getIntervention",
            currentIntervention: "episode/getCurrentIntervention",
            episode: "episode/getEpisode",
            getEpisodeId: "episode/getEpisodeId",
            isDirty: "episode/isDirty",
            episodeErrors: "episode/episodeErrors",
            episodeHasErrors: "episode/episodeHasErrors",
        }),

        saveButtonText() {
            if(this.isSaving) {
                return "Saving..."
            }

            return "Save"
        },

        baseIntervention() {

            return this.interventions.find((intervention) => {

                if(intervention.id === this.getIntervention.intervention.id) {
                    return true;
                }
            })
        },

        selectedNotificationCount() {

            // return this.$store.state.episode.intervention?.selected_notifications?.length
            if (this.intervention?.selected_notifications?.length > 0) {
                return this.intervention.selected_notifications.length
            }

            return []
        },

        hasNotified() {

            return this.intervention.notified
        },

        episodeErrorsText() {
            if (this.episodeHasErrors) {
                return this.episodeErrors.error;
            }

            return "";
        },
        intervention: {
            get() {
                return this.currentIntervention ?? { intervention: "Unknown Intervention or Intervention Not Loaded."}
            },
            set(data) {
                this.setIntervention(data);
            },
        },
        selectedNotifications: {
            get() {

                return this.$store.state.episode.intervention.selected_notifications
            },
            set(value) {
                // this.$store.commit("episode/updateSelectedNotifications", value);
                this.$store.dispatch('episode/updateSelectedNotificationsAndIntervention', value)
            },
        },
        disallowNext() {
            return false;
        },
        disallowBack() {
            return false;
        },
        nextText() {
            return this.requiresCommunication ? "COMMUNICATE" : "NEXT >";
        },
        nextVariant() {
            return this.requiresCommunication
                ? "outline-danger"
                : "outline-secondary";
        },
        requiresCommunication() {
            return this.selectedNotificationCount > 0 && (this.hasNotified === 0 || this.hasNotified === false)
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return "";
            value = value.toString();
            return value.toUpperCase();
        },
    },
    methods: {
        ...mapMutations({
            unloadMessages: "messages/unloadMessages",
            setMessageEpisodeId: "messages/setMessageEpisodeId",
        }),
        ...mapActions({
            openMessages: "messages/openMessages",
            loadMessages: "messages/loadMessages",
            saveEpisode: "episode/saveEpisode",
            sendNewMessage: "messages/sendNewMessage",
            addMessageToMessages: "messages/addMessageToMessages",
        }),

        cancelMessageBoxShow(modal) {
            this.$refs[modal].hide();
        },
        async saveResidentData() {
            this.isSaving = true
            await this.saveEpisode().finally(() => { this.isSaving = false});

            if (this.episodeHasErrors === true) {
                this.$refs["modal-error-saving"].show();
            } else {
                this.cancelMessageBoxShow("modal-error-saving");
                this.cancelMessageBoxShow("modal-unsaved-changes");
                await this.loadMessageWindowAfterSave();
            }
        },
        showConfirmUnsavedChangesModal() {
            this.$refs["modal-unsaved-changes"].show();
        },
        async loadMessageWindowAfterSave() {

            await this.setMessageEpisodeId(this.getEpisodeId);
            await this.openMessages();
            await this.loadMessages();
        },
        async openMsgWindow() {
            if (this.isDirty) {
                await this.showConfirmUnsavedChangesModal();
                return;
            }

            await this.openMessages()
        },
        goBack() {
            this.$parent.showPage = this.$parent.showPage - 1;
        },
        goForward() {
            this.$parent.showPage = 6;
        },
        updateCommunicate() {
            if (this.$parent.currentIntervention.selected_notifications.length > 0) {
                this.$parent.communicate = true;
            } else {
                this.$parent.communicate = false;
            }
        },
    },
};
</script>
