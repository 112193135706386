<template>
    <b-container fluid v-if="shouldNotify">
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col>
        <span class="text-danger">
          <i class="fas fa-exclamation-circle"/>
          Notify {{ notificationTarget }} Immediately.
        </span>
            </b-col>
        </b-row>
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col cols="1"> &nbsp;</b-col>
            <b-col cols="11">
                <b-row>
                    <b-checkbox
                        v-model="sendInterventions"
                        :disabled="!hasIntervention"
                    />
                    Escalations: {{ interventionSummary }}
                </b-row>
                <b-row>
                    <b-checkbox v-model="sendAssessments" :disabled="!hasAssessment"/>
                    Changes in Condition: {{ assessmentSummary }}
                </b-row>
                <b-row>
                    <b-checkbox v-model="sendObservations" :disabled="!hasObservation"/>
                    Observations
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {notificationCriteria} from "@/helpers/vitalSignTools";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "MessageAlertPanel",
    computed: {
        ...mapGetters({
            getSendAssessments: "messages/getSendAssessments",
            getSendObservations: "messages/getSendObservations",
            getSendInterventions: "messages/getSendInterventions",
            episode: "episode/getEpisode",
        }),

        notificationTarget() {
            return this.$store.state.user.role === "DOCTOR" ? "Team" : "Doctor";
        },

        shouldNotify() {
            return this.hasIntervention || this.hasAssessment || this.hasObservation;
        },

        hasIntervention() {
            return this.episode.interventions?.some((intervention) => this.escalationShouldNotify(intervention))
        },

        hasAssessment() {
            return this.episode.signsAndSymptoms?.some((assessment) => this.changeInConditionShouldNotify(assessment))
        },

        hasObservation() {
            return this.episode.vitals?.some((observation) => notificationCriteria(observation))
        },

        assessmentSummary() {
            const assessments =
                this.episode?.signsAndSymptoms?.filter(
                    (assessment) => this.changeInConditionShouldNotify(assessment)
                );

            if (assessments.length > 0) {
                if (assessments.length > 1) {
                    return "Multiple";
                }

                return assessments[0].signsAndSymptom?.symptom;
            }

            return "No Assessments";
        },

        interventionSummary() {
            const interventions =
                this.episode?.interventions?.filter(
                    (intervention) => this.escalationShouldNotify(intervention)
                );

            if (interventions.length > 0) {
                if (interventions.length > 1) {
                    return "Multiple";
                }

                let escalationName = interventions[0].intervention?.intervention;

                if (!escalationName) {
                    escalationName = interventions[0].intervention;
                }

                return escalationName;
            }

            return "No Interventions";
        },

        sendAssessments: {
            get() {
                return this.getSendAssessments;
            },
            set(value) {
                this.setSendAssessments(value);
            },
        },

        sendObservations: {
            get() {
                return this.getSendObservations;
            },
            set(value) {
                this.setSendObservations(value);
            },
        },

        sendInterventions: {
            get() {
                return this.getSendInterventions;
            },
            set(value) {
                this.setSendInterventions(value);
            },
        },
    },

    methods: {
        ...mapMutations({
            setSendAssessments: "messages/setSendAssessments",
            setSendObservations: "messages/setSendObservations",
            setSendInterventions: "messages/setSendInterventions",
        }),

        escalationShouldNotify(escalation) {
            const isActive = escalation.active === 1 || escalation.active === true;
            const hasNotification =
                escalation.notified === 1 || escalation.notified === true;

            return isActive && !hasNotification;
        },

        changeInConditionShouldNotify(condition) {
            const isActive = condition.status === 1 || condition.status === true;
            const hasNotification =
                condition.has_notified === 1 || condition.has_notified === true;
            const requiresNotification =
                condition.requires_notification === 1 ||
                condition.requires_notification === true;

            return isActive && requiresNotification && !hasNotification;
        },
    },
};
</script>

<style scoped></style>
