<template>
    <div>
        <table
            class="resident-items"
            id="resident-items"
            v-if="episodes.length > 0"
        >
            <thead>
            <tr class="resident-row">
                <th
                    class="
                      resident-header
                      basic-info
                      dashboard-padding
                      spacing-for-tablet-th-15
                      small-tablet-width-font
                    "
                >
                    BASIC INFO
                </th>
                <th
                    class="
                      resident-header
                      state-msg
                      dashboard-padding
                      spacing-for-tablet-th-20
                      small-tablet-width-font
                    "
                >
                    STATUS & MESSAGES
                </th>
                <th
                    class="
                      resident-header
                      vital-signs
                      dashboard-padding
                      spacing-for-tablet-th-12
                      small-tablet-width-font
                    "
                >
                    VITAL SIGNS
                </th>
                <th
                    class="
                      resident-header
                      signs-symptoms
                      dashboard-padding
                      spacing-for-tablet-th-12
                      small-tablet-width-font
                    "
                >
                    CHANGE IN CONDITION
                </th>
                <th
                    class="
                      resident-header
                      invtervention
                      dashboard-padding
                      spacing-for-tablet-th-12
                      small-tablet-width-font
                    "
                >
                    ESCALATION
                </th>

                <th
                    class="
                      resident-header
                      messages
                      dashboard-padding
                      spacing-for-tablet-th-12
                      small-tablet-width-font
                    "
                >
                    MESSAGES
                </th>
                <th
                    class="
                      resident-header
                      quick-menu
                      dashboard-padding
                      spacing-for-tablet-th-12
                      small-tablet-width-font
                    "
                >
                    QUICK MENU
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                ref="resident"
                v-for="episode in episodes"
                :id="episode.id"
                :key="episode.id"
                class="resident-row"
            >
                <td class="resident-cell basic-info spacing-for-tablet-th-15">
                    <resident-editor-tab
                        :episode="episode"
                        :action="residentBasicInfo"
                    ></resident-editor-tab>
                </td>

                <td class="resident-cell state-msg status-msg-width-height">
                    <episode-list-item-status-message :episode="episode"/>
                </td>
                <td
                    class="resident-cell vital-signs spacing-for-tablet-th-12"
                >
                    <episode-list-item-vital-sign :episode="episode"/>
                </td>
                <td
                    class="
                      resident-cell
                      signs-symptoms
                      spacing-for-tablet-th-12
                    "
                >
                    <episode-list-item-sign-and-symptoms :episode="episode"/>

                </td>
                <td
                    class="resident-cell invtervention spacing-for-tablet-th-12"
                >
                    <intervention-quick-menu
                        :episode="episode"
                        @openInterventions="openInterventions($event, episode)"
                    ></intervention-quick-menu>
                </td>

                <td class="resident-cell messages spacing-for-tablet-th-12">

                    <episode-list-item-message :episode="episode"/>
                </td>
                <td class="resident-cell quick-menu spacing-for-tablet-th-12">
                    <episode-list-item-quick-menu :episode="episode" @openEditor="openEditor($event, episode)"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import InterventionQuickMenu from "@/components/Dashboard/EpisodeListItemInterventionQuickMenu";
import ResidentEditorTab from "@/components/Dashboard/EpisodeListItemResidentEditor.vue";
import {mapActions, mapMutations} from "vuex";
import EpisodeListItemStatusMessage from "@/components/Dashboard/EpisodeListItemStatusMessage";
import EpisodeListItemVitalSign from "@/components/Dashboard/EpisodeListItemVitalSign";
import {pageHandler} from "@/mixins/constants";
import EpisodeListItemMessage from "@/components/Dashboard/EpisodeListItemMessage";
import EpisodeListItemSignAndSymptoms from "@/components/Dashboard/EpisodeListItemSignAndSymptoms";
import EpisodeListItemQuickMenu from "@/components/Dashboard/EpisodeListItemQuickMenu";

export default {
    name: "EpisodeListItems",
    title: "EpisodeListItems",

    components: {
        EpisodeListItemSignAndSymptoms,
        EpisodeListItemMessage,
        EpisodeListItemStatusMessage,
        EpisodeListItemVitalSign,
        ResidentEditorTab,
        InterventionQuickMenu,
        EpisodeListItemQuickMenu,
    },

    props: {
        episodes: {
            type: Array,
            required: true,
        }
    },

    methods: {
        ...mapActions({
            loadEpisodeById: "episode/loadEpisodeById",
        }),

        ...mapMutations({
            setEpisodeId: "episode/setEpisodeId",
        }),

        residentBasicInfo: function (episode) {
            this.setEpisodeId(episode.id);
            this.episode = episode;
            this.showEditor(this.episode, "edit", "basicData");
        },


        openInterventions(eventId, episode) {
            this.setEpisodeId(episode.id);
            this.episode = episode;
            this.showEditor(this.episode, "edit", "interventions");
        },
        openEditor(event, episode) {
            console.warn("Event: ", event)
            this.setEpisodeId(episode.id)
            this.episode = episode
            this.showEditor(this.episode, "edit", event)
        },


        async showEditor(episode, mode, screen) {
            this.$emit('showLoader')
            this.$parent.residentModalMode = mode
            this.$parent.page = screen
            await this.loadEpisodeById(episode.id)
            this.$root.selected_resident = episode;
            this.episode = episode.id;
            this.residentModalMode = mode;
            this.page = screen;
            pageHandler.page = screen;
            pageHandler.residentModalMode = this.residentModalMode;
            this.$emit('hideLoader')
            this.$bvModal.show("residentModal");
        },
    }
}
</script>