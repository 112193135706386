<template>
    <div>
        <div
            class="
                        state-msg-text
                        small-tablet-width-font
                        med-tablet-width-font-status
                      "
            v-b-tooltip.hover.left
            :title="episodeMessage(episode)"
        >
            {{ episodeStatus(episode) }}
        </div>
        <div
            class="
                        msg-text
                        med-tablet-width-font-status
                        small-tablet-width-font
                      "
            :class="{ 'high-msg-text': isHighPriority }"
            v-b-tooltip.hover.left
            :title="episodeMessage(episode)"
        >
            {{ episodeMessage(episode) }}
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {

    props: {
        episode: Object,
    },

    computed: {
        ...mapGetters({
            interventions: "configuration/interventions",
        }),
        isHighPriority() {

            //  if this episode does not have any observations, or has any coc or escalations that are unnotified.
            return this.needsObservation || this.needsNotification
        },

        needsObservation() {

            return this.episode.needsVitalSigns || this.episode.needsObservations
        },
        needsNotification() {

            return this.hasUnnotifiedChangesInCondition || this.hasUnnotifiedEscalations
        },

        hasUnnotifiedChangesInCondition() {
            return this.episode.signsAndSymptoms?.filter(this.requiresNotification).length > 0
        },

        hasUnnotifiedEscalations() {

            return this.episode.interventions?.filter(this.requiresNotification).length > 0
        },

        hasNotifiables() {
            return this.episode.interventions.length > 0 || this.episode.signsAndSymptoms.length > 0
        },
    },

    methods: {
        ...mapActions({
            setEpisodeToHighPriority: "episodes/setEpisodePriorityToHigh",
        }),
        episodeMessage: function () {
            /***
             * We must check the Interventions, Signs and Symptoms, and Vital Signs.
             *
             * If we have one or more interventions that are in Communicate, Monitor, or Priority,
             * we must state whether the doctor has been notified or not. If any of the interventions
             * have not been passed to the doctor, we must alert that they require notification.
             */
            let message = "No Message."
            let observationMessage = ""
            let notificationMessage = ""

            if(this.needsObservation) {

                observationMessage = "There are no observations for this resident."
            }

            if(this.needsNotification) {

                notificationMessage = "Notify Doctor Immediately."
            }

            if(this.hasNotifiables && ! this.needsNotification) {

                notificationMessage = "The Doctor has been notified."
            }

            return this.needsObservation ? observationMessage + ', ' + notificationMessage : notificationMessage.length > 0? notificationMessage : message
        },

        episodeStatus: function (resident_info) {
            return (
                this.signAndSymptomsMessage(resident_info) +
                " " +
                this.interventionMessage(resident_info)
            );
        },

        requiresNotification(item) {
            return item?.notified === 0;
        },

        signAndSymptomsMessage: function (episode) {

            if(episode.signsAndSymptoms?.length > 1) {

                return episode.patientFullName + ' has multiple active Changes in Condition'
            }

            if(episode.signsAndSymptoms?.length === 1) {

                return episode.patientFullName + ' has a(n) ' + episode.signsAndSymptoms[0].symptom + ' active'
            }

            return episode.patientFullName + " has no active Change in Conditions";
        },

        interventionMessage: function (episode) {

            let message;

            if (episode.interventions.length > 0) {

                const urgentInterventions = episode.interventions.filter(
                    (intervention) => {
                        return intervention.status === "Priority"
                    }
                )

                if (urgentInterventions.length > 0) {

                    this.setEpisodeToHighPriority(episode)
                }
            }

            switch (episode.interventions.length) {

                case 0:
                    message = " and No Active Escalations."
                    break;
                case 1:
                    message = `with ${episode.interventions[0].intervention.intervention}.`;
                    break;
                default:
                    message = " with Multiple Escalations."
            }

            return message;
        },
    }
}
</script>