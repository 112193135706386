<template>
  <b-container fluid>
    <episode-intervention-header :page="2" :intervention="intervention.intervention"></episode-intervention-header>
    <b-row>
      <b-col sm="9">
        <h4 style="color: red">Please select at least one symptom.</h4>
        <h5>
          Symptoms or Signs of {{ intervention.intervention }}
        </h5>
        <b-form>
          <b-form-group
            ><b-form-checkbox-group
              id="symptoms-group"
              v-model="selectedSymptoms"
              :options="intervention.symptoms"
              name="interventionSymptoms"
              stacked
            switches
            ></b-form-checkbox-group
          >
          </b-form-group>
        </b-form>
      </b-col>
      <b-col sm="3">
        <episode-intervention-flow-chart :intervention="intervention"></episode-intervention-flow-chart>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <app-button :isDisabled="disallowBack" @click="goBack">&lt; BACK</app-button>
        </b-col>
      <b-col>
          <app-button class="float-right" :isDisabled="disallowNext" @click="goForward" :isDanger="requiresCommunication">{{ nextText }}</app-button>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EpisodeInterventionFlowChart from "./EpisodeInterventionFlowChart.vue";
import EpisodeInterventionHeader from '../Episode/EpisodeInterventionHeader.vue'
import AppButton from '../AppButton.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "EpisodeInterventionsSymptoms",
  components: {
    EpisodeInterventionFlowChart,
    EpisodeInterventionHeader,
    AppButton,
  },

  computed: {
    ...mapGetters({
      interventions: 'configuration/interventions',
      getInterventionName: 'episode/getInterventionName',
      getIntervention: 'episode/getIntervention',
      currentIntervention: "episode/getCurrentIntervention",
    }),

    baseIntervention() {

      return this.currentIntervention.intervention
    },

    intervention() {

        return this.currentIntervention
    },

    page: {
      get() {
        return this.$store.state.episode.intervention.page
      },
      set(pageNumber)  {
        this.$store.commit('episode/updateInterventionPageNumber', pageNumber)
      }
    },
    nextStep: {
      get() {
        return this.$store.state.episode.intervention.next_step
      },
      set(value) {
        this.$store.commit('episode/updateInterventionNextStep', value)
      },
    },

    selectedSymptoms: {
      get() {

        return this.$store.state.episode.intervention.selected_symptoms
      },
      set(value) {

          // this.$store.commit('episode/updateSelectedSymptoms', value)
          this.$store.dispatch('episode/updateSelectedSymptomsAndIntervention', value)
      }
    },

     nextText() {

      return this.requiresCommunication ? "COMMUNICATE" : "NEXT >";
    },

    nextVariant() {

      return this.requiresCommunication ? "outline-danger" : "outline-secondary";
    },

    requiresCommunication() {

      if(this.$parent.communicate && this.getIntervention.notified != 1) {
        return true;
      } else {
        return false;
      }
    },

    disallowNext() {

      return this.intervention?.selected_symptoms?.length < 0
    },

    disallowBack() {
      //    return this.$parent.showPage === 2;
        return false
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },

  methods: {
    ...mapActions({
      updateIntervention: 'episode/updateIntervention',
      setIntervention: 'episode/setIntervention',
    }),

    goBack() {
      this.$parent.showPage = this.$parent.showPage - 1
    },

    goForward() {
      if(this.page < this.$parent.showPage + 1) {

        this.page = this.$parent.showPage + 1
        this.nextStep = 'Vitals'
      }

      if(this.requiresCommunication) {

        this.page = 5
        this.nextStep = 'Communicate'
      }
      this.$parent.showPage = this.$parent.showPage + 1
    },
  },
};
</script>