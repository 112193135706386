<template>
    <div
        @click="openMessageWithId(episode.id)"
        class="messages-blue-icon icon-margin"
        :class="{ 'messages-red-icon': episode.hasUnreadMessages }"
        v-b-tooltip.hover.left
        title="Messages"
    ></div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    props: {
        episode: Object,
    },

    computed: {
        ...mapGetters({
            getEpisodeId: "episode/getEpisodeId",
        })
    },

    methods: {
        ...mapActions({
            openMessages: "messages/openMessages",
            unloadCurrentEpisode: "episode/unloadEpisode",
        }),
        ...mapMutations({
            unloadMessages: "messages/unloadMessages",
            setMessageEpisodeId: "messages/setMessageEpisodeId",
        }),

        async openMessageWithId(episodeId) {
            await this.unloadMessages();
            await this.unloadCurrentEpisode();
            await this.setMessageEpisodeId(episodeId);
            this.openMessages();
        },
    }
}
</script>