<template>
    <div>
        <b-table
            id="Facility"
            :items="facilityList"
            :fields="facilityFields"
        >
        <template #cell(remove_facility)="row">
          <b-button size="sm" @click="removeFacility(row.item)" class="mr-2" variant="danger">
            Remove
          </b-button></template
        >
        </b-table>
    </div>
</template>
<script>
import axios from 'axios'
export default {

    data: function () {
    return {
      facilityList: [],
      facilityFields: [
        { key: "organization_name", sortable: true, label: "Organization" },
        { key: "name", sortable: true, label: "Name" },
        { key: "beds", sortable: true, label: "Bed Count" },
        { key: "creating_user.name", sortable: true, label: "Created By"},
        { key: "created_at", label: "Created At"},
        { key: "remove_facility", sortable: false, label: "Remove" },
      ],
    };
  },

    methods: {

        async loadFacilities() {

            axios.get('pointclickcare/facilities/list')
                .then(data => {
                    this.facilityList = data.data

                })
                .catch(error => {
                    console.error("An error occurred: ", error)
                });
        },

        async removeFacility(item) {
            this.$bvModal.msgBoxConfirm("Are you sure?", {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(response => { 
                if (response) {

                    this.deleteFacility(item.id)
                }
        }).catch(error => {
            console.error("An error occured", error)
        })
        },

        async deleteFacility(facility_id) {

            await axios.delete(`pointclickcare/facilities/delete/${facility_id}`)
                .then((response) => {
                    if(response.status == 201) {

                        this.facilityList = []
                        this.loadFacilities()
                    }

                    if(response.status != 200) {

                        this.$bvModal.msgBoxOk(response.data.message, {

                            title: 'Failed',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'primary',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        })
                    }
                })
                .catch((error) => {
                    console.error("An error occured: ", error)
                })
        }
        
    },
    mounted: function () {
        
        this.loadFacilities()
    }
}
</script>
