<template>
    <div style="cursor: pointer" @click="action(episode)">
        <div class="room edit-font-size edit-font-size-med edit-font-size-small">
            ROOM {{ episode.roomNumber }}
        </div>
        <div
            class="resident-name edit-font-size edit-font-size-med edit-font-size-small"
        >
            {{ episode.patientFullName }}
        </div>
        <div class="edit-icon icon-margin edit-icon-margin edit-icon-size"></div>
    </div>
</template>
<script>
export default {
    props: {
        episode: Object,
        action: Function,
    },
};
</script>
