var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',[_c('span',{staticStyle:{"color":"black","font-weight":"bold","text-align":"center"}},[_vm._v(" FOR "+_vm._s(_vm._f("capitalize")(_vm.intervention)))])])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{class:{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              _vm.page === 2,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              _vm.page > 2,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changePage(2)}}},[_vm._v(" STEP 1: SYMPTOMS ")]),_c('p',{class:{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              _vm.page === 3,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              _vm.page !== 3,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changePage(3)}}},[_vm._v(" STEP 2: VITALS ")]),_c('p',{class:{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              _vm.page === 4,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              _vm.page !== 4,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changePage(4)}}},[_vm._v(" STEP 3: EVALUATE ")]),_c('p',{class:{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              _vm.page === 5,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              _vm.page !== 5,
          }},[_vm._v(" STEP 4: TESTS ")]),_c('p',{class:{
            'resident-signSymptoms-step-text resident-signSymptoms-step-text-spacing':
              _vm.page === 6,
            'resident-signSymptoms-step-text-outline resident-signSymptoms-step-text-spacing':
              _vm.page !== 6,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changePage(6)}}},[_vm._v(" STEP 5: MONITOR ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }