export const constantMixin = Object.freeze({ 
    CNA: "CNA",
    LPN: "LPN",
    RN: "RN",
    GUEST: "GUEST",
    PA: "PA",
    MD: "MD",
    ADMIN: "ADMIN",
    NP: "NP",
    SUPERADMIN: "SUPER ADMIN",
    ADMINRN: "ADMIN RN",
    residentEditMode: "edit",
    residentSavedMode: "saved",
    residentAddMode: "add",
});

export const Roles = Object.freeze({
    CertifiedNursingAssistant: 'CNA',
    Nurse: 'NURSE',
    Doctor: 'DOCTOR',
    Administrator: 'ADMIN',
    RegisteredNurseAdministrator: 'ADMINRN',
    SuperAdministrator: 'SUPERADMIN',
});

export const pageHandler = { //global object to handle resident modal pages and message sidebar
    page: '',
    residentModalMode:'',
    interventions:[],
};

