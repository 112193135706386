<template>
    <b-container fluid class="medium mt-2 black">
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col>
                <div class="text-danger font-weight-bold">
                    <i class="fas fa-notes-medical"></i> Escalation
                </div>
                <div class="text-danger font-weight-bold">
                    {{ intervention.intervention }} ({{ isActive }})
                </div>
                <div>
                    <span class="small">{{ intervention.created_at | formatDate }}</span>
                </div>
            </b-col>
        </b-row>

      <b-row class="justify-content-start" align-v="center" align-h="start" v-if="intervention.selected_symptoms">
        <b-row >
          <b-col>&nbsp;</b-col>
          <b-col cols="12" md="auto"><i class="fas fa-diagnoses"></i>&nbsp;Symptoms</b-col>
        </b-row>
        <b-row>
          <b-col cols="1">

          </b-col>
          <b-col>
            <ul>
              <li v-for="(symptom, index) in intervention.selected_symptoms" :key="index">
                {{ symptom }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-row>

        <b-row class="justify-content-start" align-v="center" align-h="start" v-if="intervention.selected_notifications">
            <b-row >
                <b-col>&nbsp;</b-col>
                <b-col cols="12" md="auto"><i class="fas fa-user-md"></i>&nbsp;Notifications</b-col>
            </b-row>
            <b-row>
                <b-col cols="1">

                </b-col>
                <b-col cols="11">
                    <ul>
                        <li v-for="(notification, index) in intervention.selected_notifications" :key="index">
                            {{ notification }}
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-row>
    </b-container>
</template>
<script>
import moment from "moment";

export default {

    props: {
        intervention: {
            type: Object,
            required: true,
            default: function () {
                return {
                    intervention: 'not found',
                    symptoms: [],
                    evaluations: []
                }
            },
        },
    },
    computed: {
        isActive() {

            return this.intervention.active === 1 ? 'Active' : 'Resolved'
        }
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            let momentObject = moment(value);

            return momentObject.format('MMMM Do YYYY, h:mm:ss a');
        },
    }
}
</script>