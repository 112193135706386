<template>
  <b-container fluid>
    <div class="row mt-3">
      <div class="col-6">
        <app-button isSelected disabled>Immunizations</app-button>
      </div>
      <div class="text-right col-6">
        <app-button @click="showImmunizationModal()"
          ><i class="fas fa-user"></i>&nbsp;&nbsp; Add Immunization</app-button
        >
      </div>
    </div>
    <hr class="bg-primary" />
    <div class="alert alert-danger" v-if="errorMsg">
      Error Message
    </div>
    <div class="alert alert-success" v-if="successMsg">
      Success Message
    </div>
    <!-- if there are no records, show this text -->
    <div class="row" v-if="patientImmunizations.length === 0">
      <div class="col-lg-12">
        <h3>There are no records for this Patient.</h3>
      </div>
    </div>
    <!-- end of no records handler -->
    <!-- Displaying Table Records -->
    <div class="row" v-if="patientImmunizations.length > 0">
      <div class="col-lg-12">
        <table class="table table-bordered table-striped">
          <thead>
            <tr class="text-center text-light bg-dark">
              <th>Immunization Type</th>
              <th>Administered Date</th>
              <th>Brand</th>
              <th>Next Dose Date</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="immunization in patientImmunizations"
              :key="immunization.id"
            >
              <td>
                {{ capitalizeFirstLetter(immunization.immunization.type) }}
              </td>
              <td>{{ immunization.administered_at }}</td>
              <td>{{ immunization.brand }}</td>
              <td>{{ immunization.next_required_dose }}</td>
              <td>
                <app-button @click="selectImmunization(immunization);showEditImmunizationModal(immunization.id);" isNarrow><i class="fas fa-edit"></i></app-button>
              </td>
              <td>
                <app-button @click="showDeleteImmunizationModal();
                    selectImmunization(immunization);" isNarrow isDanger><i class="fas fa-trash"></i></app-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Add New Immunization Modal -->
    <div v-if="showAddImmunization">
      <div id="overlay">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-dark text-white">
              <h5 class="modal-title">Add New Immunization</h5>
              <button
                @click="hideImmunizationModal()"
                type="button"
                class="close"
              >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center mt-2" id="mbody">
              <immunization-editor
                :patient-id="getEpisode.patient.id"
                :patient-immunization-id="0"
                editor-mode="add"
              />
              <!-- @toggle-immunization="hideImmunizationModal" -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Immunization Modal -->
    <div v-if="showEditImmunization">
      <div id="overlay">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-dark text-white">
              <h5 class="modal-title">Edit Immunization</h5>
              <button
                @click="hideEditImmunizationModal()"
                type="button"
                class="close text-white"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center" id="mbody">
              <immunization-editor
                :patient-id="getEpisode.patient.id"
                :patient-immunization-id="patientImmunizationId"
                :immunization="currentImmunization"
                editor-mode="edit"
              />

              <!-- <EditImmunization @toggle-edit-immunization="hideEditImmunizationModal" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Immunization Modal -->
    <div v-if="showDeleteImmunization">
      <div id="deleteOverlay">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-dark text-white">
              <h5 class="modal-title">Delete Immunization</h5>
              <button
                @click="hideDeleteImmunizationModal()"
                type="button"
                class="close text-white"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center" id="mbody">
              <div class="row">
                <div class="col">
                  <h5 class="text-danger">
                    Are you sure you want to delete this item?
                  </h5>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col float-right">
                  <app-button isDanger @click="deleteImmunization(currentImmunization);hideDeleteImmunizationModal()">DELETE</app-button>
                  <app-button @click="hideDeleteImmunizationModal()">CANCEL</app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import ImmunizationEditor from "./ImmunizationEditor";
import AppButton from "@/components/AppButton";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Immunization",
  components: {
    ImmunizationEditor,
    AppButton,
  },
  data() {
    return {
      currentImmunization: {},
      showAddImmunization: false,
      showEditImmunization: false,
      showDeleteImmunization: false,
      errorMsg: "",
      successMsg: "",
      submitted: false,
      patientImmunizationId: 0,
    };
  },
  mounted: function() {
    this.hideEditImmunizationModal();
  },
  methods: {
    ...mapActions({
      updatePatientImmunization: "episode/updatePatientImmunization", 
      addImmunization: "episode/addImmunization",
      deleteImmunization: "episode/deleteImmunization",
      }),
    showImmunizationModal() {
      this.showAddImmunization = true;
    },
    hideImmunizationModal() {
      this.showAddImmunization = false;
    },
    showEditImmunizationModal(patientImmunizationId) {
      this.patientImmunizationId = patientImmunizationId;
      this.showEditImmunization = true;
    },
    hideEditImmunizationModal() {
      this.showEditImmunization = false;
    },
    showDeleteImmunizationModal() {
      this.showDeleteImmunization = true;
    },
    hideDeleteImmunizationModal() {
      this.showDeleteImmunization = false;
    },
    selectImmunization(immunization) {
      this.currentImmunization = immunization;
    },
    capitalizeFirstLetter(string) {
      if(string !== null) {

return string.charAt(0).toUpperCase() + string.slice(1);
      }

      return "No Value Passed."
      
    },
    async updateImmunization() {
      if (!this.ValidatePatientImmunization()) {
        const data = {
          id: this.currentImmunization.id,
          immunization_type: this.currentImmunization.immunization_type,
          administered_at_date: this.currentImmunization.administered_at_date,
          brand: this.currentImmunization.brand,
          next_dose_required_at_date: this.currentImmunization
            .next_dose_required_at_date,
        };

        this.updatePatientImmunization(data)

      } else {
        this.$bvModal.msgBoxOk("Please complete all required fields.", {
          centered: true,
        });
      }
    },

    ValidatePatientImmunization() {
      return (
        this.hasValidImmunizationType &&
        this.hasValidAdministeredDate &&
        this.hasValidBrand &&
        this.hasValidDoseDate
      );
    },
  },
  computed: {
    ...mapGetters({
      patientImmunizations: 'episode/patientImmunizations',
      getEpisode: 'episode/getEpisode',
    }),
    hasValidImmunizationType() {
      return this.currentImmunization.immunization_type == null;
    },
    hasValidAdministeredDate() {
      return this.currentImmunization.administered_at_date == null;
    },
    hasValidBrand() {
      return this.currentImmunization.brand == null;
    },
    hasValidDoseDate() {
      return this.currentImmunization.next_dose_required_at_date == null;
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  padding-top: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
#deleteOverlay {
  position: fixed;
  padding-top: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
</style>
