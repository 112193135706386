<template>
  <div class="home">
    <DashBody test_msg="Welcome to Your Dashboard App"/>
    <loader></loader>
  </div>
</template>

<script>
// @ is an alias to /src
import DashBody from '@/components/Dashbody.vue'
import Loader from '@/components/LoaderModal.vue'

export default {
  name: 'Dashboard',
  components: {
    DashBody,
    Loader
  },
  mounted() {
  },
  beforeDestroy() {
  }

}
</script>