<template>
  <div
  >
    <div
      @click="signsAndSymptoms(episode)"
      class="assessment assessment-icon"
      :class="{
        'low-warning': hasLowWarning,
        'medium-warning': hasMediumWarning,
        'high-warning': hasHighWarning,
      }"
      v-b-tooltip.hover.left
      title="Change in Condition"
    >
        <img
            v-if="hasAssessments"
            src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-RED-ALERTS/SignsSymptoms-ICONx64-RED.png"
            alt="Has Escalations"
            class="icon-size"
        />
        <img
            v-if="!hasAssessments"
            src="../../assets/Icons/main-window/PNG-icons-4-Homescreen-BLUE/SignsSymptoms-ICONx64.png"
            alt="No Escalations"
            class="icon-size"
        />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "EpisodeListItemSignAndSymptoms",

  props: {
    episode: Object,
  },

  computed: {
    ...mapGetters({}),
    hasAssessments() {
      return this.episode.hasSignsAndSymptoms;
    },

    unnotifiedSymptoms() {
      return this.episode.signsAndSymptoms?.filter(this.requiresNotification);
    },

    oldestAssessment() {
      return this.unnotifiedSymptoms?.reduce(this.oldest);
    },

    age() {

        return this.numberOfHours(moment(this.oldestAssessment.created_at))
    },

    assessmentNotificationIsRecent() {
      return (
        !this.assessmentNotificationIsModerate &&
        !this.assessmentNotificationIsOld &&
        this.age <= 1
      );
    },
    assessmentNotificationIsModerate() {
      return (
        !this.assessmentNotificationIsOld &&
        this.age > 1
      );
    },
    assessmentNotificationIsOld() {
      return this.age >= 3
    },

    hasLowWarning() {
      return (
        this.unnotifiedSymptoms.length &&
        this.assessmentNotificationIsRecent
      );
    },

    hasMediumWarning() {
      return (
          this.unnotifiedSymptoms.length &&
        this.assessmentNotificationIsModerate
      );
    },

    hasHighWarning() {
      return (
          this.unnotifiedSymptoms.length &&
        this.assessmentNotificationIsOld
      );
    },
  },

  methods: {
    ...mapActions({}),
    ...mapMutations({
      setEpisodeId: "episode/setEpisodeId",
    }),

    numberOfHours(assessment) {
      let assessmentTime = moment(assessment);
      return Math.abs(assessmentTime.diff(moment.now(), "hours"));
    },
    requiresNotification(item) {
      return item.notified === 0 && item.requires_notification === 1;
    },

    isNotNull(item) {
      return item !== null;
    },

    oldest(item, accumulator) {
      if (accumulator === undefined) {
        return item;
      }

      if (accumulator.created_at < item.created_at) {
        return accumulator;
      }

      return item;
    },

    signsAndSymptoms: function (episode) {
      this.setEpisodeId(episode.id);
      this.$parent.$parent.episode = episode;
      this.$parent.showEditor(
        this.$parent.$parent.episode,
        "edit",
        "signsAndSymptoms"
      );
    },
  },
};
</script>
<style>
.low-warning {
  animation-duration: 12s;
  animation-name: warning-cycle;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  border-radius: 50%;
}

.medium-warning {
  animation-duration: 6s;
  animation-name: warning-cycle;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  border-radius: 50%;
}

.high-warning {
  animation-duration: 125ms;
  animation-name: warning-cycle;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes warning-cycle {
  0% {
    background: #ffffba;
  }
  50% {
    background: #ffdfba;
  }
  100% {
    background: #ffb3ba;
  }
}

.assessment {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
}

.assessment-notifications {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.icon-size {

    height: 40px;
    width: 40px;
}
.assessment-icon {
    cursor: pointer;
}
</style>
